import React,{Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom";
import ScreenWrapper from '../ScreenWrapper';


export default  () => {
    return(
        <>
            <ScreenWrapper>
            <p> usecases data </p>
            </ScreenWrapper>
        </>
    )
}