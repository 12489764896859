export const SEEKER_PROFILE = "SEEKER_PROFILE";
export const CREATE_SERVICE_PROVIDER = "CREATE_SERVICE_PROVIDER";
export const CREATE_INDIVIDUAL = "CREATE_INDIVIDUAL";
export const CREATE_INDIVIDUALNON_MEMBER = "CREATE_INDIVIDUALNON_MEMBER";
export const CREATE_COMPANY_THROUGH_MEMBER = "CREATE_COMPANY_THROUGH_MEMBER";
export const INDIVIDUAL_LOGIN = "INDIVIDUAL_LOGIN";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_INDIVIDUAL = "GET_INDIVIDUAL";
export const SERVICE_PROVIDER_LOGIN = "SERVICE_PROVIDER_LOGIN";
export const GET_SERVICE_PROVIDER = "GET_SERVICE_PROVIDER";
export const GET_SERVICE_SEEKER = "GET_SERVICE_SEEKER";
export const UPDATE_SERVICE_PROVIDER = "UPDATE_SERVICE_PROVIDER";
export const UPDATE_SERVICE_SEEKER = "UPDATE_SERVICE_SEEKER";
export const GET_POST_WORK = "GET_POST_WORK";
export const CREATE_POST_WORK = "CREATE_POST_WORK";
export const DELETE_POST_WORK = "DELETE_POST_WORK";
export const COMPLETE_POST_WORK = "COMPLETE_POST_WORK";
export const DISCONTINUE_POST_WORK = "DISCONTINUE_POST_WORK";
export const UPDATE_POST_WORK = "UPDATE_POST_WORK";
export const DELETING_POST = "DELETING_POST";
export const SELECT_POST = "SELECT_POST";
export const UNSELECT_POST = "UNSELECT_POST";
export const LOADING_STATE = "LOADING_STATE";
export const UNIQUE_NAME = "UNIQUE_NAME";
export const UNIQUE_EMAIL = "UNIQUE_EMAIL";
export const UNIQUE_CNIC = "UNIQUE_CNIC";
export const CREATE_REVIEW = "CREATE_REVIEW";
export const GET_SERVICE_PROVIDER_FOR_SEEKER =
  "GET_SERVICE_PROVIDER_FOR_SEEKER";
export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_ALL = "GET_REVIEWS_ALL";
export const GET_REVIEWS_SERVICE_PROVIDERS = "GET_REVIEWS_SERVICE_PROVIDERS";


export const SITE_KEY= "6Ld3Q9gcAAAAAHT_KXf7GLD2yTX6_azs-LLX32-7";


