import {
  CREATE_INDIVIDUAL,
  GET_SERVICE_PROVIDER_FOR_SEEKER,
  CREATE_COMPANY_THROUGH_MEMBER,
  CREATE_INDIVIDUALNON_MEMBER,
  GET_SERVICE_SEEKER,
  INDIVIDUAL_LOGIN,
  LOADING_STATE,
  GET_REVIEWS,
  GET_REVIEWS_ALL,
} from "../Constants/constants";

const initialState = {
  items: [],
  creationData: {},
  individualUser: {},
  serviceSeekers: [],
  serviceProviders: [],
  reviews: [],
  reviewsAll: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_INDIVIDUAL:
      return {
        ...state,
        creationData: action.payload,
      };

    case CREATE_COMPANY_THROUGH_MEMBER:
      return {
        ...state,
        creationData: action.payload,
      };

    case CREATE_INDIVIDUALNON_MEMBER:
      return {
        ...state,
        creationData: action.payload,
      };

    case GET_SERVICE_SEEKER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    case GET_SERVICE_PROVIDER_FOR_SEEKER:
      return {
        ...state,
        serviceProviders: action.payload,
      };

    case GET_REVIEWS:
      return {
        ...state,
        reviews: action.payload,
      };

    case GET_REVIEWS_ALL:
      return {
        ...state,
        reviewsAll: action.payload,
      };

    case INDIVIDUAL_LOGIN:
      return {
        ...state,
        individualUser: action.payload,
      };

    case LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
