import React, { Component, useEffect } from "react";
import { Nav, Navbar, FormControl, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import HeaderApp from "../Header/Header";
import FooterApp from "../Footer/Footer";
import { getPostWork } from "../../Actions/post/PostWork";

import CreatePostWork from "./CreatePostWork";
import PostTypeOptions from "./PostTypeOptions";

const PostWork = (WrappedComponent, props) => {
  return (
    <div>
      <div>
        <div class="header-top">
          <HeaderApp />
        </div>
      </div>
      <div>
                <div
                    ids="iq-home"
                    className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}
                >
                <div className="container-fluid">
                <div className="col-lg-12">
                                    <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                                    Post Work
                                    </h1>
                                    
                                </div>
                    </div>
                </div>
                </div>

      <WrappedComponent props={props} />

      <FooterApp />

     
    </div>
  );
};

export default PostWork;
