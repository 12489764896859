import React,{Component} from 'react';

const HeaderApp = () => {

return(

<>
<h6>  </h6>
</>
/* <div class="clearfix"></div>
<nav class="navbar navbar-expand-lg navbar-light">

    <div class="">
        <img src="..images/logo-image.png" class="" alt="Modal Image" width="190" height="70" />
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon">
            <i class="fas fa-bars"></i>
        </span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-lg-auto text-center">
            <li class="nav-item" id="li-home">
                <a class="nav-link" href="Home.aspx">Home
                    <span class="sr-only">(current)</span>
                </a>
            </li>
            <li class="nav-item" id="li-how-it-works">
                <a class="nav-link" href="HowItWorks.aspx">How it works</a>
            </li>
            <li class="nav-item" id="li-post-work">
                <a class="nav-link" href="PostWork.aspx">Post work</a>
            </li>
            <li class="nav-item dropdown" id="li-packages">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown1" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Packages
                    <i class="fas fa-angle-down"></i>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="ServiceProviderPackages.aspx">Service providers</a>
                    <a class="dropdown-item" href="ServiceSeekerPackages.aspx">Service seekers</a>
                </div>
            </li>
            <li class="nav-item dropdown" id="li-about">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">About
                    <i class="fas fa-angle-down"></i>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <a class="dropdown-item" href="AboutUs.aspx" title="">About us</a>
                    <a class="dropdown-item" href="AboutPortal.aspx" title="">About portal</a>
                </div>
            </li>
            <li class="nav-item" id="li-contact">
                <a class="nav-link" href="Contact.aspx">Contact</a>
            </li>
        </ul>
        <div class="no-padding btn btn-primary dropdown">

            <a  class="nav-link dropdown-toggle" href="#" id="navbarDropdown12" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fas fa-user dropdown-toggle"></i>&nbsp;Register
                    
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown12">
                <a class="dropdown-item" href="ServiceProviderSignup.aspx">Service providers</a>
                <a class="dropdown-item" href="ServiceSeekerSignup.aspx">Service seekers</a>
            </div>

        </div>
        <button class="btn btn-primary" >
            <i class="fas fa-lock"></i>&nbsp;Sign in
        </button>


    </div>
</nav> */


)



}

export default HeaderApp;

