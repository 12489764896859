import {
  CREATE_SERVICE_PROVIDER,
  GET_SERVICE_PROVIDER,
  LOADING_STATE,
  UPDATE_SERVICE_PROVIDER,
} from "../../Constants/constants";
import React, {useState} from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils";
import { Alert } from "react-bootstrap";


export const createServiceProvider = (data, loading) => (dispatch) => {
// function myFunction() {
//   let myVar
//   myVar = setTimeout(alertFunc, 8000);
// }
// function alertFunc() {
//   window.location.href = "/home";
// }

  dispatch({
    type: LOADING_STATE,
    payload: loading,
  });

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/service-provider/create`,
      data
    )
    .then(function (response) {
      console.log(response,"Create SP Response");
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        Transition:"slide",
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });

      dispatch({
        type: CREATE_SERVICE_PROVIDER,
        payload: response.data,
      });
      return  response.data;
    })
    .catch(function (err) {
      console.log(err,"Create SP Error Response");
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });

      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      console.log("error arrived", err);
      return  null;
    });
    return dataResponse;
};

export const getServiceProvider = () => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;

  const dataResponse = axios
    .get(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/service-provider/get`,
      {
        params: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      console.log("response", response);
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      dispatch({
        type: GET_SERVICE_PROVIDER,
        payload: response.data.data,
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      console.log("error arrived", err);
    });
};

export const updateServiceProvider = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/service-provider/update`,
      data,
      {
        params: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      console.log("response", response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: GET_SERVICE_PROVIDER,
        payload: data,
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      console.log("error arrived", err);
    });
};

export const updateAreaOfSpecialization = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;
  data["sp_id"] = id;
  console.log('before sending data in api',data);
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/service-provider/update-aos`,
      { ...data, sp_id: id },
      {
        // params: {
        //   id: id,
        // },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      console.log("response", response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // dispatch({
      //   type: UPDATE_SERVICE_PROVIDER,
      //   payload: response.data.data,
      // });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      console.log("error arrived", err);
    });
};
