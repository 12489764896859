import React, { Component } from 'react';
import { Nav, Navbar, FormControl, Button, Form } from 'react-bootstrap'

import { NavLink, withRouter, Link } from "react-router-dom";
import { DEFAULT_MIN_VERSION } from 'tls';
import FooterApp from '../Footer/Footer';
import HeaderApp from '../Header/Header';
import MetaTags from 'react-meta-tags';



const ServiceSeekerPackages = () => {

    return (
        <>
        <MetaTags>
            <title>Service seekers Needing Accounting and Financial Services - Packages | APEXWAY</title>
            <meta name="description" content="Looking to outsource your firm's accounting, bookkeeping, taxation, and other finance functions, APEXWAY gives access to the pool of verified service providers." />
            <meta name="robots" content="noodp,noydir" />
        </MetaTags>
                
        <div>
            <div >

                <HeaderApp />

            </div>

            <div>
                <div
                    ids="iq-home"
                    className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}
                >
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                                Service Seekers<br></br>
                                <span class="section_1-breadcrumbs"><span>Packages</span></span>
                            </h1>

                        </div>
                    </div>
                </div>
            </div>



            <section id="pricing" className="overview-block-ptb grey-bg iq-price-table">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="heading-title">
                                <h3 >2 Easy steps to get things done</h3>
                                <p className="title iq-tw-7">Get registered and post work. Simple!</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id="main-packeg-div" className="col-md-3 col-lg-3">
                            <div id="packegdivstyling" className="iq-pricing text-center">
                                <div className="price-title iq-parallax iq-over-blue-80 jarallax">
                                    <h2 className="iq-font-white iq-tw-7" id="packegs-name">Individual P1</h2>
                                    <span className="text-uppercase iq-tw-4 iq-font-white"><span class="align-top">PKR</span> 100</span>
                                    <div id='jarallax-container-4' className="customstyling">
                                        <div className="newstyling">
                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li >Members of professional bodies</li>
                                    <li >(ICAP, ICMAP, PIPFA, ACCA, ICPAP, ICSP, PBC, CIA etc.)</li>
                                    <li><b>Free</b> registration</li>
                                    <li><b>100%</b> refund policy (first 30 days)</li>
                                    <li><b>Unlimited</b> project postings</li>
                                    <li>Database search option</li>
                                    <li><b>PKR 100</b> per month only</li>
                                    <li><b>PKR 1,200</b> annually</li>
                                    <li className="border-top">Limited package - <b>PKR 500</b> <br /> <i>(for 3 months only)</i></li>

                                </ul>
                                <div className="price-footer">
                                    <Link className="button" to="/service-seeker-signup"> Buy Now</Link>
                                </div>
                            </div>
                        </div>

                        <div id="main-packeg-div" className="col-md-3 col-lg-3 wow flipInY r4-mt-30" data-wow-duration="1s">
                            <div id="packegdivstyling" className="iq-pricing text-center">
                                <div className="price-title iq-parallax iq-over-blue-80 jarallax">
                                    <h2 className="iq-font-white iq-tw-7" id="packegs-name">Individual P2</h2>
                                    <span className="text-uppercase iq-tw-4 iq-font-white"><span class="align-top">PKR</span> 250</span>
                                    <div id='jarallax-container-4' className="customstyling">
                                        <div className="newstyling">

                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li>For all individuals</li>
                                    <li ><i>(Other than P1)</i></li>
                                    <li>Registration fee <b>PKR 750</b></li>
                                    <li><b>100%</b> refund policy (first 30 days)</li>
                                    <li><b>Unlimited</b> project postings</li>
                                    <li>Database search option</li>
                                    <li><b>PKR 250</b> per month</li>
                                    <li><b>PKR 3,000</b> annually</li>
                                    <li className="border-top">Limited package - <b>PKR 1,000</b> <br /> <i>(For 3 months only)</i></li>

                                </ul>
                                <div className="price-footer">
                                    <Link className="button" to="/service-seeker-signup"> Buy Now</Link>
                                </div>
                            </div>
                        </div>

                        <div id="main-packeg-div" className="col-md-3 col-lg-3">
                            <div id="packegdivstyling" className="iq-pricing text-center">
                                <div className="price-title iq-parallax iq-over-blue-80 jarallax">
                                    <h2 className="iq-font-white iq-tw-7" id="packegs-name">Company C1</h2>
                                    <span className="text-uppercase iq-tw-4 iq-font-white"><span class="align-top">PKR</span> 1,000</span>
                                    <div id='jarallax-container-4' className="customstyling">
                                        <div className="newstyling">

                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li >Company registration</li>
                                    <li ><i>(Through members listed in P1)</i></li>
                                    <li>Registration fee <b>PKR 1,000</b></li>
                                    <li><b>100%</b> refund policy (first 30 days)</li>
                                    <li><b>Unlimited</b> project postings</li>
                                    <li>Database search option</li>
                                    <li><b>PKR 1,000</b> per month</li>
                                    <li><b>PKR 12,000</b> annually</li>
                                    <li className="border-top">Limited package - <b>PKR 3,500</b> <br /> <i>(For 3 months only)</i></li>

                                </ul>
                                <div className="price-footer">
                                    <Link className="button" to="/service-seeker-signup"> Buy Now</Link>
                                </div>
                            </div>
                        </div>

                        <div id="main-packeg-div" className="col-md-3 col-lg-3">
                            <div id="packegdivstyling" className="iq-pricing text-center">
                                <div className="price-title iq-parallax iq-over-blue-80 jarallax">
                                    <h2 className="iq-font-white iq-tw-7" id="packegs-name">Company C2</h2>
                                    <span className="text-uppercase iq-tw-4 iq-font-white"><span class="align-top">PKR</span> 1,500</span>
                                    <div id='jarallax-container-4' className="customstyling">
                                        <div className="newstyling">

                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li >Company registration</li>
                                    <li ><i>(All others)</i></li>
                                    <li >Registration fee <b>PKR 1,500</b></li>
                                    <li ><b>100%</b> refund policy (first 30 days)</li>
                                    <li ><b>Unlimited</b> project postings</li>
                                    <li >Database search option</li>
                                    <li ><b>PKR 1,500</b> per month</li>
                                    <li ><b>PKR 18,000</b> annually</li>
                                    <li className="border-top">Limited package - <b>PKR 6,000</b> <br /> <i>(For 3 months only)</i></li>

                                </ul>
                                <div className="price-footer">
                                    <Link className="button" to="/service-seeker-signup"> Buy Now</Link>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <FooterApp />
        </div>
        </>
    )



}

export default ServiceSeekerPackages;
