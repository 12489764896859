import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import trash from '../../images/trash-bin.svg';
import checked from '../../images/checked.svg';
import remove from '../../images/remove.svg';
import {
  getPostWork,
  getSpecializedPostWorkAction,
  createPostReview,
  deletePostWork,
  completePostWork,
  discontinuePostWork,
} from "../../Actions/post/PostWork";
import PostWork from "./PostWork";
import { getServiceProvider } from "../../Actions/serviceProvider/ServiceProvider";
import {
  Button,
  Modal,
  Spinner,
  Option,
  DropdownButton,
  Dropdown,
  FormControl,
  Card,
} from "react-bootstrap";
import { Badge } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import moment from "moment";
import "./ListPostWork.css";
import { AllPostTypes } from "./PostTypeOptions";
import { getReviewsAll } from "../../Actions/serviceSeeker/individualLogin";
import MetaTags from 'react-meta-tags';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

const useStyles = makeStyles({
  root: {
  },
});
// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

const AllPosts = (props) => {
  // console.log("props === ", props);
  const [allPosts, setAllPosts] = useState({});
  const [selectedRow, setSelectedRow] = useState("");
  const [postReviews, setpostReviews] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [postHeading, setpostHeading] = useState("");
  const [postContent, setpostContent] = useState("");
  const [postRating, setpostRating] = useState(0);
  const [postRecommendation, setpostRecommendation] = useState(0);
  const [postCommunication, setpostCommunication] = useState(0);
  const [postSupport, setpostSupport] = useState(0);
  const [loadingOnUpdate, setLoadingOnUpdate] = useState(false);
  const [assignmentArea, setAssignmentArea] = useState(null);
  const [allSpecializations, setAllSpecialization] = useState([]);
  const [serviceProviderid, setServiceprovider] = useState("");

  const defaultSpecializations = [
    { name: "Audit & Assurance", value: "audit-assurance" },
    { name: "Accounting", value: "accounting" },
    { name: "Income Tax", value: "income-tax" },
    {
      name: "Sales & Service Tax",
      value: "sales-service-tax",
    },
    {
      name: "Corporate Secretarial",
      value: "corporate-secretarial",
    },
    {
      name: "Cost Accounting & Audit",
      value: "cost-Accounting-and-audit",
    },
    {
      name: "Transaction Advisory",
      value: "transaction-advisory",
    },
    { name: "Legal Advisory", value: "legal-advisory" },
    {
      name: "Accounting Advisory (IFRS etc.)",
      value: "accounting-advisory",
    },
    { name: "Internal Audit", value: "internal-audit" },
    {
      name: "Business Process Outsourcing",
      value: "business-process-outsourcing",
    },
    {
      name: "Public Sector/NPO Advisory",
      value: "Public-sector-NPO-advisory",
    },
    {
      name: "IT/IS Audits",
      value: "IT-IS-audits",
    },
    {
      name: "Fixed Assets Assignments",
      value: "fixed-assets-assignments",
    },
    { name: "Actuarial", value: "actuarial" },
    { name: "Others", value: "others" },
  ];

  const [open, setOpen] = React.useState(false);
  const [reviewOpen, setReviewOpen] = React.useState(false);
  const [postAction, setPostAction] = React.useState(null);
  const adminToken = localStorage.getItem("user");
  const userType = adminToken ? JSON.parse(adminToken)?.data?.userType : null;

  useEffect(async () => {
    let filteredPostAreas = [];
    if (!userType) {
      if (!props.props?.serviceProvider?.user?.area_of_specialization) {
        await props.props.getServiceProviderAction();
      } else {
        filteredPostAreas = defaultSpecializations.filter((item) => {
          if (
            props.props?.serviceProvider?.user?.area_of_specialization.includes(
              item.value
            )
          ) {
            return item;
          }
        });
        setAllSpecialization(filteredPostAreas);
        setAssignmentArea(
          filteredPostAreas.length > 0 ? filteredPostAreas[0]?.value : null
        );
      }
    }
  }, []);

  useEffect(async () => {
    let filteredPostAreas = defaultSpecializations.filter((item) => {
      if (
        props.props?.serviceProvider?.user?.area_of_specialization &&
        props.props?.serviceProvider?.user?.area_of_specialization.includes(
          item.value
        )
      ) {
        return item;
      }
    });
    setAllSpecialization(filteredPostAreas);
    setAssignmentArea(
      filteredPostAreas.length > 0 ? filteredPostAreas[0]?.value : null
    );
    let posts = adminToken
      ? userType
        ? await props.props.getPostWorkAction()
        : assignmentArea &&
          filteredPostAreas &&
          Array.isArray(filteredPostAreas) &&
          filteredPostAreas.length > 0
        ? await props.props.getSpecializedPostWorkAction({ assignmentArea })
        : []
      : [];
    setAllPosts(posts);
  }, [props.props?.serviceProvider?.user]);

  useEffect(async () => {
    let posts = adminToken
      ? userType
        ? await props.props.getPostWorkAction()
        : assignmentArea &&
          allSpecializations &&
          Array.isArray(allSpecializations) &&
          allSpecializations.length > 0
        ? await props.props.getSpecializedPostWorkAction({ assignmentArea })
        : []
      : [];
    setAllPosts(posts);
  }, [assignmentArea]);

  useEffect(async () => {
    setAllPosts(props.props.postWorks.posts);
  }, [props.props.postWorks.posts]);

  const handleClickOpen = async (row) => {
    await props.props.getAllReviewsAction();
    // console.log("agayaa", props.props.reviewAll);
    let reviews = props?.props?.reviewAll.filter(
      (item) => item.post_id === row.reference_id
    );
    setpostReviews(reviews);

    setOpen(true);
    setSelectedRow(row);
  };

  const createReview = (row) => {
    // console.log("Rev", row);
    setReviewOpen(true);
    setSelectedRow(row);
  };
  const marks = [
    {
      value: 0,
      label: 'Very poor',
    },
    {
      value: 10,
      label: 'Very High',
    }
  ];

  const openConfirmationDialog = async (row, action) => {
    await setSelectedRow(row);
    await setPostAction(action);
    await setShowDialog(true);
  };

  const deletePost = async () => {
    // console.log("row in delete === ", selectedRow);
    setShowDialog(false);
    setLoadingOnUpdate(true);
    await props.props.deletePostWorkAction(selectedRow);
    setLoadingOnUpdate(false);
  };

  const completePost = async () => {
    setShowDialog(false);
    setLoadingOnUpdate(true);
    await props.props.completePostWorkAction(selectedRow);
    setLoadingOnUpdate(false);
    await createReview(selectedRow);
  };

  const discontinuePost = async () => {
    // console.log("row in discontinue === ", selectedRow);
    setShowDialog(false);
    setLoadingOnUpdate(true);
    await props.props.discontinuePostWorkAction(selectedRow);
    setLoadingOnUpdate(false);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
    setPostAction(null);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseReview = () => {
    setReviewOpen(false);
  };

  const handleAssignmentChange = (e) => {
    setAssignmentArea(e.target.value);
  };

  function valuetext(value) {
    setpostRating(value);
    return `${value}`;
    
  }
  function valuetextt(value) {
    setpostRecommendation(value);
    return `${value}`;
    
  }
  function valuetexttt(value) {
    setpostCommunication(value);
    return `${value}`;
    
  }
  function valuetextttt(value) {
    setpostSupport(value);
    return `${value}`;
    
  }
  

  function onChange(event) {
    const { value, name, id } = event.target;

    switch (id) {
      case "post-rating":
        setpostRating(value);
        break;

        case "post-recommendation":
          setpostRecommendation(value);
        break;

        case "post-support":
          setpostSupport(value);
        break;

        case "post-communication":
          setpostCommunication(value);
        break;
        
      case "post-heading":
        setpostHeading(value);
        break;

      case "post-content":
        setpostContent(value);
        break;

      case "service-provider-id":
        setServiceprovider(value);
        break;

      default:
        // console.log("value");
    }
  }

  async function register(event) {
    event.preventDefault();

    const payload = {
      ratings: postRating,
      heading: postHeading,
      content: postContent,
      serviceProvider: serviceProviderid,
      postId: selectedRow.reference_id,
      recommendation:postRecommendation,
      communication:postCommunication,
      support:postSupport,
      area_of_specialization:selectedRow.assignment_area,
    };

    await props.props.createReviewPostAction(payload);
    await handleCloseReview();
  }
  const classes = useStyles();
  // console.log("posts ====>", props.props);
  // console.log(
  //   "props.props.deletingPost ==== ",
  //   JSON.stringify(props?.props?.postWorks.deletingPost)
  // );
  return (
    <>
          <MetaTags>
            <title>Post Work | Apex-Way</title>
            {/* <meta name="description" content="Some description." /> */}
          </MetaTags>
      <div>
        <Modal show={showDialog} onHide={handleDialogClose}>
          <Modal.Header closeButton>
            <Modal.Title>{postAction} Post</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to {postAction} the post{" "}
            {selectedRow.reference_id}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleDialogClose}>
              No
            </Button>
            {postAction && postAction.toLowerCase() === "delete" && (
              <Button variant="primary" onClick={deletePost}>
                Yes
              </Button>
            )}
            {postAction && postAction.toLowerCase() === "complete" && (
              <Button variant="primary" onClick={completePost}>
                Yes
              </Button>
            )}
            {postAction && postAction.toLowerCase() === "discontinue" && (
              <Button variant="primary" onClick={discontinuePost}>
                Yes
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          show={reviewOpen}
          onHide={handleCloseReview}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Review Post</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={register}>
              <div class="form-group">
                <label for="formGroupExampleInput">Post Id </label>
                <input
                  type="text"
                  class="form-control"
                  onChange={onChange}
                  id="post-id"
                  placeholder="enter post id"
                  value={selectedRow.reference_id}
                  disabled
                />
              </div>
              {/* <div class="form-group">
                <label for="formGroupExampleInput">Rating</label>
                <input
                  type="number"
                  class="form-control"
                  onChange={onChange}
                  id="post-rating"
                  value={postRating}
                  placeholder="enter rating"
                />
              </div> */}
              <div class="form-group">
                <label for="formGroupExampleInput2">Remarks</label>
                <input
                  required='true'
                  type="text"
                  class="form-control"
                  onChange={onChange}
                  id="post-heading"
                  value={postHeading}
                  placeholder="enter heading"
                />
              </div>
              <div class="form-group">
                <label for="formGroupExampleInput2">Comments</label>
                <input
                  type="text"
                  required='true'
                  class="form-control"
                  onChange={onChange}
                  id="post-content"
                  value={postContent}
                  placeholder="enter content"
                />
              </div>
              <div class="form-group">
                <label for="formGroupExampleInput2">Service Provider Id </label>
                <input
                  type="text"
                  required='true'
                  class="form-control"
                  onChange={onChange}
                  id="service-provider-id"
                  placeholder="Example:SP-0"
                  value={serviceProviderid}
                />
              </div>
              <label for="customRange3" class="form-label" >Rating out of 10</label><br></br>
              <div className="container" style={{paddingRight:"20px",paddingLeft:'20px'}}>
                <div class="form-group">
                  
                  <Slider
                    defaultValue={0}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    step={1}
                    className={classes.root}
                    marks={marks}
                    min={0}
                    max={10}
                  />
                        {/* <input type="range" value={postRating} onChange={onChange} class="form-range form-control" min="0" max="10" step="1" id="post-rating"></input> */}
                </div>
              </div>
              <label for="customRange4" class="form-label">Recommendation out of 10</label><br></br>
              <div className="container" style={{paddingRight:"20px",paddingLeft:'20px'}}>
              <div class="form-group">
                <Slider
                id="post-recommendation"
                  defaultValue={0}
                  getAriaValueText={valuetextt}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks={marks}
                  min={0}
                  max={10}
                />
                      {/* <input type="range" value={postRecommendation} onChange={onChange} class="form-range form-control" min="0" max="10" step="1" id="post-recommendation"></input> */}
              </div>
              </div>
              <label for="customRange5" class="form-label">Communication out of 10</label><br></br>
              <div className="container" style={{paddingRight:"20px",paddingLeft:'20px'}}>
              <div class="form-group">
                
                <Slider
                id="post-communication"
                  defaultValue={0}
                  getAriaValueText={valuetexttt}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks={marks}
                  min={0}
                  max={10}
                />
                      {/* <input type="range" value={postCommunication} onChange={onChange}  class="form-range form-control" min="0" max="10" step="1" id="post-communication"></input> */}
              </div>
              </div>
              <label for="customRange6" class="form-label">Support out of 10</label><br></br>
              <div className="container" style={{paddingRight:"20px",paddingLeft:'20px'}}>
              <div class="form-group">
                <Slider
                id="post-support"
                  defaultValue={0}
                  getAriaValueText={valuetextttt}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks={marks}
                  min={0}
                  max={10}
                />
                      {/* <input type="range" value={postSupport} onChange={onChange} class="form-range form-control" min="0" max="10" step="1" id="post-support"></input> */}
              </div>
              </div>
              <button
                type="submit"
                class="btn btn-primary text-right submit mb-4"
              >
                submit
              </button>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={open}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div class="container">
              <Modal.Header closeButton>
                <Modal.Title>Post Details</Modal.Title>
              </Modal.Header>
              <div className="postwork-id">
                <p> {selectedRow.reference_id} </p>
              </div>
              <div class="col-lg-12">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-8">
                      <p>
                        {" "}
                        <span className="postwork-bold">
                          {" "}
                          Assignment Area <br></br>
                          &nbsp; &nbsp;{" "}
                        </span>{" "}
                        {selectedRow.assignment_area}{" "}
                      </p>
                    </div>
                    <div class="col-lg-4">
                      {selectedRow.proposal_timeline && (
                        <p>
                          {" "}
                          <span className="postwork-bold">
                            {" "}
                            Proposal Date <br></br>
                            &nbsp; &nbsp;
                          </span>{" "}
                          {moment(selectedRow.proposal_timeline).format(
                            "YYYY-MM-DD"
                          )}{" "}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-8">
                      {selectedRow.budget && (
                        <p>
                          {" "}
                          <span className="postwork-bold">
                            {" "}
                            Budget <br></br>
                            &nbsp; &nbsp;{" "}
                          </span>{" "}
                          {selectedRow.budget}{" "}
                        </p>
                      )}
                    </div>
                    <div class="col-lg-4">
                      {selectedRow.completion_timeline && (
                        <p>
                          {" "}
                          <span className="postwork-bold">
                            {" "}
                            Completion Date <br></br>
                            &nbsp; &nbsp;
                          </span>{" "}
                          {moment(selectedRow.completion_timeline).format(
                            "YYYY-MM-DD "
                          )}{" "}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      {selectedRow.details && (
                        <p>
                          {" "}
                          <span className="postwork-bold">
                            {" "}
                            Details <br></br>
                            &nbsp; &nbsp;{" "}
                          </span>{" "}
                          {selectedRow.details}{" "}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      {selectedRow.other_information && (
                        <p>
                          {" "}
                          <span className="postwork-bold">
                            {" "}
                            Other Information <br></br>
                            &nbsp; &nbsp;{" "}
                          </span>{" "}
                          {selectedRow.other_information}{" "}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {selectedRow.service_seeker_contact_organization && (
                <div className="border-top"></div>
              )}
              {selectedRow.service_seeker_contact_organization && (
                <Modal.Header>
                  <Modal.Title>Contact Details</Modal.Title>
                </Modal.Header>
              )}
              <div class="col-lg-12">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-8">
                      {selectedRow.service_seeker_contact_organization && (
                        <p>
                          {" "}
                          <span className="postwork-bold">
                            {" "}
                            Organization <br></br>
                            &nbsp; &nbsp;
                          </span>{" "}
                        
                          {selectedRow.service_seeker_contact_organization}
                        </p>
                      )}
                    </div>
                    <div class="col-lg-4">
                      {selectedRow.service_seeker_contact_designation && (
                        <p>
                          {" "}
                          <span className="postwork-bold">
                            {" "}
                            Designation
                            <br></br>
                            &nbsp; &nbsp;
                          </span>{" "}
                          {selectedRow.service_seeker_contact_designation}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-8">
                      {selectedRow.service_seeker_contact_person && (
                        <p>
                          {" "}
                          <span className="postwork-bold">
                            {" "}
                            Name
                            <br></br>
                            &nbsp; &nbsp;
                          </span>{" "}
                          {selectedRow.service_seeker_contact_person}
                        </p>
                      )}
                    </div>
                    <div class="col-lg-4">
                      {selectedRow.service_seeker_contact_email && (
                        <p>
                          {" "}
                          <span className="postwork-bold">
                            {" "}
                            Email
                            <br></br>
                            &nbsp; &nbsp;
                          </span>{" "}
                          {selectedRow.service_seeker_contact_email}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-12">
                      {selectedRow.service_seeker_contact_number && (
                        <p>
                          {" "}
                          <span className="postwork-bold">
                            Phone
                            <br></br>
                            &nbsp; &nbsp;
                          </span>{" "}
                          {selectedRow.service_seeker_contact_number}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <div className="bottom-model"></div>
        </Modal>
      </div>
      <section
        className="iq-feature1 overview-block-ptb grey-bg features py-5"
        id="software-features"
      >
        <div class="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              {userType ? (
                <div style={{ float: "right", marginBottom: "20px" }}>
                  <Link to="/post-work/create" class="swipebox">
                    <button type="button" class="btn btn-primary">
                      {" "}
                      Create Post{" "}
                    </button>
                  </Link>
                </div>
              ) : (
                <select
                  class="form-control col-lg-4 col-md-6 col-sm-12"
                  onChange={handleAssignmentChange}
                  style={{
                    float: "right",
                    marginBottom: "30px",
                    marginRight: "-20px",
                  }}
                >
                  {allSpecializations.map((item) => (
                    <option value={item.value}>{item.name}</option>
                  ))}
                </select>
              )}
              <div></div>
            </div>
              <div className="container">
                <div className="row">
                  {props?.props?.postWorks.posts &&
                    props?.props?.postWorks.posts.map((row) => (
                      <div className="pointer col-lg-4 col-md-6 col-sm-12 iq-mb-30">
                        <div
                          key={row.reference_id}
                          className="iq-blog text-left iq-ptb-30 iq-pr-30 iq-pl-30"
                        >
                          <div class="address">
                            {/* <h6 className="title iq-tw-6">Address</h6> */}
                            <div  onClick={() => handleClickOpen(row)} >
                            <Button
                              className="marginT"
                              variant="link"
                              onClick={() => handleClickOpen(row)}
                            >
                              {row.reference_id}{" "}
                            </Button>
                            {(
                              <div className="pb-5">
                                {props?.props?.postWorks.allSelectedPosts &&
                                props?.props?.postWorks.allSelectedPosts
                                  .length &&
                                props?.props?.postWorks.allSelectedPosts.includes(
                                  row.reference_id
                                ) ? (
                                  <Spinner animation="border" />
                                ) : !row.completed &&
                                  !row.discontinued &&
                                  row.approved ? (
                                  <div>
                                  <Badge color="primary" style={{padding:"10px"}} pill>Approved</Badge> 
                                  </div>
                                ) : (
                                  <div>
                                  {row.completed
                                            ? <Badge color="success" style={{padding:"10px"}} pill>Completed</Badge>
                                            : [
                                                row.discontinued
                                                      ? <Badge color="danger" style={{padding:"10px"}} pill>Discontinued</Badge>
                                                      : [row.approved ? 
                                                      <Badge color="primary" style={{padding:"10px"}} pill>Approved</Badge>
                                                      :
                                                      <Badge color="warning" style={{padding:"10px"}} pill>Pending Approval</Badge>

                                                      ]
                                              ]
                                        }
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="postwork-div">
                              <div className="">
                                <p className="fleft">Assignment Area</p>
                                <p className="fleft1">
                                {row.assignment_area}</p>
                              </div>
                              <div className="">
                                <p className="fleft">Budget</p>
                                <p className="fleft1">{row.budget}</p>
                              </div>
                            </div>
                            <div className="postwork-div">
                              <div className="">
                                <p className="fleft">Details</p>
                                <p numberOfLines={1} className="fleft1">
                                  {row.details < 50
                                    ? `${row.details}`
                                    : `${row.details.substring(0, 49)}`}
                                </p>
                              </div>
                            </div>

                            {/* <div className="postwork-div">
                                                  <div className=''>
                                                  <p className="fleft">Details</p>
                                                  <p numberOfLines={1} className="fright2">{row.details}</p>
                                                  </div>
                                                </div> */}

                            <div id="bdrbtm" class="container-div ">
                              <div class="mt-4">
                                <p className="fleft">Proposal Date</p>
                              </div>
                              <div class="mt-4">
                                <p className="fright">
                                  {moment(row.proposal_timeline).format(
                                    "YYYY-MM-DD"
                                  )}
                                </p>
                              </div>
                            </div>
                            <div class="container-div">
                              <div>
                                <p className="fleft">Completion Date</p>
                              </div>
                              <div>
                                <p className="fright">
                                  {moment(row.completion_timeline).format(
                                    "YYYY-MM-DD"
                                  )}
                                </p>
                              </div>
                            </div>
                            </div>
                            {/* <p>{row.approved ? "Approved" : "Pending"}</p> */}
                            {userType && (
                              <div className="pb-1">
                                {props?.props?.postWorks.allSelectedPosts &&
                                props?.props?.postWorks.allSelectedPosts
                                  .length &&
                                props?.props?.postWorks.allSelectedPosts.includes(
                                  row.reference_id
                                ) ? (
                                  <Spinner animation="border" />
                                ) : !row.completed &&
                                  !row.discontinued &&
                                  row.approved ? (
                                    <div className="container">
                                    <div className="row justify-content-around">
                                      <div className="p-0 col-7 col-sm-7 col-md-7 col-lg-6">
                                      <div onClick={() =>openConfirmationDialog(row,"Complete")} 
                                        style={{alignItems:"center",maxHeight:"50px",width:"140px"}} 
                                        className="btnhovr d-flex btn btn-outline-success">
                                          <img className="trashcan" src={checked} alt="checked" />
                                                &nbsp;<span className="">Complete</span>
                                      </div>
                                          
                                        </div>

                                        {/* <div className="">
                                          <img onClick={() =>
                                            openConfirmationDialog(row, "Delete")
                                          } className="trashcan" src={trash} alt="trash-can" /><br></br>
                                          <span class="tooltiptext"></span>
                                        </div> */}

                                        <div className="p-0 col-7 col-sm-7 col-md-7 col-lg-6">
                                        <div onClick={() => openConfirmationDialog(row,"Discontinue")}  
                                        style={{alignItems:"center",maxHeight:"40px",width:"140px"}} 
                                        className="btnhovr d-flex btn btn-outline-danger">
                                          <img className="trashcan" src={remove} alt="cross" />
                                          &nbsp;<span className="">Discontinue</span>
                                          </div>
                                        </div>
                                        </div>
                                  </div>
                                ) : !row.approved ? (
                                  <div className="container">
                                    <div className="row justify-content-around">
                                      <div style={{alignItems:"center",maxHeight:"40px",width:"140px"}} 
                                        className="btnhovr d-flex btn btn-outline-danger">
                                          <div className="p-0 col-12 col-sm-12 col-md-12 col-lg-12">
                                            <img onClick={() =>
                                              openConfirmationDialog(row, "Delete")
                                            } className="trashcan" src={trash} alt="trash-can" />
                                            &nbsp;<span className="">Delete</span>
                                          </div>
                                       </div>   
                                      </div>
                                  </div>
                                ) : (
                                  <div className="postwork-padding">
                                </div>
                                )
                                }
                              </div>
                            )}
                          </div>
                          <div className="content-blog"></div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
           
          </div>
        </div>
      </section>
    </>
  );
};

const ListPostWork = (props) => {
  const adminToken = localStorage.getItem("user");
  return PostWork(adminToken ? AllPosts : AllPostTypes, props);
};

const mapStateToProps = (state) => {
  // console.log("state === ", state);
  return {
    postWorks: state.post,
    serviceProvider: state.serviceProvider,
    reviewAll: state.serviceSeeker.reviewsAll,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceProviderAction: (id) => dispatch(getServiceProvider(id)),
    getPostWorkAction: () => dispatch(getPostWork()),
    deletePostWorkAction: (data) => dispatch(deletePostWork(data)),
    completePostWorkAction: (data) => dispatch(completePostWork(data)),
    discontinuePostWorkAction: (data) => dispatch(discontinuePostWork(data)),
    getSpecializedPostWorkAction: (specialization) =>
      dispatch(getSpecializedPostWorkAction(specialization)),
    createReviewPostAction: (data) => dispatch(createPostReview(data)),
    getAllReviewsAction: () => dispatch(getReviewsAll()),
    // getIndividualAction: (item) => dispatch(getIndividual(item))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListPostWork);
