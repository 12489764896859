import React,{Component,useRef,useState,useEffect} from 'react';
import {FormControl,Button,Form,NavDropdown} from 'react-bootstrap'
import { NavbarToggler, Collapse,Dropdown, DropdownToggle,Nav,DropdownMenu, DropdownItem,Navbar, } from 'reactstrap';

import { Link, NavLink, withRouter } from "react-router-dom";
import logo1 from "./../../images/apexway.png"
import {authCheck} from "./../auth";
import "./style.css";




const Sidebar = () => {

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownOpenn, setDropdownOpenn] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    const toggleee = () => setDropdownOpen(prevState => !prevState);
    const togglee = () => setDropdownOpenn(prevState => !prevState);

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    let userProfile;
    let userTypes = JSON.parse(localStorage.getItem("user"))?.data?.hasOwnProperty("userType")
    if(userTypes){
        userProfile = "/service-seeker-profile";
    }else {
        userProfile = "/service-provider-profile";
    }
return(
  <header id="main-header" className="header-white">
      <div class="clearfix"></div>
      <Navbar color="light" light expand="lg">
      <Link className="navbar-brand" to="/home" >
         <img className="img-fluids"  src={ logo1 } alt="Modal Image" />
         <p className="frailway slogan">Possibilities Redefined</p>
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
        
              <ul class="navbar-nav ml-lg-auto">
                  <li class="nav-item" id="li-home">
                          <span class="sr-only">(current)</span>
                          <Link class="nav-link"  to="/home">Home</Link>
                  </li>
                  <li class="nav-item" id="li-how-it-works">
                      <Link class="nav-link" to="/how-it-works">How It Works</Link>
                  </li>
                  <li class="nav-item" id="li-post-work">
                      <Link class="nav-link" to="/post-work">Post Work</Link>
                  </li>
                  <li class="nav-item dropdown" id="li-packages">
                 
                  <NavDropdown  id="basic-nav-dropdown"  title={
                    <span>  Packages <i class="fas fa-caret-down"></i> </span>
                            } id="nav-dropdown" >
                    <NavDropdown.Item  className="ddli" eventKey="4.1">   <Link to="/service-provider-packages">Service Provider </Link> </NavDropdown.Item>
                    <NavDropdown.Item className="ddli" eventKey="4.3">   <Link  to="/service-seeker-packages">Service Seeker </Link></NavDropdown.Item>
                 </NavDropdown>
                  </li>
                  <li class="nav-item" id="li-about-us">
                      <Link class="nav-link" to="/About-us">About us</Link>
                  </li>
                  {/* <li class="nav-item" id="li-about-portal">
                      <Link class="nav-link" to="/About-portal">About Portal</Link>
                  </li> */}
                  <li class="nav-item" id="li-contact">
                  <Link class="nav-link" to="/Contact"> Contact 
                      </Link>
                  </li>
              </ul>
      {authCheck() && 
      <Dropdown>
      <Link to={`${userProfile}`}> <DropdownToggle style={{marginLeft: '10px'}}  id="btnbtn" className="button bt-blue "  caret>
            Profile
      </DropdownToggle></Link>
    </Dropdown>
    }
    {!authCheck() &&<Dropdown isOpen={dropdownOpen} toggle={toggleee}>
      <DropdownToggle style={{marginLeft: '10px'}}  id="btnbtn" className="stylingbtn button bt-blue "  caret>
      <i class="fas fa-user dropdown-toggle"></i>&nbsp;Register
      </DropdownToggle>
      <DropdownMenu>
      <Link to="/service-provider-signup">  <DropdownItem >Service Provider</DropdownItem></Link> 
      <Link  to="/service-seeker-signup">   <DropdownItem>Service Seeker</DropdownItem></Link>
        
      </DropdownMenu>
    </Dropdown>}

    {!authCheck() ? <Dropdown isOpen={dropdownOpenn} toggle={togglee}>
      <DropdownToggle style={{marginLeft: '10px',marginRight:'10px'}} id="btnbtn" className="stylingbtn button bt-blue" caret>
      <i class="fas fa-lock"></i>&nbsp;Sign in
      </DropdownToggle>
      <DropdownMenu>
      <Link to="/service-provider-signin"><DropdownItem >Service Provider</DropdownItem></Link>
      <Link  to="/service-seeker-signin"> <DropdownItem>Service Seeker</DropdownItem></Link>
      </DropdownMenu>
    </Dropdown>:

    <Dropdown isOpen={dropdownOpenn} toggle={togglee}>
      <DropdownToggle style={{marginLeft: '10px',marginRight:'10px'}}  id="btnbtn" className="button bt-blue" onClick={ async () =>{
                localStorage.clear()
                window.location.reload();
            
              }} caret>
      <i class="fas fa-lock"></i>&nbsp;Sign out
      </DropdownToggle>
    </Dropdown>
    }
          </Collapse>
      </Navbar>
  </header>
)



}

export default withRouter(Sidebar);

        