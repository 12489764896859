import React, { Component, useEffect } from 'react';
import { Nav, Navbar, FormControl, Button, Form } from 'react-bootstrap'

import { NavLink, withRouter, Link } from "react-router-dom";
import HeaderApp from '../Header/Header';
import FooterApp from '../Footer/Footer';
import bg from '../../images/bg.jpg';
import MetaTags from 'react-meta-tags';



const Serviceprovider = () => {

    useEffect(() => () => { // <-- Now we return the useEffect teardown effect, which will be fired only after the path/search change for the first time
        try {
            // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth',
            });
        } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0);
        }
    }, []);
    return (

        <div>
            <MetaTags>
                <title>Firms Providing Accounting and Financial Services - Packages | APEXWAY</title>
                <meta name="description" content="APEXWAY is state of the art and Pakistan's pioneer marketplace for accounting, taxation, finance, legal and commerce industry service providers to help winning new clients requiring outsourcing services" />
                <meta name="robots" content="noodp,noydir" />
            </MetaTags>
            <div >
                <HeaderApp />
            </div>


            {/* <section class="inner-page-banner">
    <div class="page-heading text-center">
        <h2>Service Providers</h2>
        <span class="section_1-breadcrumbs"><a href="Home.aspx"><i class="fa fa-home home_1"></i>Home</a> <span>/ Packages</span></span>
    </div>
</section> */}

            {/* className="testingdiv" */}
            <div>
                <div
                    ids="iq-home"
                    className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}
                >
                    <div className="container-fluid">
                        <div className="col-lg-12">
                            <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                                Service Providers<br></br>
                                <span class="section_1-breadcrumbs"><span>Packages</span></span>
                            </h1>

                        </div>
                    </div>
                </div>
            </div>


            <section id="pricing" className="overview-block-ptb grey-bg iq-price-table">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="heading-title">
                                <h3>Want to see your profits soar?</h3>
                                <p className="title iq-tw-7">Join today and become part of the panel of professionals</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div id="main-packeg-div" className="col-md-3 col-lg-3">
                            <div id="packegdivstyling2" className="iq-pricing text-center">
                                <div className="price-title iq-parallax iq-over-blue-80 jarallax">
                                    <h2 className="iq-font-white iq-tw-7" id="packegs-name">Starter</h2>
                                    <span className="text-uppercase iq-tw-4 iq-font-white"><span class="align-top">PKR</span> 1,400</span>
                                    <div id='jarallax-container-4' className="customstyling">
                                        <div className="newstyling">
                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li>Registration fee <b>PKR 1,000</b></li>
                                    <li><b>No</b> processing fee</li>
                                    <li><b>100%</b> refund policy (first 30 days)</li>
                                    <li><b>1</b> Area of Specialization</li>
                                    <li><b>PKR 1,400</b> per month</li>
                                    <li><b>PKR 16,800</b> annually</li>
                                    <li className="border-top">Limited package - <b>PKR 5,000</b>
                                        <br />
                                        <i>(for 2 months only)</i></li>

                                </ul>
                                <div className="price-footer">
                                    <Link className="button" to="/service-provider-signup"> Buy Now</Link>
                                </div>
                            </div>
                        </div>

                        <div id="main-packeg-div" className="col-md-3 col-lg-3 wow flipInY r4-mt-30" data-wow-duration="1s">
                            <div id="packegdivstyling2" className="iq-pricing text-center">
                                <div className="price-title iq-parallax iq-over-blue-80 jarallax">
                                    <h2 className="iq-font-white iq-tw-7" id="packegs-name">Basic</h2>
                                    <span className="text-uppercase iq-tw-4 iq-font-white"><span class="align-top">PKR</span> 1,575</span>
                                    <div id='jarallax-container-4' className="customstyling">
                                        <div className="newstyling">

                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li>Registration fee <b>PKR 1,500</b></li>
                                    <li><b>No</b> processing fee</li>
                                    <li><b>100%</b> refund policy (first 30 days)</li>
                                    <li><b>2</b> Areas of Specialization</li>
                                    <li><b>PKR 1,575</b> monthly (per area)</li>
                                    <li><b>PKR 37,800</b> annually</li>
                                    <li className="border-top">Limited package - <b>PKR 7,500</b>
                                        <br />
                                        <i>(for 2 months only)</i></li>

                                </ul>
                                <div className="price-footer">
                                    <Link className="button" to="/service-provider-signup"> Buy Now</Link>
                                </div>
                            </div>
                        </div>

                        <div id="main-packeg-div" className="col-md-3 col-lg-3">
                            <div id="packegdivstyling2" className="iq-pricing text-center">
                                <div className="price-title iq-parallax iq-over-blue-80 jarallax">
                                    <h2 className="iq-font-white iq-tw-7" id="packegs-name">Extended</h2>
                                    <span className="text-uppercase iq-tw-4 iq-font-white"><span class="align-top">PKR</span> 1,700</span>
                                    <div id='jarallax-container-4' className="customstyling">
                                        <div className="newstyling">

                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li>Registration fee <b>PKR 1,500</b></li>
                                    <li>Processing fee <b>PKR 500</b></li>
                                    <li><b>100%</b> refund policy (first 30 days)</li>
                                    <li><b>5</b> Areas of Specialization</li>
                                    <li><b>PKR 1,700</b> monthly (per area)</li>
                                    <li><b>PKR 102,000</b> annually</li>
                                    <li className="border-top">Limited package - <b>PKR 12,000</b>
                                        <br />
                                        <i>(for 2 months only)</i></li>

                                </ul>
                                <div className="price-footer">
                                    <Link className="button" to="/service-provider-signup"> Buy Now</Link>
                                </div>
                            </div>
                        </div>

                        <div id="main-packeg-div" className="col-md-3 col-lg-3">
                            <div id="packegdivstyling2" className="iq-pricing text-center">
                                <div className="price-title iq-parallax iq-over-blue-80 jarallax">
                                    <h2 className="iq-font-white iq-tw-7" id="packegs-name">Premium</h2>
                                    <span className="text-uppercase iq-tw-4 iq-font-white"><span class="align-top">PKR</span> 1,800</span>
                                    <div id='jarallax-container-4' className="customstyling">
                                        <div className="newstyling">

                                        </div>
                                    </div>
                                </div>
                                <ul>
                                    <li>Registration fee <b>PKR 2,500</b></li>
                                    <li>Processing fee <b>PKR 500</b></li>
                                    <li><b>100%</b> refund policy (first 30 days)</li>
                                    <li><b>15</b> Areas of Specialization</li>
                                    <li><b>PKR 1,800</b> monthly (per area)</li>
                                    <li><b>PKR 324,000</b> annually</li>
                                    <li className="border-top">Limited package - <b>PKR 25,000</b>
                                        <br />
                                        <i>(for 2 months only)</i></li>


                                </ul>
                                <div className="price-footer">
                                    <Link className="button" to="/service-provider-signup"> Buy Now</Link>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>


            <FooterApp />

        </div>

    )



}

export default Serviceprovider;