import React, { useEffect } from 'react';



const ScreenWrapper = (props) => {

	return (
		<div className="col-10 w-80 ml-20 main-table-screen" id="main">
			<div className="screen-wrapper-children">  </div>


			<div className="screen-wrapper-children">{props.children}</div>
		</div>
	
	);
};

export default ScreenWrapper;
