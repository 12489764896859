import {
  LOGOUT_USER,
  UNIQUE_EMAIL,
  UNIQUE_NAME,
  UNIQUE_CNIC,
} from "../../Constants/constants";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils";
// import { Alert } from "react-bootstrap";

export const logout = () => (dispatch) => {
  dispatch({
    type: LOGOUT_USER,
    payload: "log out",
  });
};

export const uniqueName = (username) => {
  
  const data = {
    username: username,
  };
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/unique/check-username`,
      data
    )
    .then(function (response) {
      console.log("unique name", response);
      if(response?.data?.success){
        toast.success(`Name ${response?.data?.msg}`, {
          position: "top-right",
          autoClose: 5000,
          className: 'toastdiv',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      else{
        toast.error(`Name ${response?.data?.msg}`, {
          position: "top-right",
          autoClose: 5000,
          className: 'toastdiv',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      
      // dispatch({
      //   type: UNIQUE_NAME,
      //   payload: response.data
      // })

      return response.data;
    })
    .catch(function (err) {
      console.log("unique email", err);

      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      return null;

    });

  return dataResponse;
};

export const uniqueEmail = (email) => {
  const data = {
    email: email,
  };
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/unique/check-email`,
      data
    )
    .then(function (response) {
      console.log("unique email", response?.data?.msg);
      if(response?.data?.success){
        toast.success(`Email ${response?.data?.msg}`, {
          position: "top-right",
          autoClose: 5000,
          className: 'toastdiv',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      else{
        toast.error(`Email ${response?.data?.msg}`, {
          position: "top-right",
          autoClose: 5000,
          className: 'toastdiv',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      
      return response.data;
    })
    .catch(function (err) {
      console.log("unique email", err);
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }

      return null;
    });

  return dataResponse;
};


export const uniqueCnic = (cnic) => {
  const data = {
    cnic: cnic,
  };
  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/unique/check-cnic`,
      data
    )
    .then(function (response) {
      console.log("unique cnic", response);
      if(response?.data?.success){
        toast.success(`CNIC ${response?.data?.msg}`, {
          position: "top-right",
          autoClose: 5000,
          className: 'toastdiv',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      else{
        toast.error(`CNIC ${response?.data?.msg}`, {
          position: "top-right",
          autoClose: 5000,
          className: 'toastdiv',
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      return response.data;
    })
    .catch(function (err) {
      console.log("unique cnic", err);
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }

      return null;
    });

  return dataResponse;
};