import React,{Component, useState} from 'react';
import {Nav,Navbar,FormControl,Button,Form, Spinner,Card} from 'react-bootstrap'

import { NavLink, withRouter } from "react-router-dom";
import FooterApp from '../Footer/Footer';
import HeaderApp from '../Header/Header';
import {sendPassword} from "../../Actions/serviceProvider/ServiceProviderLogin"
import { connect } from "react-redux";


const ResetPassword = (props) => {
    const  [password,setPass] = useState("");
    const getPass = (e)=>{
        setPass(e.target.value)
    }
    const sendPassword = async (e)=> {
        e.preventDefault();
        let tokenFromUrl = window.location.href.split("reset")[1];
        let tokenSplitted;
        if(tokenFromUrl){
            tokenSplitted=   tokenFromUrl.replace("/","");
            let data = {
                password: password,
                token: tokenSplitted
            }
            await props.sendPasswordAction(data,true)
            await setPass('');
        } else {

        }
    }

return(
<div> 
    <div>
        <div ids="iq-home" className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                    Reset your Password <br></br>
                    </h1>
                </div>
            </div>
        </div>
    </div>
   
   
   <div style={{padding: "50px"}}>
       <Card style={{padding: "50px"}}>
       <p>Enter Your New Password</p>

   <Form onSubmit={sendPassword}>
   <Form.Group controlId="formBasicPassword">
    <Form.Label>Password</Form.Label>
    <Form.Control onChange={getPass} value={password} type="password" placeholder="Password" required />
  </Form.Group>



  <Button variant="primary" className="button bt-blue" type="submit">
  {props.serviceProvider.loading ?   <Spinner animation="border" variant="light" /> : "Submit" }

  </Button>
</Form>
</Card>
</div>

<FooterApp/>

</div>
)



}







const mapStateToProps = (state) => {
    return { 
      serviceProvider: state.serviceProvider 
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return { 
        sendPasswordAction: (values,loadingState) => dispatch(sendPassword(values,loadingState))
     }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPassword)