import React, { Component, useState, useEffect } from "react";
import {
  Nav,
  Navbar,
  FormControl,
  Modal,
  Button,
  Form,
  NavDropdown,
  Table,
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  Tabs,
  Tab,
  Badge,
} from "react-bootstrap";
import MultiSelect from "react-multi-select-component";
import moment from "moment";
import { NavLink, withRouter } from "react-router-dom";
import profile from "../../images/profile.png";
import { connect } from "react-redux";
import { authCheck } from "./../auth";
import { logout } from "../../Actions/global/global";
import star from '../../images/star.svg';
import emptystar from '../../images/estar.svg';
import {
  getServiceProvider,
  updateServiceProvider,
  updateAreaOfSpecialization,
} from "../../Actions/serviceProvider/ServiceProvider";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getReviewsServiceprovider } from "../../Actions/serviceProvider/ServiceProviderLogin";
import "./custom.css";
import {Helmet} from "react-helmet";
import MetaTags from 'react-meta-tags';

const options = [
  // { label: "Select Area Of Specialization", value: "select" },
  { label: "Sales/Service Tax Services", value: "sales-service-tax" },
  { label: "Income Tax Services", value: "income-tax" },
  { label: "Accounting Services", value: "accounting" },
  { label: "Audit & Assurance Services", value: "audit-assurance" },
  {
    label: "Fixed Assets Assignments & Related Services",
    value: "fixed-assets-assignments",
  },
  {
    label: "Business Process Outsourcing",
    value: "business-process-outsourcing",
  },
  {
    label: "Public Sector/NPO/Donor Audits & Advisory Services",
    value: "Public-sector-NPO-advisory",
  },
  { label: "Actuarial Services", value: "actuarial" },
  { label: "Legal Advisory Services", value: "legal-advisory" },
  { label: "Transaction Advisory Services", value: "transaction-advisory" },
  { label: "Information System/IT Audits", value: "IT-IS-audits" },
  {
    label: "Cost Accounting, Audit & Related Services",
    value: "cost-Accounting-and-audit",
  },
  { label: "Accounting Advisory", value: "accounting-advisory" },
  { label: "Internal Audit Services", value: "internal-audit" },
  { label: "Corporate Secretarial Services", value: "corporate-secretarial" },
  { label: "Other (Specify)", value: "others" },
];

const ServiceProviderProfile = (props) => {
  const [editable, setEditable] = useState(false);

  const [user, setUser] = useState({});
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [reviewsList, setReviews] = useState([]);
  const [qualityControlRatingCondition, setQualityControlRatingCondition] = useState("");
  const [reviewsMsg, setReviewsMsg] = useState(false);
  const [showAreaOfSpecialization, setShowAreaOfSpecialization] =
    useState(false);
  const [areaOfSpecializationToUpdate, setAreaOfSpecializationToUpdate] =
    useState([]);
    const [other_specialization, setother_specialization] =
    useState([]);
    

  useEffect(async () => {
    // console.log("In useeffect === ", props.serviceProvider);
    if (await authCheck()) {
      props.history.push("/service-provider-profile");
    }
    await props.getServiceProviderAction();
    await setUser(props.serviceProvider);
  }, []);

  useEffect(async () => {
    // console.log("In update useeffect === ", props.serviceProvider);
    Quality();
    await props.getReviewsAction();
    await setReviews(props.reviews);
    await setReviewsMsg(true);
    if (await authCheck()) {
      props.history.push("/service-provider-profile");
    }
    setUser(props.serviceProvider);
    setAreaOfSpecializationToUpdate(
      props?.serviceProvider?.area_of_specialization &&
        props?.serviceProvider?.area_of_specialization.length > 0
        ? options.filter((item) =>
            props?.serviceProvider?.area_of_specialization.includes(item.value)
          )
        : []
    );
    setother_specialization(props?.serviceProvider?.other_specialization);
  }, [props.serviceProvider]);


  async function handleSubmitAreaofSpecialization(event) {
    event.preventDefault();
    let filteredAreas = areaOfSpecializationToUpdate.map((item) => item.value);
    await props.updateAreaOfSpecializationAction({
      area_of_specialization: filteredAreas,
      other_specialization:other_specialization,
    });
  }
  
  function setAreaOfSpecialization(alreadySelectedAreas, value) {
    return value;
  }
  const otherResult =  props.serviceProvider.area_of_specialization?.includes("others");
  const newotherResult = areaOfSpecializationToUpdate?.some(item => item.value === "others");


  const formik = useFormik({
    initialValues: {
      registered_with: props?.serviceProvider?.registered_with,
      regulatory_body: props?.serviceProvider?.regulatory_body,
      quality_control_rating: props?.serviceProvider?.quality_control_rating,
      affiliations: props?.serviceProvider?.affiliations,
      address1: props?.serviceProvider?.address1,
      address2: props?.serviceProvider?.address2,
      contact_number1:props?.serviceProvider?.contact_number1,
      contact_number2:props?.serviceProvider?.contact_number2,
      no_of_offices_pk:props?.serviceProvider?.no_of_offices_pk,
      memberships:props?.serviceProvider?.memberships,
      sbp_panel:props?.serviceProvider?.sbp_panel,
      total_staff:props?.serviceProvider?.total_staff,
      designation:props?.serviceProvider?.designation,
      email_other:props?.serviceProvider?.email_other,
      website: props?.serviceProvider?.website,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
    }),
    onSubmit: async (values) => {
      const {
        website,
        address2,
        address1,
        email_other,
        affiliations,
        contact_number1,
        contact_number2,
        designation,
        membership_details,
        memberships,
        registered_with,
        regulatory_body,
        no_of_offices_pk,
        sbp_panel,
        total_staff,
        quality_control_rating,
      } = values;
      // console.log("values", values);
      const { updateServiceProviderAction } = props;
      let sendingData = { ...values };
      if (
        sendingData?.area_of_specialization &&
        sendingData?.area_of_specialization.length > 0
      ) {
        let filteredAreas = sendingData.area_of_specialization.map(
          (item) => item.value
        );
        sendingData.area_of_specialization = filteredAreas;
        sendingData.other_specialization = [other_specialization];
      }
      await updateServiceProviderAction(sendingData, true);
    },
  });

  // console.log(formik.values.quality_control_rating,"asdasdasdkasjdhajkdhasjkdkashdkjashdkjashdk")
  function Quality() {
    if(formik.values.quality_control_rating != "No"){
      setQualityControlRatingCondition("Yes")
    }
    else {
      setQualityControlRatingCondition("")
    }
    
  };

  const fetchReviews = async () => {
    await props.getReviewsAction();
    await setReviews(props.reviews);
    await setReviewsMsg(true);
  };

  const logoutUser = async () => {
    // await props.logoutUserAction();
    localStorage.clear();
    window.location.reload();
  };
  function renderStar(len) {
    const temp = [];
   for (let i=0; i < len; i++) {
    temp.push(i);
  } 
  return  temp.map((val) => <img src={star} alt="as" className="star"/>); 
 };

 function emptyStar(len) {
  const temp = [];
 for (let i=0; i < 10-len; i++) {
  temp.push(i);
} 
return  temp.map((val) => <img src={emptystar} alt="as" className="star"/>); 
};

function onChange(event) {
  const { value, name, id } = event.target;

  switch (id) {
    case "dd-serv-quality-control-rating":
      setQualityControlRatingCondition(value);
      break;

      case "other_specialization":
        setother_specialization(value);
      break;

      default:
        // console.log("value");
    }
  }

  // console.log("pops", props);

  return (
    <>
    {/* <MetaTags>
      <title>Service Provider Profile | Apexway</title>
    </MetaTags> */}
    <Helmet>
        <title>Service Provider Profile | Apex-Way</title>
    </Helmet>
    <div class="bgcoolors" style={{ marginTop: "50px"}}>
      <Container>
      
      <div className="margintop">
      <span class="text-uppercase page-subtitle">OverView</span>
        <h3  class="page-title">Service Provider Profile</h3>
      </div>
        <Row>
          <Col lg={4} style={{ marginTop: "0.8em" }}>
          <Card className="shadow">
              {/* <Card.Header className="sppheader">Service Provider Profile</Card.Header> */}
              <Card.Body>
                {/* <img src={profile}/> */}
                <ListGroup variant="flush">
                  <ListGroup.Item className="cardname">
                    {" "}
                    {props?.serviceProvider?.name}{" "}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {" "}
                    {props?.serviceProvider?.email}{" "}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {" "}
                    {props?.serviceProvider?.memberships}
                  </ListGroup.Item>
                </ListGroup>
                <button id="btnbtn" onClick={handleShow} className="button bt-blue">Update
                 </button>
              </Card.Body>
            </Card>
            <Card className="shadow bottomcard" >
              <Card.Header className="sppheader">Area Of Specialization</Card.Header>
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item  style={{
                                            textAlign: "left",
                                          }}>
                  {props?.serviceProvider
                                  ?.area_of_specialization &&
                                  props?.serviceProvider
                                    ?.area_of_specialization &&
                                  props?.serviceProvider?.area_of_specialization.map(
                                    (item) => {
                                      return (
                                        <span
                                          class="badge badge-primary"
                                          style={{
                                            marginLeft: "5px",
                                            padding: "8px",
                                            marginTop: "6px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {item!="others"?item:item+" : "+props?.serviceProvider.other_specialization[0]}{" "}
                                        </span>
                                      );
                                    }
                                  )}
                    {props?.serviceProvider?.other_specialization ? "" : 
                          <span
                              class="badge badge-primary"
                              style={{
                                marginLeft: "5px",
                                padding: "8px",
                                marginTop: "6px",
                                textAlign: "left",
                                          }}
                                        >
                                        Others({props?.serviceProvider
                                  ?.other_specialization &&
                                  props?.serviceProvider
                                    ?.other_specialization &&
                                  props?.serviceProvider?.other_specialization}){" "}
                                        </span>}
                            
                  </ListGroup.Item>
                </ListGroup>
                 <button id="btnbtn" className="button bt-blue"  onClick={() => {setShowAreaOfSpecialization(true);}}>
                                Update
                              </button>
              </Card.Body>
            </Card>
          </Col>

          <Modal  show={showAreaOfSpecialization} onHide={handleClose}>
            <form
              onSubmit={handleSubmitAreaofSpecialization}
              style={{
                border: "2px solid silver",
                padding: "50px",
                textAlign: "left",
                border: "3%",
              }}
            >
              <h2> Update Area of Specialization </h2>
              <Row>
                <Col>
                  <label htmlFor="area_of_specialization">
                    {" "}
                    {/* Area of Specialization{" "} */}
                  </label>

                  {/* <MultiSelect
                    options={options}
                    // value={selected}
                    // onChange={setSelected}
                    labelledBy="Select"
                  /> */}
                  <React.Fragment
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                  >
                    <MultiSelect
                      style={{
                        margin: "10px",
                      }}
                      id="area_of_specialization"
                      name="area_of_specialization"
                      value={areaOfSpecializationToUpdate}
                      options={options}
                      onChange={(value) => {
                        // console.log("value ===---- ", value);
                        setAreaOfSpecializationToUpdate([...value]);
                      }}
                    />
                    
                  </React.Fragment>
                </Col>
              </Row>
              <Row>
              {newotherResult ? <Col>
                  <label
                    htmlFor="other_specialization"
                    className="labels-seeker-update"
                    style={{
                      marginLeft: "-2px",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    Other Specialization:
                  </label>
                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="other_specialization"
                    name="other_specialization"
                    type="text"
                    onChange={onChange}
                    value={other_specialization}
                  />
                </Col> : 
                <input
                    className="displayhide form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="other_specialization"
                    name="other_specialization"
                    type="text"
                    value={""}
                  /> }
              </Row>
              <button
                type="submit"
                className="btn btn-primary"
                style={{ marginTop: "20px"}}
              >
                {" "}
                Submit{" "}
              </button>
              <Button style={{ marginTop: "20px", marginLeft: "20px" }} onClick={() => {setShowAreaOfSpecialization(false);}}>
                Close
              </Button>
              
            </form>
          </Modal>

          <Modal show={show} onHide={handleClose}>
            <form
              onSubmit={formik.handleSubmit}
              style={{
                border: "2px solid silver",
                padding: "50px",
                textAlign: "left",
                border: "3%",
              }}
            >
              <h2> Update Profile </h2>

              <Row>
                <Col>
                  <label
                    htmlFor="registered_with"
                    className="labels-seeker-update"
                    style={{
                      marginLeft: "-2px",
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    Registered With:
                  </label>
                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="registered_with"
                    name="registered_with"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.registered_with}
                  />
                  {formik.touched.registered_with &&
                  formik.errors.registered_with ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.registered_with}
                    </div>
                  ) : null}
                </Col>
                <Col>
                  <label
                    htmlFor="SBP_panel"
                    style={{
                      marginTop: "20px",
                    }}
                  >
                    {" "}
                    SBP Panal:
                  </label>

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="sbp_panel"
                    name="sbp_panel"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sbp_panel}
                  />
                  {formik.touched.sbp_panel &&
                  formik.errors.sbp_panel ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.sbp_panel}
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <label
                    htmlFor="memberships"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    Membership With Other...:
                  </label>

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="memberships"
                    name="memberships"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.memberships}
                  />
                  {formik.touched.memberships &&
                  formik.errors.memberships ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.memberships}
                    </div>
                  ) : null}
                </Col>

                <Col>
                  <label
                    htmlFor="affiliations"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    Affiliations:
                  </label>

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="affiliations"
                    name="affiliations"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.affiliations}
                  />
                  {formik.touched.affiliations && formik.errors.affiliations ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.affiliations}
                    </div>
                  ) : null}
                </Col>
              </Row>


              <Row>
                <Col>
                  <label
                    htmlFor="no_of_offices_pk"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    Number Of Offices :
                  </label>

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="no_of_offices_pk"
                    name="no_of_offices_pk"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.no_of_offices_pk}
                  />
                  {formik.touched.no_of_offices_pk &&
                  formik.errors.no_of_offices_pk ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.no_of_offices_pk}
                    </div>
                  ) : null}
                </Col>

                <Col>
                  <label
                    htmlFor="loc_of_offices_pk"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    Designation:
                  </label>

                  <select
                        id="designation"
                        class="form-control"
                        name="designation"
                        value={formik.values.designation}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{
                            margin: "10px",
                            marginLeft: "0px",
                          }}
                      >
                      <option  hidden value="Yes">{formik.values.designation}</option>
                        <option value="Partner">Partner</option>
                        <option value="Director">Director</option>
                      </select>
                </Col>
              </Row>


              <Row>
                <Col>
                  <label
                    htmlFor=""
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    Total Number Of Staff:
                  </label>

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="total_staff"
                    name="total_staff"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.total_staff}
                  />
                  {formik.touched.total_staff &&
                  formik.errors.total_staff ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.total_staff}
                    </div>
                  ) : null}
                </Col>

                <Col>
                  <label
                    htmlFor="contact_number1"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    Contact Number (1):
                  </label>

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="contact_number1"
                    name="contact_number1"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contact_number1}
                  />
                  {formik.touched.contact_number1 &&
                  formik.errors.contact_number1 ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.contact_number1}
                    </div>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col>
                  <label
                    htmlFor="contact_number2"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Contact Number (2)
                  </label>

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="contact_number2"
                    name="contact_number2"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contact_number2}
                  />
                  {formik.touched.contact_number2 && formik.errors.contact_number2 ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.contact_number2}
                    </div>
                  ) : null}
                </Col>
                <Col>
                  <label
                    htmlFor="quality_control_rating"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    {" "}
                    Quality Control Rating:
                  </label>
                  <select
                            id="dd-serv-quality-control-rating"
                            class="form-control"
                            value={qualityControlRatingCondition}
                            onChange={onChange}
                            style={{
                                margin: "10px",
                                marginLeft: "0px",
                              }}
                          >
                          {formik.values.quality_control_rating === "No" ?
                          <option hidden value={formik.values.quality_control_rating}>{formik.values.quality_control_rating}</option> :
                          <option  hidden value="Yes">Yes</option> }
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          {qualityControlRatingCondition === "Yes" ?
                              <div className="conditiondiv">
                              <label>Current Status(Quality Control Rating):</label>
                              <input
                                style={{
                                    margin: "10px",
                                    marginLeft: "0px",
                                  }}
                                  id="quality_control_rating"
                                    name="quality_control_rating"
                                    type="text"
                                    required="true"
                                    class="form-control"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.quality_control_rating}
                                
                              /></div> 
                          : 
                          <div>
                          <input
                           style={{
                                margin: "10px",
                                marginLeft: "0px",
                              }}
                            
                            hidden="true"
                                id="quality_control_rating"
                                name="quality_control_rating"
                                type="text"
                                required= "true"
                                class="form-control"
                                value={formik.values.quality_control_rating = "No"}
                          /></div> 
                          }
                  {formik.touched.quality_control_rating &&
                  formik.errors.quality_control_rating ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.quality_control_rating}
                    </div>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col>
                  <label
                    htmlFor="address1"
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    Address (1):
                  </label>

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="address1"
                    name="address1"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address1}
                  />
                  {formik.touched.address1 && formik.errors.address1 ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.address1}
                    </div>
                  ) : null}
                </Col>
                <Col>
                  <label
                    htmlFor="memberships"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Memberships
                  </label>

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                      marginTop:"5px",
                    }}
                    id="memberships"
                    name="memberships"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.memberships}
                  />
                  {formik.touched.memberships && formik.errors.memberships ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.memberships}
                    </div>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col>
                  <label
                    htmlFor="address2"
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    Address (2)
                  </label> 

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                    }}
                    id="address2"
                    name="address2"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address2}
                  />
                  {formik.touched.address2 && formik.errors.address2 ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.address2}
                    </div>
                  ) : null}
                </Col>
                <Col>
                  <label
                    htmlFor="memberships"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Email Other
                  </label>

                  <input
                    className="form-control"
                    style={{
                      margin: "10px",
                      marginLeft: "0px",
                      marginTop:"5px",
                    }}
                    id="email_other"
                    name="email_other"
                    type="text"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email_other}
                  />
                  {formik.touched.email_other && formik.errors.email_other ? (
                    <div style={{ color: "white" }}>
                      {formik.errors.email_other}
                    </div>
                  ) : null}
                </Col>
              </Row>
              <Button style={{ marginTop: "5px" }} onClick={handleClose}>
                Close
              </Button>

              <button
                type="submit"
                className="btn btn-primary"
                style={{ marginTop: "5px", marginLeft: "10px" }}
              >
                {" "}
                Submit{" "}
              </button>
            </form>
          </Modal>

          <Col lg={8} style={{ marginTop: "0.2em" }}>
           
            <Row style={{ marginTop: "10px" }}>
              <Col lg={12}>
                <Card className="shadow">
                  <Card.Body>
                    {/* <Button onClick={logoutUser}> Logout </Button> */}

                    <Tabs
                      defaultActiveKey="profile"
                      id="uncontrolled-tab-example"
                    >
                      <Tab eventKey="profile" title="Profile">
                        <br />
                          <Container>
                          <Row>
                              <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Name:<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceProvider?.name}
                              {/* {JSON.parse(localStorage.getItem("user")).data.id} */}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Registration Number:<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                    {props?.serviceProvider?.registration_number}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>  

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Registered With:<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                                      {props?.serviceProvider?.registered_with}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            
                            <p>
                              <span className="postwork-bold">National Tax Number(NTN):<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                                      {props?.serviceProvider?.ntn_strn}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>  
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Contact Number (1):<br></br>
                              &nbsp;	&nbsp; </span>{" "}{props?.serviceProvider?.contact_number1}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Contact Address(Main Office):<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceProvider?.address1}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Signup email:<br></br>
                              &nbsp;	&nbsp; </span>{" "}{props?.serviceProvider?.email}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                        <p>
                              <span className="postwork-bold">Name of contact person:<br></br>
                              &nbsp;	&nbsp; </span>{" "}{props?.serviceProvider?.contacts}
                            </p>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Designation:<br></br>
                              &nbsp;	&nbsp; </span>{" "}{props?.serviceProvider?.designation}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                        <p>
                              <span className="postwork-bold">Reference id:<br></br>
                              &nbsp;	&nbsp; </span>{" "}{props?.serviceProvider?.reference_id}
                            </p>
                        </div>
                        </div>
                        </div>
                        </div>
                        <hr className="review-card"></hr>
                        <div style={{ marginBottom:"20px"}}>
                        <h4 style={{ marginBottom:"20px",marginLeft:'25px',textAlign: "left"}} >Additional Information</h4>
                        </div> 
                        <div class="col-lg-12">
                        <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}} class="col-lg-8">
                              <p>
                                <span className="postwork-bold">Member Since:<br></br>
                                    &nbsp;	&nbsp; </span>{" "}
                                    {" "}
                                        {moment(
                                          props?.serviceSeeker?.created_at
                                        ).format("YYYY-MM-DD HH:MM")}
                                  </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Total Staff:<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceProvider?.total_staff}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Contact Number(2):<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                                  {props?.serviceProvider?.contact_number2}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Contact address(2):<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                                  {props?.serviceProvider?.address2}
                            </p>
                            
                            
                        </div>
                        </div>
                        </div>
                        </div>  

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                          <p>
                              <span className="postwork-bold">Email-other:<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                                  {props?.serviceProvider?.email_other}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                          <p>
                              <span className="postwork-bold">Website:<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                                  {props?.serviceProvider?.website}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div> 

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Regulatory body:<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceProvider?.regulatory_body}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Quality control rating:<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceProvider?.quality_control_rating}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div> 

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Membership With Other Professional Bodies:<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceProvider?.memberships}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Affiliations with International Network Firms:<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceProvider?.affiliations}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">SBP panel:<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceProvider?.sbp_panel}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Number of offices in Pakistan:<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceProvider?.no_of_offices_pk}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Location of offices:<br></br>
                              &nbsp;	&nbsp; </span>
                              {props?.serviceProvider
                                  ?.loc_of_offices_pk &&
                                  props?.serviceProvider
                                    ?.loc_of_offices_pk &&
                                  props?.serviceProvider?.loc_of_offices_pk.map(
                                    (item) => {
                                      return (
                                        <span
                                          class="badge badge-primary"
                                          style={{
                                            marginLeft: "5px",
                                            padding: "8px",
                                            marginTop: "6px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {item}{" "}
                                        </span>
                                      );
                                    }
                                  )}
                            </p>
                        </div>
                       
                        </div>
                        </div>
                        </div>
                        </Row>
                            </Container>
                          
                      </Tab>

                      <Tab eventKey="reviews" title="Reviews">
                        <br />
                          {reviewsMsg &&
                            props?.reviews &&
                            props?.reviews?.length === 0 && (
                              <p> No reviews Found</p>
                            )}

                          <Row>
                            <Col>
                              <div>
                                {props?.reviews &&
                                  props?.reviews.map((item, index) => {
                                    return (
                                      <div>
                                <hr className="review-card" />
                                
                                <div>

                                 <p style={{ textAlign: "left", fontWeight: "bold", marginTop:"10px"}}>
                                  Name - {" "} {moment(item.created_at).format( " kk:mm (DD-MM-YYYY) ")}{" "}
                                </p>
                                <div class="col-lg-12">
                                  <div class="container">
                                  <div class="row">
                                  <div class="col-lg-7">
                                  <div style={{ textAlign: "left" }}>
                                     <p>{" "}
                                    <span style={{ fontWeight: "bold" }}>{item.heading}</span> - {item.content}{" "} </p>
                                   </div>
                                  </div>
                                  <div class="col-lg-5">
                                  <div style={{ textAlign: "left" }}>
                                     <p>{" "}
                                    <b>Area Of Specialization : </b> <br></br>
                                    <span style={{ marginLeft:"10px" }}>{item.area_of_specialization}</span> <br></br> <b>Task Completion :</b> <br></br><p style={{ marginLeft:"10px" }}> {item.task_completion}{" "} </p></p>
                                   </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                                  <div class="col-lg-12">
                                  <div class="container">
                                  <div class="row">
                                  <div class="col-lg-6">
                                  <div>
                                      <p style={{ fontWeight: "bold" }}>{" "}
                                      Rating: (<span>{item.rating}/10</span>)<br></br>  
                                     
                                      {renderStar(Number(item.rating))}{emptyStar(Number(item.rating))}
                                          </p>
                                        </div>
                                  </div>
                                  <div class="col-lg-6">
                                  <div>
                                      <p style={{ fontWeight: "bold"}}>{" "}
                                      Recommendation: (<span>{item.recommendation}/10</span>)<br></br>  
                                      {renderStar(Number(item.recommendation))}{emptyStar(Number(item.recommendation))}
                                          </p>
                                        </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                                  <div class="col-lg-12">
                                  <div class="container">
                                  <div class="row">
                                  <div class="col-lg-6">
                                  <div>
                                      <p style={{ fontWeight: "bold"}}>{" "}
                                      Support: (<span>{item.support}/10</span>)<br></br>  
                                      {renderStar(Number(item.support))}{emptyStar(Number(item.support))}
                                          </p>
                                        </div>
                                  </div>  
                                  <div class="col-lg-6">
                                  <div>
                                      <p style={{ fontWeight: "bold" }}>{" "}
                                      Communication: (<span>{item.communication}/10</span>)<br></br>  
                                      {renderStar(Number(item.communication))}{emptyStar(Number(item.communication))}
                                          </p>
                                        </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                                </div>
                                </div>
                                    );
                                  })}
                              </div>
                            </Col>
                          </Row>
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    serviceProvider: state.serviceProvider.user,
    reviews: state.serviceProvider.reviews,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReviewsAction: () => dispatch(getReviewsServiceprovider()),
    getServiceProviderAction: (id) => dispatch(getServiceProvider(id)),
    updateServiceProviderAction: (values) =>
    dispatch(updateServiceProvider(values)),
    updateAreaOfSpecializationAction: (values) => dispatch(updateAreaOfSpecialization(values)),
    // getIndividualAction: (item) => dispatch(getIndividual(item))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceProviderProfile);
