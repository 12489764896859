// import loadScript from 'load-script';

let initialized = false;
let queue = [];

export function fb(callback) {
  if (initialized) {
    callback(window.FB);
  } else {
    queue.push(callback);
    if (!window.fbAsyncInit) {
      window.fbAsyncInit = () => {
        window.FB.init({
        //   appId: window.config.facebook.appId,
        //   autoLogAppEvents: true,
        //   status: true,
        //   cookie: true,
          xfbml: true,
          version: 'v12.0',
        });
        initialized = true;
        queue.forEach(cb => cb(window.FB));
        queue = null;
      };
      const script = document.createElement('script');
      const isDebug = window.localStorage.getItem('fb:debug') === 'true';
      script.src = `https://connect.facebook.net/en_US/sdk/xfbml.customerchat${isDebug ? '/debug' : ''}.js`; 
      // const script = window.localStorage.getItem('fb:debug') === 'true'
      //   ? 'xfbml.customerchat/debug.js'
      //   : 'xfbml.customerchat.js';
      // script.src(`https://connect.facebook.net/en_US/sdk/${script}`, { async: true });
    }
  }
}
