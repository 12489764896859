import React,{Component} from 'react';
import {Nav,Navbar,FormControl,Button,Form} from 'react-bootstrap'

import { NavLink, withRouter } from "react-router-dom";
import FooterApp from '../Footer/Footer';
import HeaderApp from '../Header/Header';
import MetaTags from 'react-meta-tags';

const Contact = () => {

return(


<div> 
    <MetaTags>
        <title>Contact Us | Apexway</title>
        <meta name="description" content="Connect to seek or provide professional services in accounting and finance." />
        <meta name="robots" content="noodp,noydir" />
    </MetaTags>

    <div >
  
    <HeaderApp/>

   </div>
   
   
   {/* <section class="inner-page-banner">
   <div class="page-heading text-center">
    <h2>Contact Us</h2>
    <span class="section_1-breadcrumbs"><a href="Home.aspx"><i class="fa fa-home home_1"></i> Home</a></span>
   </div>
   </section> */}
   
   <div>
                <div
                    ids="iq-home"
                    className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}
                >
                <div className="container-fluid">
                <div className="col-lg-12">
                                    <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                                    Contact Us<br></br>
                                    </h1>
                                    
                                </div>
                    </div>
                </div>
                </div>
                <section id="contact" className="footer-info grey-bg">
                    <div className="container">
                    <div className="row">
                            <div className="mt-5 col-lg-12 col-md-12">
                                <div className="heading-title text-center">
                                 <h3 className="title iq-tw-6">Contact Us</h3>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-space-between">
                        <div className="col-md-6 col-lg-1">

                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="iq-get-in">
                                    <h4 className="iq-tw-7 iq-mb-50">Get in Touch</h4>
                                        <form method="post" >
                                            <div className="contact-form">
                                                <div className="section-field iq-mb-10">
                                                    <input type="text" name="Name" placeholder="Name" required=""/>
                                                </div>
                                                <div className="section-field iq-mb-20">
                                                <input type="email" class="email" name="Email" placeholder="Email" required=""/>
                                                </div>
                                                <div className="section-field iq-mb-20">
                                                <input type="text" name="Phone no" placeholder="Phone" required=""/>
                                                </div>
                                                <div className="section-field textarea iq-mb-20">
                                                    <label htmlFor="contact_message"/>
                                                    <textarea name="Message" placeholder="Message" required=""></textarea>
                                                </div>
                                                <div className="section-field iq-mt-20">
                                                </div>
                                                <button id="submit" name="submit" type="submit" value="Send"
                                                        className='subbtn button iq-mt-15 '>Send Message
                                                </button>
                                                <div className="alert alert-success alert-dismissible fade show" role="alert" id="success">
                                                    <strong>Thank You, Your message has been received.</strong>.
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                </div>
                            </div>
                            
                            <div className="col-md-6 col-lg-4">
                            <section className="iq-feature1 overview-block-ptb " id="software-features">
                                <div className="container">
                                    <div className="row">
                                            <div  className="col-lg-12 col-md-12 col-sm-12 iq-mb-10">
                                                <div className='iq-blog text-left iq-ptb-30 iq-pr-30 iq-pl-30'>
                                                    <div class="address">
                                                    <h6 className="title iq-tw-6">Address</h6>
                                                        <p><span class="fa fa-home"></span>A-101, Block H, Hyderi, Karachi, Pakistan</p>
                                                    </div>
                                                    <div className="content-blog">
                                                    </div>
                                                </div>
                                            </div>
                                            <div  className="col-lg-12 col-md-12 col-sm-12 iq-mb-10">
                                                <div className='iq-blog text-left iq-ptb-30 iq-pr-30 iq-pl-30'>
                                                <div class="address address-mdl">
                                                <h6 className="title iq-tw-6">Phone</h6>
                                                    <p><span class="fa fa-phone"></span> +92 (345) 8228575</p>
                                                    {/* <p><span class="fa fa-mobile"></span>+92 (317) 2710008</p> */}
                                                </div>
                                                    <div className="content-blog">
                                                    </div>
                                                </div>
                                            </div>
                                            <div  className="col-lg-12 col-md-12 col-sm-12 iq-mb-10">
                                                <div className='iq-blog text-left iq-ptb-30 iq-pr-30 iq-pl-30'>
                                                <div class="address">
                                                <h6 className="title iq-tw-6">Email</h6>
                                                    <p><span class="fa fa-envelope"></span> <a href="mailto:mail@example.com">info@apex-way.com</a></p>
                                                    <p><span class="fa fa-globe"></span> <a href="mailto:website@example.com">www.apex-way.com</a></p>
                                                </div>
                                                    <div className="content-blog">
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                 </div>
                            </section>
                            </div>
                        </div>
                    </div>
                </section>
   
                
   
   {/* <section class="features py-5">
    
   <section class="contact">
   <div class="container">
    <div class="row contact-footer-social">
        <div class="col-md-7 contact-right"> 
            <form action="#" method="post">  
                <input type="text" name="Name" placeholder="Name" required=""/>
                <input type="email" class="email" name="Email" placeholder="Email" required=""/>
                <input type="text" name="Phone no" placeholder="Phone" required=""/>
                <textarea name="Message" placeholder="Message" required=""></textarea>
                <div class="read mt-3">
                    <a href="#">Submit <span class="btn ml-2"><i class="fas fa-arrow-right" aria-hidden="true"></i></span></a>
                </div>	
            </form>
        </div>
        <div class="col-md-5 mt-md-0 mt-5 contact-left">
            <div class="address">
                <h5>Address:</h5>
                <p><span class="fa fa-home"></span>A-101, Block H, Hyderi, Karachi, Pakistan</p>
            </div>
            <div class="address address-mdl">
                <h5>Phones:</h5>
                <p><span class="fa fa-phone"></span> +92 (345) 8228575</p>
                <p><span class="fa fa-mobile"></span>+92 (317) 2710008</p>
            </div>
            <div class="address">
                <h5>Email:</h5>
                <p><span class="fa fa-envelope"></span> <a href="mailto:mail@example.com">info@apex-way.com</a></p>
                <p><span class="fa fa-globe"></span> <a href="mailto:website@example.com">www.apex-way.com</a></p>
            </div>
        </div>
    </div>
   </div>
   </section>
   
   </section> */}
   
   
<FooterApp/>
  
</div>
)



}

export default Contact;
