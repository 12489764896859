import React,{Component, useEffect, useState} from 'react';
import { NavLink, withRouter } from "react-router-dom";
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {Container,Row,Col,Spinner,Form,Button} from 'react-bootstrap';
import {
  toast
} from 'react-toastify';
import { useFormik } from 'formik';
import {
  SITE_KEY,
} from "../../Constants/constants";
import * as Yup from 'yup';
import {loginIndividual} from "../../Actions/serviceSeeker/individualLogin";
import {connect} from "react-redux";
import login_bg from '../../images/login_bg.svg';
import {authCheck} from '../auth';
import { Link } from "react-router-dom";
import visbilty from '../../images/visibility.svg';
import invisible from '../../images/invisible.svg';
import MetaTags from 'react-meta-tags';
import Recaptcha from 'react-recaptcha';


const ServiceSeekerSignIn = (props) => {
  const [modal2, setModal2] = useState(false);
  const modalTogglee = () => setModal2(!modal2);

  useEffect(()=>{
    if(authCheck()){
      props.history.push("/service-seeker-profile")
    }
  },[])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
   
    },
    validationSchema: Yup.object({

      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string()
      .max(105, 'Must be 105 characters or less')
      .required('Required'),
    }),
    onSubmit: async values => {
      if(verified){
        const {email,password} = values;
        const {loginIndividualAction,individualUser} = props
        await loginIndividualAction(values,true);
      }
      else{
        setModal2(true);
      }
    },
  });

  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [loading,setLoading] = useState(false);
  const [verified, setverified] = useState(false);

  const [imageIcon, setimageIcon] = useState(visbilty);
  const [passwordShown, setPasswordShown] = useState(false);



  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setimageIcon(passwordShown ? visbilty : invisible)
  };
  
  const callback2 = function () {
    setverified(true)
  };

return(

<> 
  <MetaTags>
    <title>Pakistan's Pioneer Marketplace for Outsourcing Accounting, Taxation And Commerce Related Assignments | APEXWAY</title>
    <meta name="description" content="Connect to find credible professional service providers in accounting, taxation and finance." />
    <meta name="robots" content="noodp,noydir" />
  </MetaTags>
          <div>
        <Modal size="lg" isOpen={modal2} toggle={modalTogglee} className="success-msg">
          <ModalHeader toggle={modalTogglee}>Verification Failed !!!</ModalHeader>
          <ModalBody>
          reCAPTCHA verification is required to proceed on this website<br></br>
          Click the checkbox to get a verification challenge
          </ModalBody>
        </Modal>
      </div>
<div class="content mt-5">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <img src={login_bg} alt="Image" class="img-fluid" />
        </div>
        <div class="col-md-6 contents">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <div class="mb-4">
              <h3>Service Seeker<br></br> Sign In</h3>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div class="form-group first">
                <input
                           class="form-control"
                              id="email"
                              name="email"
                              type="email"
                              placeholder='Email'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div style={{color: "red",fontSize:'13px'}}>{formik.errors.email}</div>
                            ) : null}

              </div>
              
              <div class="form-group last mb-4">
                <input
                    class="form-control"
                      id="password"
                      name="password"
                      placeholder='Password'
                      type={passwordShown ? "text" : "password"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div style={{color: "red",fontSize:'13px'}}>{formik.errors.password}</div>
                    ) : null}
                    <img onClick={togglePasswordVisiblity} className="visbility-icon-signin" src={imageIcon}  alt="visbility-icon" />
              </div>
              
              <div class="d-flex mb-3 align-items-center">
                <Link to="/forgot-password" id="fpass" style={{color: "black"}}>Forgot Password ?</Link>
              </div>
              <div class="signincaptche">
              <Recaptcha
                 sitekey={SITE_KEY}
                  render="explicit"
                  onloadCallback={""}
                  verifyCallback={callback2}
              />
              </div>
              {verified ? <button type="submit" disabled={loading} value="Log In" class="btn btn-block btn-primary" >
              {props.loading ?   <Spinner animation="border" variant="light" /> : "Log In" }
              </button> :
              <button type="submit" disabled value="Log In" class="btn btn-block btn-primary" >
              {props.loading ?   <Spinner animation="border" variant="light" /> : "Log In" }
              </button> }
              
            </form>
            </div>
          </div>
          
        </div>
        
      </div>
    </div>
  </div>

</>

)



}

const mapStateToProps = (state) => {
  return { 
    individualUser: state.serviceSeeker.individualUser,
    loading: state.serviceSeeker.loading

  }
}

const mapDispatchToProps = (dispatch) => {
  return { 
    loginIndividualAction: (item,isLoading)=> dispatch(loginIndividual(item,isLoading)),

   }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceSeekerSignIn)
