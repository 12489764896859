import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation
} from "react-router-dom";
import List from "../list";
import UsecasesManagement from "../Useacases/UsecasesManagement";
import App from "../../App";
import Sidebar from "../Sidebar/sidebar";
import HowItWorks from "../HowItWorks/HowItWorks";
import PostWork from "../PostWork/PostWork";
import Serviceprovider from "../ServiceProvider/ServiceProviderPackages";
import ServiceSeekerPackages from "../ServiceSeeker/ServiceSeekerPackages";
import ServiceSeekerSignup from "../ServiceSeeker/ServiceSeekerSignup";
import ServiceProviderSignup from "../ServiceProvider/ServiceProviderSignup";
import Contact from "../Contact/Contact";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import AboutUs from "../AboutUs/AboutUs";
import AboutPortal from "../AboutPortal/AboutPortal";
import Home from "../Home/Home";
import ServiceSeekerSignIn from "../ServiceSeeker/ServiceSeekerSignIn";
import ServiceSeekerProfile from "../ServiceSeeker/ServiceSeekerProfile";

import { ProtectedRoute } from "./../protected.route";
import ServiceProviderSignIn from "../ServiceProvider/ServiceProviderSignIn";
import ServiceProviderProfile from "../ServiceProvider/ServiceProviderProfile";
import PostWorkTypes from "../PostWork/CreatePostWork";
import CreatePostWork from "../PostWork/CreatePostWork";
import PostTypeOptions from "../PostWork/PostTypeOptions";
import ListPostWork from "../PostWork/ListPostWork";
import ResetPassword from "../ForgotPassword/ResetPassword";
import Terms from '../TermAndCondition/terms';

import ReactGA from 'react-ga';

const usePageViews = () => {
  let location = useLocation()
  React.useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [location])
}

export const Routers = () => {
  usePageViews();
  return (
    <>
      <Sidebar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/how-it-works" component={HowItWorks} />
        <Route path="/usecases" component={UsecasesManagement} />
        <Route path="/post-work" exact={true} component={ListPostWork} />
        <Route
          path="/post-work/create"
          exact={true}
          component={PostTypeOptions}
        />
        <Route
          path="/post-work/create/audit-assurance"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/accounting"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/income-tax"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/sales-service-tax"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/corporate-secretarial"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/cost-Accounting-and-audit"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/transaction-advisory"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/legal-advisory"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/accounting-advisory"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/internal-audit"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/business-process-outsourcing"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/Public-sector-NPO-advisory"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/IT-IS-audits"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/fixed-assets-assignments"
          component={CreatePostWork}
        />
        <Route
          path="/post-work/create/actuarial"
          component={CreatePostWork}
        />
        <Route path="/post-work/create/others" component={CreatePostWork} />
        <Route
          path="/service-provider-packages"
          component={Serviceprovider}
        />
        <Route
          path="/service-seeker-packages"
          component={ServiceSeekerPackages}
        />
        <Route
          path="/service-seeker-signup"
          component={ServiceSeekerSignup}
        />
        <Route
          path="/service-provider-signup"
          component={ServiceProviderSignup}
        />
        <Route
          path="/service-seeker-signin"
          component={ServiceSeekerSignIn}
        />
        <Route
          path="/service-provider-signin"
          component={ServiceProviderSignIn}
        />

        {/* <Route path="/service-seeker-profile" component={ServiceSeekerProfile}/> */}
        <ProtectedRoute
          path="/service-seeker-profile"
          component={ServiceSeekerProfile}
        />
        <ProtectedRoute
          path="/service-provider-profile"
          component={ServiceProviderProfile}
        />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/contact" component={Contact} />
        <Route path="/About-us" component={AboutUs} />
        <Route path="/About-portal" component={AboutPortal} />
        <Route path="/Terms-and-Conditions" component={Terms} />
      </Switch>
    </>
  );
};
