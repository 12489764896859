import {
  CREATE_SERVICE_PROVIDER,
  SERVICE_PROVIDER_LOGIN,
  GET_SERVICE_PROVIDER,
  LOADING_STATE,
  GET_REVIEWS_SERVICE_PROVIDERS,
} from "../Constants/constants";

const initialState = {
  items: [],
  creationData: {},
  user: {},
  loggedIn: {},
  reviews: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CREATE_SERVICE_PROVIDER:
      return {
        ...state,
        creationData: action.payload,
      };

    case SERVICE_PROVIDER_LOGIN:
      return {
        ...state,
        loggedIn: action.payload,
      };

    case GET_SERVICE_PROVIDER:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };

    case LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      };

    case GET_REVIEWS_SERVICE_PROVIDERS:
      return {
        ...state,
        reviews: action.payload,
      };

    default:
      return state;
  }
}
