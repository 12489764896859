import React from "react";

import { Link } from "react-router-dom";

import PostWork from "./PostWork";
import A1 from '../../images/icons/Accounting advisory.png';
import A2 from '../../images/icons/Income tax.png';
import A3 from '../../images/icons/Accounting.png';
import A4 from '../../images/icons/audit.png';
import A5 from '../../images/icons/Corporate.png';
import A6 from '../../images/icons/Cost accounting.png';
import A7 from '../../images/icons/Legal advisory.png';
import A8 from '../../images/icons/Internal audit.png';
import A9 from '../../images/icons/Business process outsourcing.png';
import A10 from '../../images/icons/Public sector.png';
import A11 from '../../images/icons/IS IT audit.png';
import A12 from '../../images/icons/fixed-asset.png';
import A13 from '../../images/icons/Actuarial.png';
import A14 from '../../images/icons/Others.png';
import MetaTags from 'react-meta-tags';




export const AllPostTypes = () => {
  return (
    <section className="overview-block-ptb grey-bg iq-specialities">
    <MetaTags>
            <title>Post Work | Apex-Way</title>
            {/* <meta name="description" content="Some description." /> */}
          </MetaTags>
    <div className="container">
        {/* <div className="row">
            <div className="col-sm-12">
                <div className="heading-title">
                    <h3 className="title iq-tw-7">Sofbox Specialities </h3>
                    <p>Sofbox is popular for the below specialities that can make your website look beautiful
                    </p>
                </div>
            </div>
        </div> */}
        <div className="row">
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/audit-assurance" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A4} alt="icon" />
                        </div>
                        <h5 className="iq-tw-7 iq-pt-20 iq-pb-10">AUDIT AND ASSURANCE</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/accounting" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A3} alt="icon" />
                </div>
                        <h5 className="iq-tw-7 iq-pt-20 iq-pb-10">ACCOUNTING</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/income-tax" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A2} alt="icon" />
                </div>
                        <h5 className="iq-tw-7 iq-pt-20 iq-pb-10">INCOME TAX</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/sales-service-tax" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A2} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">Sales AND Service Tax</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/corporate-secretarial" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A5} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">corporate secretarial</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link
                        to="/post-work/create/cost-Accounting-and-audit"
                        class="swipebox"
                    >
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A6} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">cost Accounting and audit</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link
                 to="/post-work/create/transaction-advisory"
                 class="swipebox"
              >
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A1} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">Transaction Advisory</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/legal-advisory" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A7} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">Legal Advisory</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/accounting-advisory" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A1} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">Accounting Advisory (IFRS etc.)</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/internal-audit" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A8} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">Internal Audit</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link
                        to="/post-work/create/business-process-outsourcing"
                        class="swipebox"
                    >
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A9} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">Business Process Outsourcing</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link
                    to="/post-work/create/Public-sector-NPO-advisory"
                    class="swipebox"
                >
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A10} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">Public Sector/NPO Advisory</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/IT-IS-audits" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A11} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">IT/IS Audits</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link
                    to="/post-work/create/fixed-assets-assignments"
                    class="swipebox"
                >
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A12} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">Fixed Assets Assignments</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/actuarial" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    <img className="posticons" src={A13} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">Actuarial</h5>
                        {/* <p>how r u</p> */}
                    </div>
                    </Link>
                </div>
                <div className="col-sm-12 col-lg-3 col-md-6 iq-mb-30">
                <Link to="/post-work/create/others" class="swipebox">
                    <div className="iq-fancy-box iq-works-box text-center">
                    <div className="iq-icon">
                    {/* <i className="fa fa-cogs" aria-hidden="true" /> */}
                    <img className="posticons" src={A14} alt="icon" />
                </div>
                        <h5 className="text-uppercase iq-tw-7 iq-pt-20 iq-pb-10">Others</h5>
                    </div>
                    </Link>
                </div>
        </div>
    </div>
</section>
  );
};

const PostTypeOptions = () => {
  return PostWork(AllPostTypes);
};

export default PostTypeOptions;
