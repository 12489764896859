import {
  INDIVIDUAL_LOGIN,
  LOADING_STATE,
  GET_SERVICE_SEEKER,
  UPDATE_SERVICE_SEEKER,
  GET_SERVICE_PROVIDER_FOR_SEEKER,
  GET_REVIEWS,
  GET_REVIEWS_ALL,
} from "../../Constants/constants";
import axios from "axios";
import { toast } from "react-toastify";
import { authCheck } from "./../../components/auth";

export const loginIndividual = (data, loading) => (dispatch) => {
  dispatch({
    type: LOADING_STATE,
    payload: loading,
  });

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/service-seeker/login`,
      data
    )
    .then(function (response) {
      console.log(response);
      toast.success("LOGGED IN SUCCESFULLY", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: INDIVIDUAL_LOGIN,
        payload: response,
      });

      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });

      localStorage.setItem("user", JSON.stringify(response));

      if (authCheck()) {
        window.location.replace("/service-seeker-profile");
      }
    })
    .catch(function (err) {
      console.log("error");
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
    });
};

export const getServiceSeeker = () => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;

  let userType = JSON.parse(localStorage.getItem("user")).data.userType;

  const requestsBasedOnUserType = {
    individual: `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/individual/get`,
    company: `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/company/get`,
  };

  const dataResponse = axios
    .get(requestsBasedOnUserType[userType], {
      params: {
        id: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      console.log("response get", response);
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      dispatch({
        type: GET_SERVICE_SEEKER,
        payload: response.data.data,
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      console.log("error arrived", err);
    });
};

export const updateServiceSeeker = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;
  const userType = JSON.parse(adminToken)?.data?.userType;

  if (userType === "individual" || userType === "company") {
    const dataResponse = axios
      .post(
        `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/${userType}/update`,
        data,
        {
          params: {
            id: id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (response) {
        console.log("response", response);
        toast.success(response?.data?.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        dispatch({
          type: GET_SERVICE_SEEKER,
          payload: data,
        });
      })
      .catch(function (err) {
        toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        if (
          err?.response?.data?.error &&
          (err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("token is not valid") ||
            err?.response?.data?.message
              ?.toLowerCase()
              ?.includes("token is not valid") ||
            err?.response?.data?.msg
              ?.toLowerCase()
              ?.includes("you are not authorized to perform this action") ||
            err?.response?.data?.message
              ?.toLowerCase()
              ?.includes("you are not authorized to perform this action"))
        ) {
          localStorage.clear();
          window.location.href = "/";
        }
        console.log("error arrived", err);
      });
  }
};

export const getServiceProviders = () => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;

  let userType = JSON.parse(localStorage.getItem("user")).data.userType;

  const dataResponse = axios
    .get(`${process.env.REACT_APP_HOST_URL}/api/v1/apexway/service-seeker/sp`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      console.log("response get", response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: GET_SERVICE_PROVIDER_FOR_SEEKER,
        payload: response.data.data,
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      console.log("error arrived", err);
    });
};

export const getReviews = (id) => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  // const id = JSON.parse(adminToken)?.data?.id;

  let userType = JSON.parse(localStorage.getItem("user")).data.userType;

  const dataResponse = axios
    .get(`${process.env.REACT_APP_HOST_URL}/api/v1/apexway/review/providers`, {
      params: {
        serviceProvider: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      console.log("response get", response);
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      dispatch({
        type: GET_REVIEWS,
        payload: response.data.data,
      });
    })
    .catch(function (err) {
      // toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      dispatch({
        type: GET_REVIEWS,
        payload: [],
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      console.log("error arrived", err);
    });
};

export const getReviewsAll = () => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;

  let userType = JSON.parse(localStorage.getItem("user")).data.userType;

  const dataResponse = axios
    .get(`${process.env.REACT_APP_HOST_URL}/api/v1/apexway/review/users`, {
      params: {
        userId: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      console.log("review all", response);
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      dispatch({
        type: GET_REVIEWS_ALL,
        payload: response.data.data,
      });
    })
    .catch(function (err) {
      // toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      dispatch({
        type: GET_REVIEWS_ALL,
        payload: [],
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      console.log("error arrived", err);
    });
};
