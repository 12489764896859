import React,{Component,useEffect} from 'react';
import {Nav,Navbar,FormControl,Button,Form} from 'react-bootstrap'

import { NavLink, withRouter } from "react-router-dom";
import HeaderApp from '../Header/Header';
import FooterApp from '../Footer/Footer';


const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

return(

  <div>  

<div>
<HeaderApp/>
</div>

    <div>
        <div ids="iq-home" className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                    Terms and Conditions <br></br>
                    </h1>
                </div>
            </div>
        </div>
    </div>

<section class="features py-5">
    <div className="container">
        <div>
            <h4 className="heading-h4">Terms of Service</h4>
            <br></br>
            <p>Welcome to Apexway Portal (<a href="www.apex-way.com">www.apex-way.com</a> “the Site"). The following terms and conditions (the "Terms of Service" or “Agreement”) form a binding agreement between you and us. The terms "Apexway", "us", "we" or "our" refers to IMI APEXWAY SOLUTIONS (SMC-PRIVATE) LIMITED who, in conjunction with its affiliated companies, offers the Services (as defined below). The term "you" or "your" refers to the person accessing or using the Site and Services, or the company or organization on whose behalf that person accesses the Site and Services.
            </p>
            <br></br>
            </div>
        <div>
            <h4 className="heading-h4">Our Services</h4>
            <br></br>
            <p>
            We provide an online platform for connecting the Service Seekers with Service Providers through our Site at <a href="http://www.apex-way.com">http://www.apex-way.com</a> and any other related websites, applications or other distribution channels we may, from time to time, operate (collectively, the "Services"). You may use the Services for your personal and business use or for internal business purpose in the organization that you represent.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Acceptance of the Terms</h4>
            <br></br>
            <p>
            By accessing or using the Services, Terms of Service, whether you are a “Member” (which means that you have registered for an account) or a “Visitor” (which means that you are visiting the Site or any of our related websites, applications or the like). The term “User” refers to a Visitor or a Member. These Terms of Service along with any other guidelines we may post from time to time, such as our Privacy Policy (collectively, the “Guidelines”) will govern your use of the Services. If you do not agree to these Terms of Service or any of our Guidelines, do not use any of our Services.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Eligibility</h4>
            <br></br>
            <p>
            By using the Services, you represent and warrant that (a) you are fully able and competent to enter into the terms, conditions, obligations, representations and warranties set forth in these Terms of Service; (b) all registration information you submit is truthful and accurate; (c) you will maintain the accuracy of such information; (d) you are 18 years of age and older; and (e) your use of the Services does not violate any applicable law or regulation. Use of the Services is void where prohibited.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Your Account</h4>
            <br></br>
            <p>
            You can create an account with us by registering on the Site. You are solely responsible for maintaining the confidentiality of your password, and fully responsible for all activities that occur under your account. You agree not to use the account, username or password of another User at any time or to disclose your password to any third party or do anything else that might jeopardize the security of your account. You agree to notify us immediately of any unauthorized use of your account. If we have reasonable grounds to suspect violation of these terms or that registration information you have provided is untrue, inaccurate, outdated, or incomplete, we may terminate your User account and refuse current or future use of any or all of the Services. We are not responsible for any loss or damage to you or any third party that may be incurred as a result of any unauthorized access and/or use of your account, or otherwise
             </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Fees and Subscription Plans</h4>
            <br></br>
            <p>
            Our services are available on monthly and yearly subscription plans (“Paid Services”). When you subscribe for Paid Services, the seller of these services and the party you are contracting with is IMI APEXWAY SOLUTIONS (SMC-PRIVATE LIMITED. Your subscription is billed in advance and will be automatically renewed at the end of each subscription period unless you inform us that you do not wish to renew the subscription. Subscriptions are non-refundable after the first 30-Days. We reserve the right to change the subscription fee and to charge for use of Services that are currently available free of charge. You will not be charged for using any Service unless you have opted for a paid subscription plan. Information on the subscription options and charges for all paid Services is available at: <a href="http://www.apex-way.com">http://www.apex-way.com</a>. Unless otherwise stated, all fees are quoted in Pakistan Rupees.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Payments</h4>
            <br></br>
            <p>
            After submitting the form on our portal for registration you will receive an email from us. The email will have all necessary bank details for payment of subscription amount. You are responsible for paying all fees and applicable taxes associated with the Paid Services in a timely manner with a valid payment method. You also authorize us to collect and store your payment details, along with other related transaction information. You agree to submit any disputes regarding any charge to your account in writing to us within thirty (30) days of such charge, otherwise such dispute will be waived and such charge will be final and not subject to challenge.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">User Data Rights</h4>
            <br></br>
            <p>
            As a Member, you will have access to certain information explicitly submitted by other Users, such as contact information, submitted through our website (“User Submissions”). In order to provide our Services, you also allow us to collect, store and use additional data about the usage of our tools and information about your contacts that was not explicitly submitted by any User, such as IP address of a contact or statistics about the usage of the portal. 
            <br></br><br></br>
You give permission to Apexay to store copies of the data and documents you are required to share with us for verification and portal account creation. You acknowledge that we shall not be held responsible for any loss of whatever nature and type for the accuracy or loss of data that you share with us. 

            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Prohibited Activity</h4>
            <br></br>
            <p>
            You agree not to use the Services for illegal, harmful, misleading, fraudulent or other malicious purposes or to post, disseminate or communicate any unlawful, defamatory, obscene, lewd, excessively violent, harassing, sexually explicit or otherwise objectionable subject matter or for the transmission of material that contains viruses or other malicious code, or that which infringes or may infringe intellectual property or other rights of another. You agree not to use the Services for the transmission of "junk mail", "spam", "chain letters", “phishing” or unsolicited mass distribution of email. 
            <br></br><br></br>
            Without assuming any obligation to do so, we may delete or suspend any account or assignment associated with it, that we have reasonable grounds to believe violates this Agreement or that may be offensive or illegal, or violate the rights, harm, or threaten the safety of any person.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Inactive User Accounts Policy</h4>
            <br></br>
            <p>
            We reserve the right to disable or deactivate unpaid User accounts that are inactive for more than 90 days. In the event of such termination, all data associated with such User account may be deleted. We will provide you prior notice of such termination by email. In the future, we may impose different usage restrictions than currently offered.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Service Communications</h4>
            <br></br>
            <p>
            By providing Apexway your email address, phone number or any other personal information as a Member, you consent to our using the email address or phone number to send you Service-related notices, including any notices required by law, in lieu of communication by postal mail. We may also use your email address or phone number to send you other messages, such as updates, user communications, newsletters, changes to features of the Service, or Apexway offers. If you do not want to receive certain email messages and communications, you may opt out by contacting our support team. Opting out may prevent you from receiving valuable messages regarding updates, improvements, offers, or communications from other Users. Apexway reserves the right to send you notices about your account even if you opt out of all voluntary email and phone notifications.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Privacy Protection</h4>
            <br></br>
            <p>
            We care about your privacy. Your information may be stored and processed in any country in which Apexway and its service providers maintain facilities, including outside of the Pakistan. In this regard, or for purposes of sharing or disclosing data in accordance with this Agreement, Apexway reserves the right to transfer information outside of your country. By using the Services, you consent to any such transfer of information outside of your country.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Service Provider and Service Seeker Interactions</h4>
            <br></br>
            <p>
            You release Apexway, its officers, employees, agents and successors from claims, demands and damages of every kind or nature arising out of or related to any disputes between service providers and service seekers. Apexway expressly disclaims responsibility for the accuracy, quality, legality, nature, availability or reliability of Sites linked to by or through the Site or Services
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Copyright Protection</h4>
            <br></br>
            <p>
            Apexway trademarks, logos, service marks, images, trade names, designs, page headers, button icons, scripts and other distinctive branding features used in connection with the Services are the trademarks or service marks of Apexway and may not be copied, imitated, or used, in whole or in part, without the prior written permission of Apexway.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Disclaimer of Warranties</h4>
            <br></br>
            <p>
            Apexway does not warrant that (i) the Services will meet your specific requirements, (ii) the Services will be uninterrupted, timely, secure, or error-free, (iii) the results that may be obtained from the use of the Services will be accurate or reliable, (iv) the quality of any products, services, information, or other material purchased or obtained by you through the Services from service providers will meet your expectations, and (v) any errors in the Services will be corrected.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Limitation of Liability</h4>
            <br></br>
            <p>
            You expressly understand and agree that IMI APEXWAY SOLUTIONS (SMC-PRIVATE) LIMITED, its subsidiaries, and affiliates, and their respective officers, directors, agents, co-branders or other partners, and employees (“Apexway”) shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses resulting from: (i) the use or the inability to use the Services; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any member or third party on the Services; (v) or any other matter relating to the Services. Your sole and exclusive remedy for any dispute with Apexway related to any of the Services shall be termination of such Service. In no event shall Apexway’s entire liability to you in respect of any Service, whether direct or indirect, exceed the fees paid by you towards such Service in the previous three (03) months.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Indemnification</h4>
            <br></br>
            <p>You agree to indemnify, defend, and hold harmless Apexway and its third party service providers from and against any claim, demand, loss, damage, cost, or liability (including reasonable attorneys' fees) arising out of or relating to:
            </p>
            <ul>
                <li>
                • &nbsp;&nbsp;Any Content you submit, post, transmit, or make available through the Site or Services;
                </li>
                <li>
                • &nbsp;&nbsp;Your use or misuse of the Services;
                </li>
                <li>
                •&nbsp;&nbsp;Your connection to the Services;
                </li>
                <li>
                •&nbsp;&nbsp;Your breach or alleged breach of this Agreement; or
                </li>
                <li>
                •&nbsp;&nbsp;Your violation of any rights (including intellectual property rights) of a third party
                </li>
            </ul>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Changes to Services</h4>
            <br></br>
            <p>
            We reserve the right at any time (and from time to time) to modify, suspend, or discontinue providing the Services or any part thereof with or without notice. We will not be liable to you or to any third party for any modification, suspension or discontinuance of the Services.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Amendments</h4>
            <br></br>
            <p>
            We may amend, modify, change, add or remove portions of this Agreement or any Guidelines at any time without notice to you by posting a revised version on www.apex-way.com or elsewhere on the Site. The revised version will be effective at the time we post it. Your continued use of the Services after posting of the changes constitutes your binding acceptance of such changes.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Term and Termination</h4>
            <br></br>
            <p>
            You may terminate your account and end your use of the Services at any time and for any or no reason. Apexway has the right (at its sole discretion) for any reason to (i) delete, disable or deactivate your account, block your email or IP address, or otherwise terminate your access to or use of the Services, (ii) remove and discard any Content within any account or anywhere on the Site or (iii) shut down an account, with or without notice, and with no liability of any kind to you.
            </p>
            <p>
            If you terminate your account, we will have no obligation to refund you any fees you may have paid except as may be required by applicable law. Upon deactivating your account, this Agreement terminates and your access rights to the Site and any Services immediately cease to exist. 
             </p>
            <p>
            This Agreement shall be governed by the laws of Pakistan without giving effect to any principles that may provide the application of the law of another jurisdiction. Any controversy or claim arising out of or relating to this Agreement shall be settled in accordance with the laws of Pakistan
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">30-Day Money Back Policy</h4>
            <br></br>
            <p>
            At Apexway, we are so confident in our platform that we offer a 30-day, money back guarantee. No questions asked! If you are not fully satisfied with our platform, simply request a refund within the first 30 days of purchasing your new subscription. We'll refund you 100% of your subscription fees. No kidding.
            </p>
            <p>
            This guarantee applies to new Apexway subscriptions only and not for existing subscriptions that have been purchased more than 30 days ago. Refunds will be promptly issued within 4-5 working days after we receive your request. This policy shall also be applicable for subscribers of Limited Package provided they notify us for refund within 15 days from activation of the account.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Refund Procedure</h4>
            <br></br>
            <p>
            To request a refund under this policy, you must contact us within the first 30 (15 days for Limited Package) calendar days of your Apexway account activation. Just send an email to us with subject heading "Billing 30 days – Request Refund". We'll gladly refund you 100% of your subscription fees within 4-5 working days of your refund request. 
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Refund Policy After 30 Days</h4>
            <br></br>
            <p>
            To cancel your account after 30-days of your purchase of a new Apexway subscription, please send an email to with subject "Billing - Request Refund". Within 4-5 working days of your request, you will receive a pro-rated refund for the remainder of your subscription period (Apexway Premium and Extended Annual Subscriptions only; refunds will not be available for monthly accounts).
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">General</h4>
            <br></br>
            <p>
            The failure of Apexway to exercise or enforce any right or provision of this Agreement does not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should try to give effect to the parties' intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect. Apexway may assign this Agreement in whole or in part in its sole discretion without your consent and without notice. This Agreement constitutes the entire agreement between you and Apexway and governs your use of the Services, superseding any prior agreements (whether written or oral) between you and Apexway regarding the subject matter hereof. Nothing in this Agreement shall prevent us from complying with the law and applicable regulations.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Privacy Policy</h4>
            <br></br>
            <p>
            We provide an online platform for connecting the Service Seekers with Service Providers through our Site at <a href="http://www.apex-way.com">http://www.apex-way.com</a> and any other related websites, applications or other distribution channels we may, from time to time, operate (collectively, the "Services"). Your privacy and protection of information you share with us while using our Services is very important to us.
            </p>
            <p>
            This "Privacy Policy" explains what information we gather from our users and how we use it. By using or accessing our Services, you are accepting the practices described in this Privacy Policy. (Capitalized terms not defined herein have the meaning set forth in our Terms of Service). 
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">What information does Apexway gather?</h4>
            <br></br>
            <p>
            Apexway gathers and stores three types of information about users that are subject to our Privacy Policy:
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Information users provide to us:</h4>
            <br></br>
            <p>
            These are voluntary submissions made when creating an account on Apexway or through your use of the Services, such as name and email address provided during registration and form submissions, or payment information provided during purchases. Please understand that when you sign into Apexway or post an assignment, your information is not anonymous to us.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Information we collect when users interact with Apexway:</h4>
            <br></br>
            <p>
            We keep track of user interactions with our Services, such as visiting a web page, clicking a button, and choosing a form. Also, when users access our Services, we may collect information about their access method (such as hardware and software attributes), location, IP address, and pages they visit. In addition, we store certain information from their browser using “cookies”. (For more on cookies, please see the section "What are cookies?")
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Information we receive from third parties:</h4>
            <br></br>
            <p>
            We do not own or operate any third-party services offered that you may use or interact with through our Services (collectively, “Third Party Services”). Whenever you visit, use or interact with a Third Party Service, we may receive information from them, including information about actions you take. 
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Why does Apexway gather information about me?</h4>
            <br></br>
            <p>
            Apexway collects information in order to improve the content and quality of our Services. Any personally identifiable information we collect (including email addresses, physical addresses or phone numbers) is not shared with or sold to other organizations for commercial purposes, except to provide products or services you've requested, when we have your permission, or under special circumstances outlined in this Privacy Policy.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">What are cookies?</h4>
            <br></br>
            <p>
            Apexway may place a text file called a "cookie" in the browser files of your computer. These cookies help us make Apexway easier to use, to make our advertising better, and to protect both you and Apexway. For example, a cookie may be used to store or "remember" your Member login information (but not your password) so that you are not required to manually log into the site at every visit. You can remove or block cookies using the settings in your browser, but in some cases that may impact your ability to use some of our Services.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">How secure is my information?</h4>
            <br></br>
            <p>
            Apexway uses commercially reasonable physical, electronic, and procedural measures to safeguard any information in our possession against loss, theft and unauthorized use, disclosure or modification. We limit access to personal information about you to employees whom we believe reasonably need that information to provide support, products, or services to you or to fulfill their roles within our organization.
            </p>
            <p>
            Although we have established and maintain security procedures to protect your personally identifiable information, please be aware that no security measures are perfect or impenetrable. You can reduce the risk of unauthorized access to your information by using common sense security practices such as choosing a strong password, using different passwords for different services, and using up-to-date antivirus software.
            </p>
            <p>
            Please report any security violations to us on this automated form.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">What control do I have over my information?</h4>
            <br></br>
            <p>
            As a registered Member, you may modify or update your personal account information at any time by contacting our support team. Should you desire to do so, you may also delete and close your Apexway account at any time by contacting our support team. Note that removed or deleted information may persist in backup copies for a reasonable period of time.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">How is information shared?</h4>
            <br></br>
            <p>
            Apexway will not share your personally identifiable information with others except as described herein and in limited circumstances where we believe such sharing is reasonably necessary to offer the Services, legally required, or permitted by you. For example, we may provide information to Service Providers to help us bring you the services we offer. 
            </p>
            <p>
            Where we utilize third parties for the processing of any personal information, we implement reasonable contractual and technical protections limiting the use of that information to the Apexway-specified purposes. We may store personal information in locations outside the direct control of Apexway (for instance, on servers or databases co-located with hosting providers).
            </p>
            <p>
            Except as otherwise described in this Privacy Policy, we will not disclose personal information to any third party unless we believe that disclosure is necessary: (1) to conform to legal requirements or to respond to a subpoena, search warrant or other legal process received by Apexway, whether or not a response is required by applicable law; (2) to enforce the Apexway Terms of Service or to protect our rights; or (3) to protect the safety or rights of members of the public or users of the Services. Apexway reserves the right to transfer personal information to a successor in interest that acquires rights to that information as a result of the sale of Apexway or substantially all of its assets to that successor in interest. We may also transfer such information in the course of corporate divestitures, mergers, or dissolution.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">How am I notified of changes to this privacy policy?</h4>
            <br></br>
            <p>
            We may change this Privacy Policy pursuant to the procedures outlined in our Terms of Service. Unless stated otherwise, our current Privacy Policy applies to all information we have about you and your account. If we make changes to this Privacy Policy we will notify you by publication here.
            </p>
            <br></br>
        </div>
        <div>
            <h4 className="heading-h4">Who can I contact about this privacy policy?</h4>
            <br></br>
            <p>
            To submit an inquiry about our Privacy Policy, please contact our support team. 
            </p>
            <br></br>
        </div>
    </div>
</section>

<FooterApp/>

</div>

)



}

export default Terms

