import react,{Component} from "react";

export default class List extends Component {


    constructor(props){
        super(props);
        this.state = {
            listData :null,
            newData: null
        }
    }


    addData(){
     const {listData,newData} = this.state;
        this.setState({
            listData: null,
            newData: null
        })
    }

    deleteData(item){

        const {listData,newData} = this.state;
        const id = item?.id;

        const updatedList = listData.filter(item => item.id !== id)
        
        this.setState({listData : updatedList})

       }


    setAddValue(e){
        const newDataSet = {
            name: e.target.value,
            id: Math.random()
        }
        this.setState({
            newData : newDataSet
        })
    }

    render(){

        const {listData,newData} = this.state;

        return(
            <div>
            <h3> List Data </h3>
            {listData?.map((item,index) => {
                return(
                    <div style={{display: 'flex',flexDirection: 'column',justifyContent: "center",alignItems: "center"}}>
                    <h5 key={index}> {item.name} </h5>
                    <button  onClick={() => this.deleteData(item)}> delete </button>
                    </div>
                )
            })}
            <button onClick={() => this.addData()}> add item </button>

            <input type="text" value={newData!== null ? newData.name : "" } onChange={(e) => this.setAddValue(e)} />

             </div>
        )
    }
} 
