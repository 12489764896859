import {
  CREATE_POST_WORK,
  DELETE_POST_WORK,
  SELECT_POST,
  UNSELECT_POST,
  COMPLETE_POST_WORK,
  DISCONTINUE_POST_WORK,
  GET_POST_WORK,
  LOADING_STATE,
  UPDATE_POST_WORK,
  CREATE_REVIEW,
} from "../../Constants/constants";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils";

export const createPostWork = (data, loading) => (dispatch) => {
  dispatch({
    type: LOADING_STATE,
    payload: loading,
  });

  const dataResponse = axios
    .post(`${process.env.REACT_APP_HOST_URL}/api/v1/apexway/post/create`, data)
    .then(function (response) {
      // console.log(response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });

      dispatch({
        type: CREATE_POST_WORK,
        payload: response.data,
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });

      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }

      // console.log("error arrived", err);
    });
};

export const getPostWork = () => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;

  const dataResponse = axios
    .get(`${process.env.REACT_APP_HOST_URL}/api/v1/apexway/post/by-user`, {
      params: {
        userId: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      // console.log("response", response);
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      dispatch({
        type: GET_POST_WORK,
        payload: response.data.data,
      });
    })
    .catch(function (err) {
      // toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }

      // console.log("error arrived", err);
    });
};

export const createPostReview = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;

  data["userId"] = id;

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/review/create`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // console.log("response", response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: CREATE_REVIEW,
        payload: response?.data?.data,
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      // console.log("error arrived", err);
    });
};

export const deletePostWork = (data) => (dispatch) => {
  dispatch({
    type: SELECT_POST,
    payload: data.reference_id,
  });
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;
  data["userId"] = id;
  data["posts"] = [data._id];

  const dataResponse = axios
    .delete(`${process.env.REACT_APP_HOST_URL}/api/v1/apexway/post`, {
      data,
      params: {
        id: id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      // console.log("response", response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: DELETE_POST_WORK,
        payload: { _id: data._id },
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      dispatch({
        type: UNSELECT_POST,
        payload: data.reference_id,
      });
    });
};

export const completePostWork = (data) => (dispatch) => {
  dispatch({
    type: SELECT_POST,
    payload: data.reference_id,
  });
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;
  // console.log("token in delete ==== ", token);
  // data["userId"] = id;
  // data["posts"] = [data._id];

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/post/update`,
      { completed: true },
      {
        params: {
          id: data._id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // console.log("response", response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: COMPLETE_POST_WORK,
        payload: { ...data, ...response.data.data },
      });
      dispatch({
        type: UNSELECT_POST,
        payload: data.reference_id,
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      // console.log("error arrived", err);
      dispatch({
        type: UNSELECT_POST,
        payload: data.reference_id,
      });
    });
};

export const discontinuePostWork = (data) => (dispatch) => {
  dispatch({
    type: SELECT_POST,
    payload: data.reference_id,
  });
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;
  // data["userId"] = id;
  // data["posts"] = [data._id];

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/post/update`,
      { discontinued: true },
      {
        params: {
          id: data._id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // console.log("response", response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: DISCONTINUE_POST_WORK,
        payload: { ...data, ...response.data.data },
      });
      dispatch({
        type: UNSELECT_POST,
        payload: data.reference_id,
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      // console.log("error arrived", err);
      dispatch({
        type: UNSELECT_POST,
        payload: data.reference_id,
      });
    });
};

export const getSpecializedPostWorkAction = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/post/by-specialization`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // console.log("response", response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: GET_POST_WORK,
        payload: response.data.data,
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      // console.log("error arrived", err);
    });
};

export const updatePostWork = (data) => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const id = JSON.parse(adminToken)?.data?.id;

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/post/update`,
      data,
      {
        params: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(function (response) {
      // console.log("response", response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: UPDATE_POST_WORK,
        payload: response.data.data,
      });
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }

      // console.log("error arrived", err);
    });
};
