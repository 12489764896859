import React, { Component, useState, useEffect } from "react";
import { Nav, Navbar, FormControl, Accordion, OverlayTrigger, InputGroup, Form } from "react-bootstrap";
import { UncontrolledCollapse, Button, CardBody, Card, Collapse, FormGroup, Label, Input, FormFeedback, FormText, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link, NavLink, withRouter } from "react-router-dom";
import FooterApp from "../Footer/Footer";
import HeaderApp from "../Header/Header";
// import {createServiceProvider} from "../../Actions/ServiceProviderAction";
import { createServiceProvider } from "../../Actions/serviceProvider/ServiceProvider";
import { uniqueEmail, uniqueName, uniqueCnic } from "../../Actions/global/global";
import visbilty from '../../images/visibility.svg';
import invisible from '../../images/invisible.svg';
import {
  SITE_KEY,
} from "../../Constants/constants";
import Recaptcha from 'react-recaptcha';
import MetaTags from 'react-meta-tags';

import { Spinner } from "react-bootstrap";

import "react-datepicker/dist/react-datepicker.css";

import { connect } from "react-redux";
import MultiSelect from "react-multi-select-component";

const ServiceProvider = (props) => {
  const [name, setName] = useState("");
  const [cnic, setCnic] = useState("");
  const [gender, setGender] = useState("");
  const [member, setMember] = useState("");
  const [emailOther, setemailOther] = useState("");
  const [sbpPanel, setsbpPanel] = useState("");
  const [ntnStrn, setNtnStrn] = useState("");
  const [totalStaff, settotalStaff] = useState("");
  // const [memberSince,setMemberSince] = useState("");
  const [membershipNo, setMembershipNo] = useState("");
  const [registrationNumber, setregistrationNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [serviceSeekerType, setServiceSeekerType] = useState("Individual(M)");
  const [organizationIncorp, setOrganizationIncorp] = useState("");
  const [verified, setverified] = useState(false);
  const [organizationAddressOne, setOrganizationAddressOne] = useState("");
  const [organizationAddressTwo, setOrganizationAddressTwo] = useState("");
  const [organizationContactOne, setOrganizationContactOne] = useState("");
  const [organizationContactTwo, setOrganizationContactTwo] = useState("");
  const [ContactOne, setContactOne] = useState("");
  const [uniqueCnicState, setuniqueCnicState] = useState("");
  const [ContactTwo, setContactTwo] = useState("");
  const [designation, setDesignation] = useState("");
  const [serviceSeekerOfficialEmail, setServiceSeekerOfficialEmail] =
    useState("");
  const [serviceSeekerPersonalEmail, setServiceSeekerPersonalEmail] =
    useState("");
  const [serviceSeekerPermanentAddress, setServiceSeekerPermanentAddress] =
    useState("");
  const [communicationAddress, setCommunicationAddres] = useState("");
  const [regulatoryBody, setregulatoryBody] = useState("ICAP");
  const [regWith, setregWith] = useState("");
  const [noOfOffice, setNoOfOffices] = useState("");
  const [qualityControlRating, setQualityControlRating] = useState("No");
  const [qualityControlRatingCondition, setQualityControlRatingCondition] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [locationOther, setLocationOther] = useState("");
  const [membershipsOther, setMembershipsOther] = useState("");

  const [incorporationNumber, setIncorporationNumber] = useState("");
  const [contacts, setContacts] = useState("");
  const [password, setPassword] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [others, setothers] = useState("");

  const [collapse, setCollapse] = useState(true);
  const toggle = () => setCollapse(!collapse);
  const [collapsee, setCollapsee] = useState(false);
  const togglee = () => setCollapsee(!collapsee);
  const [checked, setChecked] = useState(false);
  const handleClick = () => setChecked(!checked);

  const [area_of_specialization, setArea_of_specialization] = useState([]);
  const [Location, setLocation] = useState([]);
  const [passwordShown, setPasswordShown] = useState(false);
  const [imageIcon, setimageIcon] = useState(visbilty);
  const [modal2, setModal2] = useState(false);
  const modalTogglee = () => setModal2(!modal2);
  const [modal3, setModal3] = useState(false);
  const modalToggleee = () => setModal3(!modal3);


  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setimageIcon(passwordShown ? visbilty : invisible)
  };

  const options2 = [
    { label: "Karachi", value: "Karachi" },
    { label: "Lahore", value: "Lahore" },
    { label: "Islamabad", value: "Islamabad" },
  ];

  const options = [
    // { label: "Select Area Of Specialization", value: "select" },
    { label: "Sales/Service Tax Services", value: "sales-service-tax" },
    { label: "Income Tax Services", value: "income-tax" },
    { label: "Accounting Services", value: "accounting" },
    { label: "Audit & Assurance Services", value: "audit-assurance" },
    {
      label: "Fixed Assets Assignments & Related Services",
      value: "fixed-assets-assignments",
    },
    {
      label: "Business Process Outsourcing",
      value: "business-process-outsourcing",
    },
    {
      label: "Public Sector/NPO/Donor Audits & Advisory Services",
      value: "Public-sector-NPO-advisory",
    },
    { label: "Actuarial Services", value: "actuarial" },
    { label: "Legal Advisory Services", value: "legal-advisory" },
    { label: "Transaction Advisory Services", value: "transaction-advisory" },
    { label: "Information System/IT Audits", value: "IT-IS-audits" },
    {
      label: "Cost Accounting, Audit & Related Services",
      value: "cost-Accounting-and-audit",
    },
    { label: "Accounting Advisory", value: "accounting-advisory" },
    { label: "Internal Audit Services", value: "internal-audit" },
    { label: "Corporate Secretarial Services", value: "corporate-secretarial" },
    { label: "Other (Specify)", value: "others" },
  ];

  const [selected, setSelected] = useState([]);
  const [selected2, setSelected2] = useState([]);
  const [textchecking, settextchecking] = useState("");

  useEffect(() => {
    indexcheck()
  }, [selected]);


  function indexcheck() {
    let datavalue = ""
    datavalue = selected.find(o => o.value === 'others');
    // console.log(datavalue);
    if (datavalue != "" && datavalue != null) {
      settextchecking("others")
    }
    else {
      settextchecking("")
    }

  };




  function setAreaOfSpecialization(value) {
    if (area_of_specialization.includes(value)) {
      let filtered = area_of_specialization.filter((item) => item !== value);
      setArea_of_specialization(filtered);
    } else {
      setArea_of_specialization([...area_of_specialization, value]);
    }
  }

  function setLocationCity(value) {
    if (Location.includes(value)) {
      let filtered = Location.filter((item) => item !== value);
      setLocation(filtered);
    } else {
      setLocation([...Location, value]);
    }
  }

  function onChange(event) {
    const { value, name, id } = event.target;

    switch (id) {
      case "txt-serv-seek-promo-code":
        setPromoCode(value);
        break;

      case "dd-serv-seek-type":
        setServiceSeekerType(value);
        break;

      case "txt-serv-seek-org-area-of-specialization":
        if (value !== "select") {
          setAreaOfSpecialization(value);
        }
        break;

      case "txt-serv-password":
        setPassword(value);
        break;

      case "txt-serv-seek-name":
        setName(value);
        break;

      case "txt-serv-seek-cnic":
        setCnic(value);
        break;

      case "txt-serv-seek-others":
        setothers(value);
        break;


      case "dd-serv-seek-gender":
        setGender(value);
        break;

      case "dd-serv-seek-member":
        setMember(value);
        break;

      case "txt-serv-seek-memship-no":
        setMembershipNo(value);
        break;

      case "dd-serv-seek-location-other":
        if (value !== "select") {
          setLocationCity(value);
        }
        break;

      case "txt-serv-seek-contact1":
        setContactOne(value);
        break;

      case "txt-serv-seek-contact2":
        setContactTwo(value);
        break;

      case "txt-serv-seek-comm-addr":
        setCommunicationAddres(value);
        break;

      case "txt-serv-seek-perm-addr":
        setServiceSeekerPermanentAddress(value);
        break;

      case "txt-serv-seek-off-email":
        setServiceSeekerOfficialEmail(value);
        break;

      case "txt-serv-seek-pers-email":
        setServiceSeekerPersonalEmail(value);
        break;

      case "txt-serv-seek-affiliation":
        setAffiliation(value);
        break;

      case "dd-serv-designation":
        setDesignation(value);
        break;

      case "txt-serv-seek-org-website":
        setWebsite(value);
        break;

      case "txt-serv-seek-org-name":
        setOrganizationName(value);
        break;

      case "txt-ntn-strn":
        setNtnStrn(value);
        break;

      case "txt-serv-seek-org-email":
        setOrganizationEmail(value);
        break;

      case "txt-serv-seek-org-addr1":
        setOrganizationAddressOne(value);
        break;

      case "txt-serv-seek-org-addr2":
        setOrganizationAddressTwo(value);
        break;

      case "txt-serv-seek-org-contact1":
        setOrganizationContactOne(value);
        break;

      case "txt-serv-seek-org-contact2":
        setOrganizationContactTwo(value);
        break;

      case "dd-serv-memfield":
        setregulatoryBody(value);
        break;

      case "txt-serv-seek-regwith":
        setregWith(value);
        break;

      case "txt-serv-seek-registration-number":
        setregistrationNumber(value);
        break;

      case "dd-serv-quality-control-rating":
        setQualityControlRatingCondition(value);
        break;

      case "dd-serv-quality-control-rating-status":
        setQualityControlRating(value);
        break;

      case "txt-serv-seek-no-of-offices":
        setNoOfOffices(Number(value));
        break;

      case "txt-serv-seek-incorp-no":
        setIncorporationNumber(value);
        break;

      case "dd-serv-seek-totalStaff":
        settotalStaff(value);
        break;

      case "div-ss-offemail":
        setemailOther(value);
        break;

      case "txt-serv-seek-membership-other":
        setMembershipsOther(value);
        break;

      case "txt-serv-seek-contacts":
        setContacts(value);
        break;

      case "txt-serv-seek-sbpPanel":
        setsbpPanel(value);
        break;

      default:
      // console.log("value");
    }
  }

  function toggle3() {
    setCollapse(false);
    setCollapsee(true);
  };
  async function register(event) {
    event.preventDefault();
    if (verified) {
      const payload = {
        name,
        regWith,
        qualityControlRating,
        affiliation,
        noOfOffice,
        locationOther,
        cnic,
        gender,
        member,
        ntnStrn,
        membershipNo,
        registrationNumber,
        website,
        emailOther,
        organizationAddressOne,
        organizationAddressTwo,
        organizationContactOne,
        organizationContactTwo,
        organizationEmail,
        organizationIncorp,
        organizationName,
        communicationAddress,
        ContactOne,
        contacts,
        ContactTwo,
        sbpPanel,
        totalStaff,
        designation,
        serviceSeekerOfficialEmail,
        serviceSeekerPermanentAddress,
        serviceSeekerPersonalEmail,
        serviceSeekerType,
        regulatoryBody,
        incorporationNumber,
        membershipsOther,
        others,

      };

      let payloadData;
      let uniqueMail;
      let uniqueName;
      let uniqueCnic;

      let area_of_specialization_values = selected.map((item) => item.value);
      let loc_of_offices_pk_values = selected2.map((item) => item.value);

      payloadData = {
        other_specialization: others,
        name: name,
        incorporation_number: incorporationNumber,
        registered_with: regWith,
        regulatory_body: regulatoryBody,
        ntn_strn: ntnStrn,
        total_staff: totalStaff,
        contacts: contacts,
        sbp_panel: sbpPanel,
        registration_number: registrationNumber,
        quality_control_rating: qualityControlRating,
        member_dropdown: serviceSeekerType,
        affiliations: affiliation,
        address1: organizationAddressOne,
        address2: organizationAddressTwo,
        membership_details: membershipNo,
        contact_number1: ContactOne,
        memberships: membershipsOther,
        contact_number2: ContactTwo,
        // cnic: cnic,
        email_other: emailOther,
        username: name,
        password: password,
        no_of_offices_pk: noOfOffice,
        email: serviceSeekerPersonalEmail,
        designation: designation,
        organisation: organizationName,
        website: website,
        area_of_specialization: area_of_specialization_values,
        loc_of_offices_pk: loc_of_offices_pk_values,
        promoCode: promoCode,
      };
      uniqueMail = await props.checkUniqueEmail(serviceSeekerPersonalEmail);
      uniqueName = await props.checkUniqueName(name);
      // uniqueCnic = await props.checkUniqueCnic(cnic);
      // console.log(props.creationData,"checking");
      if (uniqueMail.success && uniqueName.success) {
        let checkdata;
        checkdata = await props.createServiceProvider(payloadData, true);
        if (checkdata?.success) {
          setModal2(!modal2);
          resetForm()
        }
      }
    }
    else {
      setModal3(true);
    }
  }


  function resetForm() {
    setPromoCode("");
    setServiceSeekerType("Individual(M)");
    setAreaOfSpecialization([]);
    setPassword("");
    setName("");
    setCnic("");
    setothers("");
    setGender("");
    setMember("");
    setMembershipNo("");
    setLocation("");
    setContactOne("");
    setContactTwo("");
    setCommunicationAddres("");
    setServiceSeekerPermanentAddress("");
    setServiceSeekerOfficialEmail("");
    setServiceSeekerPersonalEmail("");
    setAffiliation("");
    setDesignation("");
    setsbpPanel('');
    settotalStaff('');
    setWebsite("");
    setOrganizationName("");
    setNtnStrn("");
    setemailOther("");
    setOrganizationEmail("");
    setOrganizationAddressOne("");
    setOrganizationAddressTwo("");
    setOrganizationContactOne("");
    setOrganizationContactTwo("");
    setregulatoryBody("ICAP");
    setregWith("");
    setQualityControlRating("No");
    setregistrationNumber("");
    setQualityControlRating("");
    setNoOfOffices(Number("1"));
    setIncorporationNumber("");
    setMembershipsOther("");
    setContacts("");
    setSelected([]);
    setSelected2([]);
  }

  const callback2 = function () {
    setverified(true)
  };

  const onloadCallback = function () {
    console.log('loaded()');
  }

  return (
    <div>
      <MetaTags>
        <title>Register as a Service Provider | Apexway</title>
        <meta name="description" content="APEXWAY is Pakistan's state of art marketplace for accounting, taxation, finance, legal and commerce industry service providers to help win new clients requiring outsourcing services." />
        <meta name="robots" content="noodp,noydir" />
      </MetaTags>
      <div>
        <HeaderApp />
      </div>
      <div>
        <Modal size="lg" isOpen={modal2} toggle={modalTogglee} className="success-msg">
          {/* Successfully Registered */}
          <ModalHeader toggle={modalTogglee}></ModalHeader>
          <ModalBody className="success-msgs">
            Registration form has been successfully submitted. Thank you!<br></br><br></br>
            We are very pleased that you are interested in beginning your journey with us.<br></br><br></br>
            You will shortly receive an email from <a href="info@apex-way.com" target="_blank">info@apex-way.com</a> that will guide you to the registration process. If you do not receive an email, check your “junk mail/spam” folder. For technical assistance, please do not hesitate to write to us at <a href="info@apex-way.com" target="_blank">info@apex-way.com</a>. We shall be glad to get back to you.<br></br><br></br>
            <h3>The APEXWAY Team</h3><br></br>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal size="lg" isOpen={modal3} toggle={modalToggleee} className="success-msg">
          <ModalHeader toggle={modalToggleee}>Verification Failed !!!</ModalHeader>
          <ModalBody className="success-msgs">
            reCAPTCHA verification is required to proceed on this website<br></br>
            Click the checkbox to get a verification challenge
          </ModalBody>
        </Modal>
      </div>

      <div>
        <div ids="iq-home" className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}>
          <div className="container-fluid">
            <div className="col-lg-12">
              <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                SERVICE PROVIDER REGISTRATION<br></br>
                <span class="section_1-breadcrumbs"></span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <section class="features py-5">
        <div class="container">
          <div class="heading pb-4">
            {/* <h3 class="heading mb-2 text-center">
              <span>Service Provider </span>Registration
            </h3> */}
            <p class="para mb-5 text-center">
              Register Now and provide your desired Services{" "}
            </p>
          </div>

          <Form id="form-provider" onSubmit={register}>
            <div id="div-ss-signup-accordions">
              <div class="card" id="generalInfoAccord">
                <div class="card-header" style={{ backgroundColor: "#5B9BD5" }}>
                  <span onClick={toggle} className="sssheadtag text-light">Required Information</span>
                  {collapse === true ? <Button className="togglebtn" color="muted" onClick={toggle} ><i class="fa fa-minus" aria-hidden="true" onClick={toggle}></i></Button>
                    : <Button className="togglebtn" color="muted" onClick={toggle}><i class="fas fa-plus"></i></Button>}
                </div>
                <div
                  id="accordion1"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <Collapse
                    isOpen={collapse}
                  >
                    <div class="card-body">
                      <div class="row col-md-12">
                        <div class="col-md-6">
                          <div class="form-group" id="div-ss-name">
                            {/* <pre>{JSON.stringify(selected)}</pre> */}
                            <label>
                              Area Of Specialization{" "}
                              <span class="text-danger">*</span>
                            </label>
                            <MultiSelect
                              options={options}
                              value={selected}
                              required="true"
                              onChange={setSelected}
                              labelledBy="Select"
                            />
                          </div>
                          {textchecking === "others" ?
                            <div class="form-group" id="div-ss-name">
                              <label>
                                Please specify
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                value={others}
                                type="text"
                                maxlength="30"
                                class="form-control"
                                id="txt-serv-seek-others"
                                placeholder="Enter Text"
                                onChange={onChange}
                                required="true"
                              />
                            </div>
                            :
                            <div></div>
                          }


                          <div class="form-group" id="div-ss-name">
                            <label>
                              Name Of Service Provider / Firm
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              value={name}
                              type="text"
                              maxlength="50"
                              class="form-control"
                              id="txt-serv-seek-name"
                              placeholder="Enter Name"
                              onChange={onChange}
                              required="true"
                            />
                          </div>

                          <div class="form-group" id="">
                            <label>
                              {" "}
                              Registration Number<span class="text-danger">*</span>
                            </label>
                            <input
                              value={registrationNumber}
                              type="tel"
                              maxlength="15"
                              pattern="[0-9.]+"
                              class="form-control"
                              id="txt-serv-seek-registration-number"
                              placeholder="Enter Registration Number"
                              onChange={onChange}
                              required="true"
                            />
                          </div>

                          <div class="form-group" id="">
                            <label>
                              {" "}
                              Registered With <span class="text-danger">*</span>
                            </label>
                            <input
                              value={regWith}
                              type="text"
                              maxlength="40"
                              class="form-control"
                              id="txt-serv-seek-regwith"
                              placeholder="Enter Registered with"
                              onChange={onChange}
                              required="true"
                            />
                          </div>

                          <div class="form-group" id="">
                            <label>
                              NTN/STRN <span class="text-danger">*</span>
                            </label>
                            <input
                              value={ntnStrn}
                              type="text"
                              class="form-control"
                              id="txt-ntn-strn"
                              required="true"
                              maxlength="15"
                              placeholder="Enter NTN/STRN"
                              onChange={onChange}
                            />
                          </div>
                          <div class="form-group" id="div-ss-contact1">
                            <label>
                              Contact Office (Main Office) <span class="text-danger">*</span>
                            </label>
                            <input
                              value={ContactOne}
                              type="tel"
                              maxlength="15"
                              pattern="[0-9.]+"
                              class="form-control"
                              id="txt-serv-seek-contact1"
                              placeholder="Enter Contact Office"
                              onChange={onChange}
                              required="true"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group" id="">
                            <label>
                              {" "}
                              Address Office <span class="text-danger">*</span>
                            </label>
                            <input
                              value={organizationAddressOne}
                              type="text"
                              maxlength="100"
                              class="form-control"
                              id="txt-serv-seek-org-addr1"
                              placeholder="Enter Address Office"
                              required="true"
                              onChange={onChange}
                            />
                          </div>


                          <div class="form-group" id="div-ss-persemail">
                            <label>
                              Email Address (Will be used for signup)<span class="text-danger">*</span>
                            </label>
                            <input
                              value={serviceSeekerPersonalEmail}
                              type="email"
                              maxlength="50"
                              class="form-control"
                              required="true"
                              id="txt-serv-seek-pers-email"
                              placeholder="Enter Email Address"
                              onChange={onChange}
                            />
                          </div>

                          <div class="form-group" id="div-ss-commaddr">
                            <label>
                              {" "}
                              Password <span class="text-danger">*</span>
                            </label>
                            <div className="pass-wrapper">
                              <input
                                value={password}
                                type={passwordShown ? "text" : "password"}
                                class="testingpass form-control"
                                id="txt-serv-password"
                                maxlength="30"
                                placeholder="Enter Password"
                                onChange={onChange}
                                required="true"
                              />
                              <img onClick={togglePasswordVisiblity} className="visbility-icon" src={imageIcon} alt="visbility-icon" />
                            </div>
                          </div>

                          <div class="form-group" id="">
                            <label>
                              {" "}
                              Name of contact persons <span class="text-danger">*</span>
                            </label>
                            <input
                              value={contacts}
                              type="text"
                              class="form-control"
                              maxlength="50"
                              id="txt-serv-seek-contacts"
                              placeholder="Enter Name of the contact person"
                              onChange={onChange}
                            />
                          </div>

                          <div class="form-group" id="div-ss-desig">
                            <label>
                              Designation <span class="text-danger">*</span>
                            </label>
                            <select
                              id="dd-serv-designation"
                              class="form-control"
                              value={designation}
                              onChange={onChange}
                              required="true"
                            >
                              <option hidden disable value="Select">Select</option>
                              <option value="Partner">Partner </option>
                              <option value="Director">Director </option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                          <Dropdown>
                            <DropdownToggle onClick={toggle3} style={{ marginLeft: '10px' }} id="btnnext" className="button bt-blue " caret>
                              Next
                            </DropdownToggle>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>

            <div id="div-ss-signup-accordions" className="additional-info-div">
              <div class="card" id="generalInfoAccord">
                <div class="card-header" style={{ backgroundColor: "#5B9BD5" }}>
                  <span onClick={togglee} className="sssheadtag text-light">Additional Information</span>
                  {collapsee === true ? <Button className="togglebtn" color="muted" onClick={togglee} ><i class="fa fa-minus" aria-hidden="true" onClick={togglee}></i></Button>
                    : <Button className="togglebtn" color="muted" onClick={togglee}><i class="fas fa-plus"></i></Button>}
                </div>
                <div
                  id="accordion1"
                  class="collapse show"
                  data-parent="#accordion"
                >
                  <Collapse
                    isOpen={collapsee}
                  >
                    <div class="card-body">
                      <div class="row col-md-12">
                        <div class="col-md-6">
                          <div class="form-group" id="">
                            <label>
                              {" "}
                              Total Number of Staff (eg:9999){" "}
                            </label>
                            <input
                              value={totalStaff}
                              type="Number"
                              min="1"
                              maxlength="10"
                              class="form-control"
                              id="dd-serv-seek-totalStaff"
                              placeholder="Enter Total Number of Staff"
                              onChange={onChange}
                            />
                          </div>

                          {/* <div class="form-group" id="div-ss-gender">
                          <label>Gender</label>
                          <select
                            id="dd-serv-seek-gender"
                            class="form-control"
                            onChange={onChange}
                          >
                            <option hidden value={gender}>Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          
                        </div> */}
                          <div class="form-group" id="div-ss-contact2">
                            <label>Contact No 2</label>
                            <input
                              value={ContactTwo}
                              type="tel"
                              class="form-control"
                              maxlength="15"
                              pattern="[0-9.]+"
                              id="txt-serv-seek-contact2"
                              placeholder="Enter Contact no.2"
                              onChange={onChange}
                            />
                            <label
                              class="d-none text-danger"
                              id="lbl-ss-contact2-error"
                            ></label>
                          </div>

                          <div class="form-group" id="">
                            <label> Address 2</label>
                            <input
                              value={organizationAddressTwo}
                              type="text"
                              class="form-control"
                              maxlength="100"
                              id="txt-serv-seek-org-addr2"
                              placeholder="Enter  Address 2"
                              onChange={onChange}
                            />
                            <label
                              class="d-none text-danger"
                              id="lbl-ss-org-addr1-error"
                            >
                              Please enter address 1
                            </label>
                          </div>
                          <div class="form-group" id="">
                            <label>Email Other</label>
                            <input
                              value={emailOther}
                              type="email"
                              maxlength="50"
                              id="div-ss-offemail"
                              class="form-control"
                              placeholder="Enter Email Other"
                              onChange={onChange}
                            />
                          </div>

                          <div class="form-group" id="">
                            <label>Website (Complete URL)</label>
                            <input
                              value={website}
                              type="text"
                              class="form-control"
                              maxlength="50"
                              id="txt-serv-seek-org-website"
                              placeholder="Enter Website"
                              onChange={onChange}
                            />
                          </div>

                          <div class="form-group" id="div-ss-member">
                            <label> Regulatory Body</label>
                            <select
                              id="dd-serv-memfield"
                              class="form-control"
                              value={regulatoryBody}
                              onChange={onChange}
                            >
                              <option value="ICAP">ICAP</option>
                              <option value="ICMAP">ICMAP </option>
                              <option value="PIPFA">PIPFA </option>
                              <option value="ACCA">ACCA</option>
                              <option value="ICSP">ICSP</option>
                              <option value="PBC">PBC</option>
                            </select>
                            <label
                              class="d-none text-danger"
                              id="lbl-ss-member-error"
                            ></label>
                          </div>
                          <div class="form-group" id="div-ss-promoCode">
                            <label>Promo Code</label>
                            <input
                              value={promoCode}
                              type="text"
                              maxlength="15"
                              class="form-control"
                              id="txt-serv-seek-promo-code"
                              placeholder="Enter Promo Code"
                              onChange={onChange}
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group" id="div-ss-member">
                            <label> Quality Control Rating </label>
                            <select
                              id="dd-serv-quality-control-rating"
                              class="form-control"
                              value={qualityControlRatingCondition}
                              onChange={onChange}
                            >
                              <option hidden value="Select">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                            {qualityControlRatingCondition === "Yes" ? <div className="conditiondiv">
                              <label>Current Status(Quality Control Rating)</label>
                              <input
                                value={qualityControlRating}
                                type="text"
                                required="true"
                                class="form-control"
                                maxlength="20"
                                id="dd-serv-quality-control-rating-status"
                                placeholder="Current Status(Quality Control Rating)"
                                onChange={onChange}
                              /></div>
                              :
                              <div>
                                <input
                                  value="No"
                                  type="text"
                                  maxlength="20"
                                  hidden="true"
                                  class="form-control"
                                  id="dd-serv-quality-control-rating-status"
                                  placeholder="Current Status"
                                /></div>
                            }
                          </div>

                          <div class="form-group" id="">
                            <label>
                              {" "}
                              Membership With Other Professional Bodies{" "}
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              value={membershipsOther}
                              type="text"
                              maxlength="40"
                              class="form-control"
                              id="txt-serv-seek-membership-other"
                              placeholder="Enter  Membership With Other Professional Bodies"
                              onChange={onChange}
                            />
                          </div>
                          <div class="form-group" id="">
                            <label>
                              {" "}
                              Affiliations with International Network Firms{" "}
                            </label>
                            <input
                              value={affiliation}
                              type="text"
                              class="form-control"
                              maxlength="100"
                              id="txt-serv-seek-affiliation"
                              placeholder="Enter affiliation"
                              onChange={onChange}
                            />
                            <label
                              class="d-none text-danger"
                              id="lbl-ss-incorp-no-error"
                            >
                              Please enter Registered With.
                            </label>
                          </div>

                          <div class="form-group" id="">
                            <label>
                              {" "}
                              SBP Panel{" "}
                            </label>
                            <input
                              value={sbpPanel}
                              type="text"
                              maxlength="50"
                              class="form-control"
                              id="txt-serv-seek-sbpPanel"
                              placeholder="Enter SBP Panel"
                              onChange={onChange}
                            />
                          </div>

                          <div class="form-group" id="">
                            <label>
                              {" "}
                              Number of offices in Pakistan{" "}
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              value={noOfOffice}
                              type="Number"
                              min="1"
                              maxlength="10"
                              class="form-control"
                              id="txt-serv-seek-no-of-offices"
                              placeholder="Enter Registered with"
                              onChange={onChange}
                            />
                            <label
                              class="d-none text-danger"
                              id="lbl-ss-incorp-no-error"
                            >
                              Please enter no of offices.
                            </label>
                          </div>

                          <div class="form-group" id="div-ss-gender">
                            <label>
                              Location of offices (Other than the main office){" "}
                            </label>
                            {/* <select
                            id="dd-serv-seek-location-other"
                            class="form-control"
                            onChange={onChange}
                            multiple="multiple" 
                          >
                            <option hidden value={locationOther}>Select Cities</option>
                            <option value="Karachi">Karachi</option>
                            <option value="Lahore">Lahore</option>
                          </select> */}
                            <MultiSelect
                              options={options2}
                              value={selected2}
                              id="dd-serv-seek-location-other"
                              onChange={setSelected2}
                              labelledBy="Select"
                            />

                            <label
                              class="d-none text-danger"
                              id="lbl-ss-gender-error"
                            ></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
            <div class="col-lg-12" id="div-ss-signup">
              <div className="btnnextt">
                <div className="captcha">
                  <Recaptcha
                    sitekey={SITE_KEY}
                    render="explicit"
                    onloadCallback={onloadCallback}
                    verifyCallback={callback2}
                  />
                </div>
                <div class="form-group" >
                  <Form.Group>
                    <input style={{ marginRight: "5px" }} onClick={handleClick} checked={checked} type="checkbox" />
                    <label>Agree to
                      <Link target="_blank" to="/Terms-and-Conditions"> <span className="terms-conditions">Terms and Conditions</span></Link>
                      <span class="text-danger">*</span>
                    </label>
                  </Form.Group>
                  {checked && verified ?
                    <Button
                      type="submit" className="button"
                    >
                      {props.loading ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        "Register"
                      )}
                    </Button>
                    :
                    <Button
                      type="submit" disabled variant="secondary" className="button"
                    >
                      {props.loading ? (
                        <Spinner animation="border" variant="light" />
                      ) : (
                        "Register"
                      )}
                    </Button>
                  }
                </div>
              </div>
            </div>
          </Form>

        </div>
      </section>

      <FooterApp />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    creationData: state.serviceProvider.creationData,
    loading: state.serviceProvider.loading,
    global: state.globalReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createServiceProvider: (item, isLoading) =>
      dispatch(createServiceProvider(item, isLoading)),
    checkUniqueEmail: (item) => uniqueEmail(item),
    checkUniqueName: (item) => uniqueName(item),
    checkUniqueCnic: (item) => uniqueCnic(item),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceProvider);
