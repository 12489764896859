import { CREATE_INDIVIDUAL, LOADING_STATE } from "../../Constants/constants";
import axios from "axios";
import { toast } from "react-toastify";

export const createIndividual = (data, loading) => (dispatch) => {
function myFunction() {
  let myVar
  myVar = setTimeout(alertFunc, 8000);
}
function alertFunc() {
  window.location.href = "/home";
}
  dispatch({
    type: LOADING_STATE,
    payload: loading,
  });

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/individual/create`,
      data
    )
    .then(function (response) {
      console.log(response);
      toast.success(response?.data?.msg, {
        position: "top-right",
        autoClose: 6000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });


      dispatch({
        type: CREATE_INDIVIDUAL,
        payload: response.data,
      });

      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });
      
      return response.data;
    })
    .catch(function (err) {
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      return null ;
    });
    return dataResponse;
};
