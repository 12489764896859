import React, { Component, useState,useEffect } from "react";
import { Nav, Navbar, FormControl,Accordion,OverlayTrigger,InputGroup,Form} from "react-bootstrap";
import { UncontrolledCollapse, Button, CardBody, Card,Collapse, FormGroup, Label, Input, FormFeedback, FormText,Dropdown, DropdownToggle,Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { Link,NavLink, withRouter } from "react-router-dom";
import FooterApp from "../Footer/Footer";
import HeaderApp from "../Header/Header";
import {
  SITE_KEY,
} from "../../Constants/constants";
import { createIndividual } from "../../Actions/serviceSeeker/createIndividual";
import { createIndividualNonMember } from "../../Actions/serviceSeeker/createIndividualNonMember";
import { createCompanyThroughMember } from "../../Actions/serviceSeeker/createCompanyThroughMember";
import DatePicker from "react-datepicker";
import { Spinner } from "react-bootstrap";
import { uniqueEmail, uniqueName,uniqueCnic } from "../../Actions/global/global";
import "react-datepicker/dist/react-datepicker.css";
import visbilty from '../../images/visibility.svg';
import invisible from '../../images/invisible.svg';
import { connect } from "react-redux";
import Recaptcha from 'react-recaptcha';
import { resetPassword } from "../../Actions/serviceProvider/ServiceProviderLogin";
import "./custom.css";
import MetaTags from 'react-meta-tags';

const ServiceSeekerSignup = (props) => {
  const [name_of_member, setName_of_member] = useState("");
  const [officialemail, setofficialemail] = useState("");
  const [name, setName] = useState("");
  const [cnic, setCnic] = useState("");
  const [uniqueCnicState, setuniqueCnicState] = useState("");
  const [gender, setGender] = useState("");
  const [personalemail, setpersonalemail] = useState("");
  const [member, setMember] = useState("");
  const [ntnStrn, setNtnStrn] = useState("");
  const [memberSince, setMemberSince] = useState("");
  const [membershipNo, setMembershipNo] = useState("");
  const [website, setServiceSeekerWebsite] = useState("");
  const [organizationName, setOrganizationName] = useState("");
  const [organizationEmail, setOrganizationEmail] = useState("");
  const [serviceSeekerType, setServiceSeekerType] = useState("Individual(M)");
  const [organizationIncorp, setOrganizationIncorp] = useState("");
  const [organizationWebsite, setOrganizationWebsite] = useState("");
  const [organizationAddressOne, setOrganizationAddressOne] = useState("");
  const [organizationAddressTwo, setOrganizationAddressTwo] = useState("");
  const [organizationContactOne, setOrganizationContactOne] = useState("");
  const [organizationContactTwo, setOrganizationContactTwo] = useState("");
  const [serviceSeekerContactOne, setServiceSeekerContactOne] = useState("");
  const [serviceSeekerContactTwo, setServiceSeekerContactTwo] = useState("");
  const [serviceSeekerDesignation, setServiceSeekerDesignation] = useState("");
  const [serviceSeekerOfficialEmail, setServiceSeekerOfficialEmail] =
    useState("");
  const [serviceSeekerPersonalEmail, setServiceSeekerPersonalEmail] =
    useState("");
  const [serviceSeekerPermanentAddress, setServiceSeekerPermanentAddress] =
    useState("");
  const [
    serviceSeekerAssociatedOrganization,
    setServiceSeekerAssociatedOrganization,
  ] = useState("");
  const [
    serviceSeekerCommunicationAddress,
    setServiceSeekerCommunicationAddress,
  ] = useState("");
  const [memberField, setMemberField] = useState("");
  const [setCalendarValue, setCalendar] = useState(false);
  const [workingAs, setWorkingAs] = useState("");
  const [password, setPassword] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [collapse, setCollapse] = useState(true);
  const toggle = () => setCollapse(!collapse);
  const [collapsee, setCollapsee] = useState(false);
  const togglee = () => setCollapsee(!collapsee);
  const [checked, setChecked] = useState(false);
  const handleClick = () => setChecked(!checked);
  const [verified, setverified] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [imageIcon, setimageIcon] = useState(visbilty);
  const [modal2, setModal2] = useState(false);
  const modalTogglee = () => setModal2(!modal2);
  const [modal3, setModal3] = useState(false);
  const modalToggleee = () => setModal3(!modal3);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
    setimageIcon(passwordShown ? visbilty : invisible)
  };
  

  function toggle3() {
    setCollapse(false);
    setCollapsee(true);
  };  

  function onChange(event) {
    const { value, name, id } = event.target;

    switch (id) {
      case "txt-serv-seek-promo-code":
        setPromoCode(value);
        break;

      case "dd-serv-seek-type":
        setServiceSeekerType(value);
        break;

      case "dd-serv-working":
        setWorkingAs(value);
        break;

      case "txt-serv-seek-name":
        setName(value);
        break;

      case "txt-serv-seek-name_of_member":
        setName_of_member(value);
        break;

      case "txt-serv-seek-official-email":
        setofficialemail(value);
        break;

      case "txt-serv-seek-cnic":
        setCnic(value);
        break;

      case "dd-serv-seek-gender":
        setGender(value);
        break;

      case "dd-serv-seek-member":
        setMember(value);
        break;

      case "txt-serv-seek-memship-no":
        setMembershipNo(value);
        break;

      case "txt-serv-seek-contact1":
        setServiceSeekerContactOne(value);
        break;

      case "txt-serv-seek-contact2":
        setServiceSeekerContactTwo(value);
        break;

      case "txt-serv-seek-comm-addr":
        setServiceSeekerCommunicationAddress(value);
        break;

      case "txt-serv-seek-perm-addr":
        setServiceSeekerPermanentAddress(value);
        break;

      case "txt-serv-seek-personal-email":
        setpersonalemail(value);
        break;
        

      case "txt-serv-seek-off-email":
        setServiceSeekerOfficialEmail(value);
        break;

      case "txt-serv-seek-pers-email":
        setServiceSeekerPersonalEmail(value);
        break;

      case "txt-serv-seek-designation":
        setServiceSeekerDesignation(value);
        break;

      case "txt-serv-seek-org":
        setServiceSeekerAssociatedOrganization(value);
        break;

      case "txt-serv-seek-website":
        setServiceSeekerWebsite(value);
        break;

      case "txt-serv-seek-org-name":
        setOrganizationName(value);
        break;

      case "txt-serv-seek-incorp-no":
        setOrganizationIncorp(value);
        break;

      case "txt-ntn-strn":
        setNtnStrn(value);
        break;

      case "txt-serv-seek-org-email":
        setOrganizationEmail(value);
        break;

      case "txt-serv-seek-org-website":
        setOrganizationWebsite(value);
        break;

      case "txt-serv-seek-org-addr1":
        setOrganizationAddressOne(value);
        break;

      case "txt-serv-seek-org-addr2":
        setOrganizationAddressTwo(value);
        break;

      case "txt-serv-seek-org-contact1":
        setOrganizationContactOne(value);
        break;

      case "txt-serv-seek-org-contact2":
        setOrganizationContactTwo(value);
        break;

      case "dd-serv-memfield":
        setMemberField(value);
        break;

      case "txt-serv-password":
        setPassword(value);
        break;

      default:
        // console.log("value");
    }
  }
  const popover = (
    <div>
      <p>asdasd</p>
    </div>
  );

  function resetForm() {
    setName("");
    setCnic("");
    setGender("");
    setMember("");
    setNtnStrn("");
    setMemberSince("");
    setMembershipNo("");
    setServiceSeekerWebsite("");
    setOrganizationName("");
    setOrganizationEmail("");
    setServiceSeekerType("Individual(M)");
    setOrganizationIncorp("");
    setOrganizationWebsite("");
    setOrganizationAddressOne("");
    setOrganizationAddressTwo("");
    setOrganizationContactOne("");
    setOrganizationContactTwo("");
    setServiceSeekerContactOne("");
    setServiceSeekerContactTwo("");
    setServiceSeekerDesignation("");
    setServiceSeekerOfficialEmail("");
    setServiceSeekerPersonalEmail("");
    setServiceSeekerPermanentAddress("");
    setServiceSeekerAssociatedOrganization("");
    setServiceSeekerCommunicationAddress("");
    setMemberField("");
    setCalendar(false);
    setWorkingAs("");
    setPassword("");
    setPromoCode("");
  }

  function getCalendarValue(val) {
    setMemberSince(val);
  }

  
  async function register(event) {
    event.preventDefault();
    if(verified){
    const payload = {
      name,
      cnic,
      gender,
      member,
      ntnStrn,
      memberSince,
      membershipNo,
      website,
      organizationAddressOne,
      organizationAddressTwo,
      organizationContactOne,
      organizationContactTwo,
      organizationEmail,
      organizationIncorp,
      organizationWebsite,
      serviceSeekerAssociatedOrganization,
      serviceSeekerCommunicationAddress,
      serviceSeekerContactOne,
      serviceSeekerContactTwo,
      serviceSeekerDesignation,
      serviceSeekerOfficialEmail,
      serviceSeekerPermanentAddress,
      serviceSeekerPersonalEmail,
      serviceSeekerType,
      memberField,
    };

    let payloadData;
    let uniqueMail;
    let uniqueName;
    let uniqueCnic;

    switch (serviceSeekerType) {
      case "Individual(M)":
        payloadData = {
          member_type: memberField,
          member_dropdown: serviceSeekerType,
          membership_number: membershipNo,
          currently_working_as: workingAs,
          name: name,
          cnic: cnic,
          username: name,
          contact_number1: serviceSeekerContactOne,
          contact_number2: serviceSeekerContactTwo,
          contact_address_comm: serviceSeekerCommunicationAddress,
          contact_address_permanent: serviceSeekerPermanentAddress,
          email: serviceSeekerPersonalEmail,
          email_official: serviceSeekerOfficialEmail,
          designation: serviceSeekerDesignation,
          organisation: serviceSeekerAssociatedOrganization,
          website: website,
          password: password,
          promoCode: promoCode,
        };

        uniqueMail = await props.checkUniqueEmail(serviceSeekerPersonalEmail);
        uniqueName = await props.checkUniqueName(name);
        // console.log(uniqueCnicState,"in state");
        // if(cnic != ""){
        //   uniqueCnic = await props.checkUniqueCnic(cnic);
        //   setuniqueCnicState(uniqueCnic);
        //    }
          if (uniqueMail?.success && uniqueName?.success){
            if(cnic != ""){
              uniqueCnic = await props.checkUniqueCnic(cnic);
              if(uniqueCnic?.success){
                let checkdata;
                checkdata = await props.createIndividual(payloadData, true);
                  if(checkdata?.success){
                    setModal2(!modal2);
                    resetForm()
                  }
              }
              }
              else{
                let checkdata;
                  checkdata = await props.createIndividual(payloadData, true);
                    if(checkdata?.success){
                      setModal2(!modal2);
                      resetForm()
                    }
              }
            }
            
          
        break;

      case "Individual(NM)":
        payloadData = {
          member_dropdown: "Non-Individual",
          name: name,
          cnic: cnic,
          currently_working_as: workingAs,
          username: name,
          password: password,
          contact_number1: serviceSeekerContactOne,
          contact_number2: serviceSeekerContactTwo,
          contact_address_comm: serviceSeekerCommunicationAddress,
          contact_address_permanent: serviceSeekerPermanentAddress,
          email: serviceSeekerPersonalEmail,
          email_official: serviceSeekerOfficialEmail,
          designation: serviceSeekerDesignation,
          organisation: serviceSeekerAssociatedOrganization,
          website: website,
          promoCode: promoCode,
        };

        uniqueMail = await props.checkUniqueEmail(serviceSeekerPersonalEmail);
        uniqueName = await props.checkUniqueName(name);

        if (uniqueMail?.success && uniqueName?.success){
            if(cnic != ""){
              uniqueCnic = await props.checkUniqueCnic(cnic);
              if(uniqueCnic?.success){
                let checkdata;
                  checkdata = await props.createIndividualNonMember(payloadData, true);
                  if(checkdata?.payload?.success){
                    setModal2(!modal2);
                    resetForm();
                  }
              }
            }
            else{
                let checkdata;
                checkdata = await props.createIndividualNonMember(payloadData, true);
                  if(checkdata?.payload?.success){
                    setModal2(!modal2);
                    resetForm();
                  }
            }
          }
        break;

      case "Company(M)":
        payloadData = {
          name: organizationName,
          incorporation_number: organizationIncorp,
          company_type: serviceSeekerType,
          ntn_strn: ntnStrn,
          contact_number1: serviceSeekerContactOne,
          contact_number2: serviceSeekerContactTwo,
          username: organizationName,
          password: password,
          designation: serviceSeekerDesignation,
          address1: serviceSeekerCommunicationAddress,
          address2: serviceSeekerPermanentAddress,
          email: organizationEmail,
          member_type: memberField,
          member_number: membershipNo,
          website: website,
          official_email:officialemail,
          personal_email:personalemail,
          name_of_member: name_of_member,
          member_id: membershipNo,
          promoCode: promoCode,
          is_member: true,
        };

        uniqueMail = await props.checkUniqueEmail(organizationEmail);
        uniqueName = await props.checkUniqueName(name);

        if (uniqueMail.success && uniqueName.success) {
          let checkdata;
          checkdata = await props.createCompanyThroughMember(payloadData, true);
            if(checkdata?.success){
              setModal2(!modal2);
              resetForm()
            }
        }
        

        break;

      case "Company/Org(WM)":
        payloadData = {
          name: organizationName,
          incorporation_number: organizationIncorp,
          company_type: serviceSeekerType,
          ntn_strn: ntnStrn,
          contact_number1: serviceSeekerContactOne,
          contact_number2: serviceSeekerContactTwo,
          username: organizationName,
          password: password,
          address1: serviceSeekerCommunicationAddress,
          address2: serviceSeekerPermanentAddress,
          email: organizationEmail,
          website: website,
          designation: serviceSeekerDesignation,
          name_of_member: name,
          member_id: membershipNo,
          promoCode: promoCode,
          member_number: membershipNo,
          is_member: false,
        };

        // console.log("payload company without", payloadData);

        uniqueMail = await props.checkUniqueEmail(serviceSeekerPersonalEmail);
        uniqueName = await props.checkUniqueName(name);

        if (uniqueMail.success && uniqueName.success) {
          let checkdata;
          checkdata = await props.createCompanyThroughMember(payloadData, true);
            if(checkdata?.success){
              setModal2(!modal2);
              resetForm()
            }
        }

        break;

      default:
        // console.log("payload");
      }
    }
    else{
      setModal3(true);
    }
  }
  const callback2 = function () {
    setverified(true)
  };
  
  const onloadCallback = function() {
    console.log('loaded()');
}


  return (
    <div>
      <MetaTags>
        <title>Register as a Service Seeker | Apexway</title>
        <meta name="description" content="Looking to outsource accounting, bookkeeping, taxation and other finance functions of your firm, APEXWAY gives to access to the pool of verified service providers" />
        <meta name="robots" content="noodp,noydir" />
      </MetaTags>
      <div>
        <HeaderApp />
      </div>
      <div>
        <Modal size="lg" isOpen={modal2} toggle={modalTogglee} className="success-msg">
          <ModalHeader toggle={modalTogglee}></ModalHeader>
          <ModalBody>
          Registration form has been successfully submitted. Thank you!<br></br><br></br>
          We are very pleased that you are interested in beginning your journey with us.<br></br><br></br>
          You will shortly receive an email from <a href="info@apex-way.com" target="_blank">info@apex-way.com</a> that will guide you to the registration process. If you do not receive an email, check your “junk mail/spam” folder. For technical assistance, please do not hesitate to write to us at <a href="info@apex-way.com" target="_blank">info@apex-way.com</a>. We shall be glad to get back to you.<br></br><br></br>
          <h3>The APEXWAY Team</h3><br></br>
          </ModalBody>
        </Modal>
      </div>
      <div>
        <Modal size="lg" isOpen={modal3} toggle={modalToggleee} className="success-msg">
        <ModalHeader toggle={modalToggleee}>Verification Failed !!!</ModalHeader>
          <ModalBody>
          reCAPTCHA verification is required to proceed on this website<br></br>
          Click the checkbox to get a verification challenge
          </ModalBody>
        </Modal>
      </div>

      <div>
        <div ids="iq-home" className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                    Service Seeker REGISTRATION<br></br>
                    <span class="section_1-breadcrumbs"></span>
                    </h1>
                </div>
            </div>
        </div>
    </div>

      <section class="features py-5">
        <div class="container">
          <div class="heading pb-4">
            <p class="para mb-5 text-center">
              Register Now and get the services from your desired Service
              Providers
            </p>
          </div>

          <Form  onSubmit={register} id="form">
            <div class="form-group col-md-6">
              <label className="labelcolor">Select Service Seeker Type</label>
              <select
                id="dd-serv-seek-type"
                class="form-control"
                onChange={onChange}
              >
                {/* <option value={serviceSeekerType}>Select</option> */}
                <option value="Individual(M)">Individual (Member)</option>
                <option value="Individual(NM)">Individual (Non-Member)</option>
                <option value="Company(M)">Company (through Member)</option>
                <option value="Company/Org(WM)">
                  Company/Organization (without member)
                </option>
              </select>
            </div>
          
            <div id="div-ss-signup-accordions">
              <div class="card" id="generalInfoAccord">
                <div class="card-header" style={{ backgroundColor: "#5B9BD5" }}>
                  <span onClick={toggle} className="sssheadtag text-light">Required Information</span>
                  {collapse === true ? <Button className="togglebtn" color="muted"  onClick={toggle} ><i class="fa fa-minus" aria-hidden="true" onClick={toggle}></i></Button> 
                  : <Button className="togglebtn" color="muted" onClick={toggle}><i class="fas fa-plus"></i></Button> }
                </div>
                <div
                  id="accordion1"
                  class="collapse show"
                  data-parent="#accordion"
                >
                <Collapse
                isOpen={collapse}
              >
                
                  <div class="card-body">
                    <div class="row col-md-12">
                      <div class="col-md-6">
                      {(serviceSeekerType === "Individual(M)") && (
                          <>
                            <div class="form-group" id="div-ss-member">
                              <label className="labelcolor">
                                Member <span class="text-danger">*</span>
                              </label>
                              <select
                                id="dd-serv-memfield"
                                class="form-control"
                                value={memberField}
                                onChange={onChange}
                                required="true"
                              >
                                <option  hidden disable value="">
                                  Select Member Type
                                </option>
                                <option value="ICAP">ICAP</option>
                                <option value="ICMAP">ICMAP </option>
                                <option value="PIPFA">PIPFA </option>
                                <option value="ACCA">ACCA</option>
                                <option value="ICSP">ICSP</option>
                                <option value="PBC">PBC</option>
                                <option value="Others">Others</option>
                              </select>
                            </div>
                            <div class="form-group" id="div-ss-memshipnum">
                            
                              <label className="labelcolor">
                                Membership Number{" "}
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                value={membershipNo}
                                type="text"
                                class="form-control"
                                id="txt-serv-seek-memship-no"
                                placeholder="Enter Membership Number"
                                onChange={onChange}
                                required="true"
                                maxlength="15"
                              />
                              <label
                                class="d-none text-danger"
                                id="lbl-ss-memshipno-error"
                              ></label>
                            </div>
                          </>
                        )}

                        {(serviceSeekerType === "Company/Org(WM)" ||
                          serviceSeekerType === "Company(M)") && (
                          <>
                          <div class="form-group" id="">
                            <label className="labelcolor">
                              Name of the Organization {" "}
                              <span class="text-danger">*</span>
                            </label>
                            <input
                              value={organizationName}
                              type="text"
                              maxlength="50"
                              class="form-control"
                              id="txt-serv-seek-org-name"
                              placeholder="Enter Organization Name"
                              onChange={onChange}
                              required="true"
                            />
                          </div>
                          </>
                        )}
                        
                        {(serviceSeekerType === "Individual(M)" ||
                          serviceSeekerType === "Individual(NM)") && (
                          <>
                          <div class="form-group" id="div-ss-name">
                        
                          <label className="labelcolor">
                            Name <span class="text-danger">*</span>
                          </label>
                          <input
                            value={name}
                            type="text"
                            maxlength="50"
                            class="form-control"
                            id="txt-serv-seek-name"
                            placeholder="Enter Name"
                            onChange={onChange}
                            required="true"
                          />
                          <label
                            class="d-none text-danger"
                            id="lbl-ss-name-error"
                          ></label>
                        </div>
                          </>
                        )}

                        {(serviceSeekerType === "Company/Org(WM)" ||
                          serviceSeekerType === "Company(M)") && (
                          <>
                          <div class="form-group" id="">
                            <label className="labelcolor">
                              NTN/STRN <span class="text-danger">*</span>
                            </label>
                            <input
                              value={ntnStrn}
                              type="text"
                              class="form-control"
                              id="txt-ntn-strn"
                              placeholder="Enter NTN/STRN"
                              onChange={onChange}
                              required="true"
                              maxlength="15"
                            />
                            <label
                              class="d-none text-danger"
                              id="lbl-ss-ntn-strn-error"
                            >
                              Please enter Ntn/Strn
                            </label>
                          </div>
                          </>
                        )}
                        <div class="form-group" id="div-ss-contact1">
                          <label className="labelcolor">
                            Contact No 1 <span class="text-danger">*</span>
                          </label>
                          <input
                            value={serviceSeekerContactOne}
                            type="text"
                            class="form-control"
                            id="txt-serv-seek-contact1"
                            placeholder="Enter Contact No 1"
                            onChange={onChange}
                            required="true"
                            maxlength="15"
                          />
                          <label
                            class="d-none text-danger"
                            id="lbl-ss-contact1-error"
                          ></label>
                        </div>
                        <div class="form-group" id="div-ss-commaddr">
                          <label className="labelcolor">
                            Contact Address{" "}
                            <span class="text-danger">*</span>
                          </label>
                          <input
                            value={serviceSeekerCommunicationAddress}
                            type="text"
                            class="form-control"
                            maxlength="100"
                            id="txt-serv-seek-comm-addr"
                            placeholder="Enter Contact Address"
                            onChange={onChange}
                            required="true"
                          />
                        </div> 
                        

                        {(serviceSeekerType === "Company(M)") && (
                          <>
                          <div class="form-group" id="div-ss-name">
                        
                          <label className="labelcolor">
                            Name Of Member <span class="text-danger">*</span>
                          </label>
                          <input
                            value={name_of_member}
                            type="text"
                            class="form-control"
                            maxlength="50"
                            id="txt-serv-seek-name_of_member"
                            placeholder="Enter Name of Member"
                            onChange={onChange}
                            required="true"
                          />
                          <label
                            class="d-none text-danger"
                            id="lbl-ss-name-error"
                          ></label>
                        </div>
                          
                          </>
                        )}
                        {(serviceSeekerType === "Company(M)") && (
                            <div class="form-group" id="div-ss-offemail">
                          <label className="labelcolor">Official Email</label>
                            <input
                              value={officialemail}
                              type="email"
                              class="form-control"
                              maxlength="50"
                              id="txt-serv-seek-official-email"
                              placeholder="Enter Official Email"
                              onChange={onChange}
                            />
                        </div>
                        )} 

                        {(serviceSeekerType === "Company/Org(WM)") && (
                          <>
                          <div class="form-group" id="div-ss-name">
                        
                          <label className="labelcolor">
                            Name of Contact Person <span class="text-danger">*</span>
                          </label>
                          <input
                            value={name_of_member}
                            type="text"
                            maxlength="50"
                            class="form-control"
                            id="txt-serv-seek-name_of_member"
                            placeholder="Name of Contact Person"
                            onChange={onChange}
                            required="true"
                          />
                          <label
                            class="d-none text-danger"
                            id="lbl-ss-name-error"
                          ></label>
                        </div>
                          
                          </>
                        )}

                        
                      </div>
                      <div class="col-md-6">

                      {(serviceSeekerType === "Company/Org(WM)" ||
                          serviceSeekerType === "Company(M)") && (
                          <>
                          <div class="form-group" id="div-ss-desig">
                          <label className="labelcolor">
                            Designation <span class="text-danger">*</span>
                          </label>

                          <input
                            value={serviceSeekerDesignation}
                            type="text"
                            class="form-control"
                            maxlength="30"
                            id="txt-serv-seek-designation"
                            placeholder="Enter Designation"
                            onChange={onChange}
                            required="true"
                          />
                        </div>
                          </>
                        )}
                        {(serviceSeekerType === "Individual(M)" ||
                          serviceSeekerType === "Individual(NM)") && (
                            <div class="form-group" id="div-ss-persemail">
                          <label className="labelcolor">
                            Personal Email (Signup Email) <span class="text-danger">*</span>
                          </label>
                            <input
                              value={serviceSeekerPersonalEmail}
                              type="email"
                              class="form-control"
                              required="true"
                              maxlength="50"
                              id="txt-serv-seek-pers-email"
                              placeholder="Enter Personal Email"
                              onChange={onChange}
                            />
                        </div>
                        )}
                        {(serviceSeekerType === "Company(M)" ||serviceSeekerType === "Company/Org(WM)" ) && (
                        <div class="form-group" id="">
                            <label className="labelcolor">
                              Signup Email <span class="text-danger">*</span>
                            </label>
                            <input
                              value={organizationEmail}
                              type="email"
                              class="form-control"
                              maxlength="50"
                              id="txt-serv-seek-org-email"
                              placeholder="Enter Signup Email"
                              onChange={onChange}
                              required="true"
                            />
                          </div>
                        )}
                        <div class="form-group" id="div-ss-commaddr">
                          <label className="labelcolor">
                            {" "}
                            Password <span class="text-danger">*</span>
                          </label>
                          <div className="pass-wrapper">
                          <input
                            value={password}
                            type={passwordShown ? "text" : "password"}
                            class="testingpass form-control"
                            id="txt-serv-password"
                            maxlength="30"
                            placeholder="Enter Password"
                            onChange={onChange}
                            required="true"
                          />
                          <img onClick={togglePasswordVisiblity} className="visbility-icon" src={imageIcon}  alt="visbility-icon" />
                          </div>
                        </div>
                        {(serviceSeekerType === "Company(M)") && (
                          <>
                            <div class="form-group" id="div-ss-member">
                              <label className="labelcolor">
                                Member <span class="text-danger">*</span>
                              </label>
                              <select
                                id="dd-serv-memfield"
                                class="form-control"
                                value={memberField}
                                onChange={onChange}
                                required="true"
                              >
                                <option  hidden disable value="">
                                  Select Member Type
                                </option>
                                <option value="ICAP">ICAP</option>
                                <option value="ICMAP">ICMAP </option>
                                <option value="PIPFA">PIPFA </option>
                                <option value="ACCA">ACCA</option>
                                <option value="ICSP">ICSP</option>
                                <option value="PBC">PBC</option>
                                <option value="Others">Others</option>
                              </select>
                            </div>
                            <div class="form-group" id="div-ss-memshipnum">
                              <label className="labelcolor">
                                Membership Number{" "}
                                <span class="text-danger">*</span>
                              </label>
                              <input
                                value={membershipNo}
                                type="text"
                                class="form-control"
                                id="txt-serv-seek-memship-no"
                                placeholder="Enter Membership Number"
                                onChange={onChange}
                                required="true"
                                maxlength="15"
                              />
                            </div>
                          </>
                        )}
                        
                        
                        <Dropdown> 
                          <DropdownToggle onClick={toggle3} style={{marginLeft: '10px'}}  id="btnnext" className="button bt-blue "  caret>
                                Next
                          </DropdownToggle>
                        </Dropdown>
                      </div>
                    </div>
                  </div>
                  </Collapse>
                </div>
              </div>
            </div>


            <div id="div-ss-signup-accordions" className="additional-info-div">
              <div class="card" id="generalInfoAccord">
                <div class="card-header" style={{ backgroundColor: "#5B9BD5" }}>
                  <span onClick={togglee} className="sssheadtag text-light">Additional Information</span>
                  {collapsee === true ? <Button className="togglebtn" color="muted"  onClick={togglee} ><i class="fa fa-minus" aria-hidden="true"></i></Button> 
                  : <Button className="togglebtn" color="muted" onClick={togglee}><i class="fas fa-plus"></i></Button> }
                </div>
                <div
                  id="accordion1"
                  class="collapse show"
                  data-parent="#accordion"
                >
                <Collapse
                isOpen={collapsee}
              >
                
                  <div class="card-body">
                    <div class="row col-md-12">
                      <div class="col-md-6">
                      {(serviceSeekerType === "Individual(M)") && (
                        <div class="form-group" id="div-ss-memsince">
                              <label className="labelcolor">Member Since</label>
                              {/* <label for="start-date">Start Date</label> */}
                              <div style={{ padding: 0 }}>
                                <DatePicker
                                  className="form-control"
                                  selected={memberSince}
                                  onChange={(date) => getCalendarValue(date)}
                                  maxDate={new Date()}
                                  placeholderText="Enter Member Since"
                                  dropdownMode="select"
                                    showMonthDropdown
                                    showYearDropdown
                                    adjustDateOnChange
                                />
                              </div>
                              
                            </div>
                        )}
                        
                        {(serviceSeekerType === "Individual(M)" ||
                          serviceSeekerType === "Individual(NM)") && (
                            <>
                            <div class="form-group" id="div-ss-cnic">
                          <label className="labelcolor">CNIC</label>
                          <input
                            value={cnic}
                            type="tel"
                            maxlength="13"
                            class="form-control"
                            id="txt-serv-seek-cnic"
                            placeholder="Enter CNIC"
                            onChange={onChange}
                          />
                          <label
                            class="d-none text-danger"
                            id="lbl-ss-cnic-error"
                          ></label>
                        </div>
                            </>
                        )}

                        <div class="form-group" id="div-ss-contact2">
                          <label className="labelcolor">Contact No 2</label>
                          <input
                            value={serviceSeekerContactTwo}
                            type="text"
                            class="form-control"
                            id="txt-serv-seek-contact2"
                            placeholder="Enter Contact No 2"
                            onChange={onChange}
                            maxlength="15"
                          />
                        </div>
                        <div class="form-group" id="div-ss-permaddr">
                          <label className="labelcolor">Permanent Address</label>

                          <input
                            value={serviceSeekerPermanentAddress}
                            type="text"
                            class="form-control"
                            maxlength="100"
                            id="txt-serv-seek-perm-addr"
                            placeholder="Enter Permanent Address"
                            onChange={onChange}
                          />
                        </div>
                        {(serviceSeekerType === "Company(M)"||
                          serviceSeekerType === "Company/Org(WM)") && (
                            <div class="form-group" id="">
                          <label className="labelcolor">Personal Email</label>
                            <input
                              value={personalemail}
                              type="email"
                              maxlength="50"
                              class="form-control"
                              id="txt-serv-seek-personal-email"
                              placeholder="Enter Personal Email"
                              onChange={onChange}
                            />
                        </div>
                        )}

                        
                      </div>
                      <div class="col-md-6">
                      {(serviceSeekerType === "Individual(M)" ||
                          serviceSeekerType === "Individual(NM)") && (
                            <div class="form-group" id="div-ss-offemail">
                          <label className="labelcolor">Official Email</label>
                            <input
                              value={serviceSeekerOfficialEmail}
                              type="email"
                              maxlength="50"
                              class="form-control"
                              id="txt-serv-seek-off-email"
                              placeholder="Enter Official Email"
                              onChange={onChange}
                            />
                        </div>
                        )}
                      {(serviceSeekerType === "Individual(M)" || serviceSeekerType === "Individual(NM)" ) && (
                        <>
                            <div class="form-group" id="div-ss-member">
                                  <label className="labelcolor">Currently Working As</label>

                                  <select
                                    id="dd-serv-working"
                                    class="form-control"
                                    value={workingAs}
                                    onChange={onChange}
                                  >
                                    <option value="">Select</option>
                                    <option value="PARTNER">PARTNER</option>
                                    <option value="DIRECTOR">DIRECTOR </option>
                                    <option value="SELF-EMPLOYED">
                                      SELF-EMPLOYED{" "}
                                    </option>
                                  </select>
                                </div>


                             <div class="form-group" id="div-ss-org">
                              <label className="labelcolor">Name of Organization</label>
                              <input
                                value={serviceSeekerAssociatedOrganization}
                                type="text"
                                class="form-control"
                                maxlength="50"
                                id="txt-serv-seek-org"
                                placeholder="Enter Organization Name"
                                onChange={onChange}
                              />
                           </div>
                                </>
                                
                                

                        )}

                        {(serviceSeekerType === "Company(M)"||
                          serviceSeekerType === "Company/Org(WM)") && (
                            <div class="form-group" id="">
                              <label className="labelcolor">Incorporation Number</label>
                              <input
                                value={organizationIncorp}
                                type="text"
                                class="form-control"
                                id="txt-serv-seek-incorp-no"
                                placeholder="Enter Incorporation Number."
                                onChange={onChange}
                                maxlength="15"
                              />
                              <label
                                class="d-none text-danger"
                                id="lbl-ss-incorp-no-error"
                              >
                                Please enter Incorporation No.
                              </label>
                            </div>
                        )}
                        
                        {(serviceSeekerType === "Individual(M)" ||
                          serviceSeekerType === "Individual(NM)"||
                          serviceSeekerType === "Company(M)"||
                          serviceSeekerType === "Company/Org(WM)") && (
                            <div class="form-group" id="div-ss-website">
                          <label className="labelcolor">Website</label>
                          <input
                            value={website}
                            type="text"
                            maxlength="50"
                            class="form-control"
                            id="txt-serv-seek-website"
                            placeholder="Enter Website"
                            onChange={onChange}
                          />
                        </div>
                        )}
                        <div class="form-group" id="div-ss-promoCode">
                          <label className="labelcolor">Promo Code</label>
                          <input
                            value={promoCode}
                            type="text"
                            class="form-control"
                            id="txt-serv-seek-promo-code"
                            placeholder="Enter Promo Code"
                            onChange={onChange}
                            maxlength="15"
                          />
                        </div>
                        
                      </div>
                        
                    </div>
                  </div>
                  </Collapse>
                </div>
              </div>
            </div>
            

            <div class="col-lg-12" id="div-ss-signup">
            <div className="btnnextt">
            <div className="captcha">
            <Recaptcha
                 sitekey={SITE_KEY}
                  render="explicit"
                  onloadCallback={onloadCallback}
                  verifyCallback={callback2}
              />
              </div>
              <div class="form-group">
                <Form.Group>
                <input style={{marginRight:"5px"}} onClick={handleClick} checked={checked} type="checkbox" />
                  <label className="labelcolor">Agree to 
                  <Link target="_blank"  to="/Terms-and-Conditions"> <span  className="terms-conditions">Terms and Conditions</span></Link>
                  <span class="text-danger">*</span>
                          </label>
                </Form.Group>
                {checked && verified ?
                  <Button
                  type="submit"  className="button"
                >
                  {props.loading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    "Register"
                  )}
                </Button>
                :
                <Button
                  type="submit" disabled variant="secondary"  className="button"
                >
                  {props.loading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    "Register"
                  )}
                </Button>
                }
              </div>
              </div>
            </div>
          </Form>
        </div>  
      </section>

      <FooterApp />
    </div>
  );
};

const mapStateToProps = (state) => {
  // console.log("state", state);
  return {
    creationData: state.serviceSeeker.creationData,
    loading: state.serviceSeeker.loading,
    globalData: state.globalReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createIndividual: (item, isLoading) =>
      dispatch(createIndividual(item, isLoading)),
    createCompanyThroughMember: (item, isLoading) =>
      dispatch(createCompanyThroughMember(item, isLoading)),
    createIndividualNonMember: (item, isLoading) =>
      dispatch(createIndividualNonMember(item, isLoading)),
    checkUniqueEmail: (item) => uniqueEmail(item),
    checkUniqueName: (item) => uniqueName(item),
    checkUniqueCnic: (item) => uniqueCnic(item),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceSeekerSignup);
