import React, { Component, useEffect, useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Redirect, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import PostWork from "./PostWork";
import PostTypeOptions from "./PostTypeOptions";
import { getServiceSeeker } from "../../Actions/serviceSeeker/individualLogin";

const LoginSchema = Yup.object().shape({
  // email: Yup.string()
  //   .email("Invalid email address format")
  //   .required("Email is required"),
  // password: Yup.string()
  //   .min(3, "Password must be 3 characters at minimum")
  //   .required("Password is required"),
  details: Yup.string().required("Details are required"),
  useOwnDetails: Yup.boolean(),
  proposalTimeline: Yup.date()
    .required("Proposal timeline is required")
    .typeError("Invalid Date"),
  completionTimeline: Yup.date()
    .required("Completion timeline is required")
    .typeError("Invalid Date"),
  feeType: Yup.string().required("Fee type is required"),
  budget: Yup.number().when("feeType", {
    is: (feeType) => (feeType !== "variable" ? true : false),
    then: Yup.number()
      .integer()
      .min(1, "Budget can not be less than 1")
      .required("Budget is required"),
  }),
  minBudget: Yup.number().when("feeType", {
    is: (feeType) => (feeType === "variable" ? true : false),
    then: Yup.number()
      .integer()
      .min(1, "Minimum budget can not be less than 1")
      .required("Minimum budget is required"),
  }),
  maxBudget: Yup.number().when("feeType", {
    is: (feeType) => (feeType === "variable" ? true : false),
    then: Yup.number()
      .integer()
      .min(
        Yup.ref("minBudget"),
        "Maximum budget can not be less than minimum budget"
      )
      .required("Maximum budget is required"),
  }),
  serviceSeekerContactPerson: Yup.string().when("useOwnDetails", {
    is: (useOwnDetails) => !useOwnDetails,
    then: Yup.string()
      .required("Contact Person Name is required")
      .min(1, "Contact Person Name is required"),
  }),

  serviceSeekerOrganization: Yup.string().when("useOwnDetails", {
    is: (useOwnDetails) => !useOwnDetails,
    then: Yup.string()
      .required("Organization is required")
      .min(1, "Organization is required"),
  }),
  serviceSeekerEmail: Yup.string().when("useOwnDetails", {
    is: (useOwnDetails) => !useOwnDetails,
    then: Yup.string()
      .required("Email is required")
      .min(1, "Email is required"),
  }),

  serviceSeekerContactNumber: Yup.string().when("useOwnDetails", {
    is: (useOwnDetails) => !useOwnDetails,
    then: Yup.string()
      .required("Contact Number is required")
      .min(1, "Contact Number is required"),
  }),

  // otherInfo: Yup.string().required("Details are required"),
});

const CreatePostTypeDetail = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [formikProposalTimeline, setFormikProposalTimeline] = useState();
  const [formikCompletionTimeline, setFormikCompletionTimeline] = useState();
  const adminToken = localStorage.getItem("user");
  const userType = adminToken ? JSON.parse(adminToken)?.data?.userType : null;
  // console.log("userType in creat ==== ", userType);
  // console.log("props in creat ==== ", props);
  useEffect(async () => {
    if (userType && !props.props.serviceSeeker.user) {
      await props.props.getServiceSeekerAction();
    }
  }, []);

  function onProposalDateChange(value) {
    setFormikProposalTimeline(value);
  }
  function onCompletionDateChange(value) {
    setFormikCompletionTimeline(value);
  }
  let postType = location.pathname.split("post-work/create/");
  let selectedPostType = "";
  // console.log(
  //   "props.props.serviceSeeker in created post === ",
  //   props.props.serviceSeeker
  // );
  if (Array.isArray(postType) && postType.length > 1) {
    selectedPostType = postType[1];
    return (
      <div className="container" style={{ marginBottom: 60 }}>
        <div className="row mb-5">
          <div className="col-lg-12">
            <h1 className="mt-5">{selectedPostType}</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6">
            <Formik
              initialValues={{
                details: "",
                proposalTimeline: "",
                completionTimeline: "",
                budget: "",
                maxBudget: "",
                minBudget: "",
                otherInfo: "",
                userType: "individual",
                feeType: "fixed",
                serviceSeekerContactPerson: "",
                serviceSeekerDesignation: "",
                serviceSeekerOrganization: "",
                serviceSeekerEmail: "",
                serviceSeekerContactNumber: "",
                sendEmail: false,
                useOwnDetails: false,
              }}
              validationSchema={LoginSchema}
              onSubmit={async (values, { setSubmitting }) => {
                let dataToSend = { ...values };
                const loggedInUserDetail = JSON.parse(
                  localStorage.getItem("user")
                );
                if (loggedInUserDetail.data) {
                  dataToSend.userId = loggedInUserDetail.data.id;
                }
                dataToSend.assignmentArea = selectedPostType;
                // dataToSend.proposalTimeline = formikProposalTimeline;
                // dataToSend.completionTimeline = formikCompletionTimeline;
                if (dataToSend.feeType === "variable") {
                  dataToSend[
                    "budget"
                  ] = `${dataToSend["minBudget"]}-${dataToSend["maxBudget"]}`;
                } else {
                  dataToSend["budget"] = `${dataToSend["budget"]}`;
                }
                if (dataToSend["useOwnDetails"]) {
                  dataToSend["serviceSeekerContactPerson"] =
                    props.props.serviceSeeker.user.name;
                  dataToSend["serviceSeekerDesignation"] =
                    props.props.serviceSeeker.user.designation;
                  dataToSend["serviceSeekerOrganization"] =
                    props.props.serviceSeeker.user.organisation;
                  dataToSend["serviceSeekerEmail"] =
                    props.props.serviceSeeker.user.email;
                  dataToSend["serviceSeekerContactNumber"] =
                    props.props.serviceSeeker.user.contact_number1;
                }
                // delete values["useOwnDetails"];

                delete dataToSend["useOwnDetails"];
                delete dataToSend["minBudget"];
                delete dataToSend["maxBudget"];
                const adminToken = localStorage.getItem("user");
                const token = JSON.parse(adminToken)?.data?.token;
                const dataResponse = await axios
                  .post(
                    `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/post/create`,
                    dataToSend,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then(function (response) {
                    // console.log(response);
                    toast.success(response?.data?.msg, {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });

                    // dispatch({
                    //   type: LOADING_STATE,
                    //   payload: !loading,
                    // });

                    // dispatch({
                    //   type: CREATE_SERVICE_PROVIDER,
                    //   payload: response.data,
                    // });
                    history.push("/post-work");
                  })
                  .catch(function (err) {
                    toast.error(
                      err?.response?.data?.msg || err?.response?.data?.message,
                      {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );

                    // dispatch({
                    //   type: LOADING_STATE,
                    //   payload: !loading,
                    // });

                    if (
                      err?.response?.data?.error &&
                      (err?.response?.data?.msg
                        ?.toLowerCase()
                        ?.includes("token is not valid") ||
                        err?.response?.data?.message
                          ?.toLowerCase()
                          ?.includes("token is not valid") ||
                        err?.response?.data?.msg
                          ?.toLowerCase()
                          ?.includes(
                            "you are not authorized to perform this action"
                          ) ||
                        err?.response?.data?.message
                          ?.toLowerCase()
                          ?.includes(
                            "you are not authorized to perform this action"
                          ))
                    ) {
                      localStorage.clear();
                      window.location.href = "/";
                    }
                    // console.log("error arrived", err);
                  });
                setSubmitting(false);
              }}
            >
              {({
                touched,
                errors,
                isSubmitting,
                values,
                handleChange,
                setFieldValue,
              }) => {
                {/* console.log("errors ==== ", errors);
                console.log(
                  "errors completion ==== ",
                  errors.completionTimeline
                );
                console.log("touched ==== ", touched);
                console.log("values in formik ==== ", values);
                console.log(
                  "formikProposalTimeline ==== ",
                  new Date(formikProposalTimeline)
                ); */}
                return (
                  <Form>
                    <div className="form-group">
                      <label>Details *</label>
                      <textarea
                        name="details"
                        placeholder="Enter Details"
                        onChange={handleChange}
                        value={values.details}
                        className={`form-control ${
                          touched.details && errors.details ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="details"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group">
                      <label>Proposal Timeline *</label>
                      <DatePicker
                        name="proposalTimeline"
                        autoComplete="off"
                        placeholder="Enter Proposal Timeline"
                        value={values.proposalTimeline}
                        minDate={moment().toDate()}
                        selected={values.proposalTimeline}
                        // value={formikProposalTimeline}
                        // selected={formikProposalTimeline}
                        onChange={(value) => {
                          setFieldValue("proposalTimeline", value);
                        }}
                        className={`form-control ${
                          touched.proposalTimeline && errors.proposalTimeline
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="proposalTimeline"
                        className="text-danger"
                        style={{ fontSize: "12.8px" }}
                        // className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group">
                      <label>Completion Timeline *</label>
                      <DatePicker
                        name="completionTimeline"
                        autoComplete="off"
                        placeholder="Enter Completion Timeline"
                        value={values.completionTimeline}
                        minDate={moment().toDate()}
                        selected={values.completionTimeline}
                        // value={formikProposalTimeline}
                        // selected={formikProposalTimeline}
                        onChange={(value) => {
                          setFieldValue("completionTimeline", value);
                        }}
                        className={`form-control ${
                          touched.completionTimeline &&
                          errors.completionTimeline
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="completionTimeline"
                        className="text-danger"
                        style={{ fontSize: "12.8px" }}
                        // className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group">
                      <label>Fee Type *</label>
                      <select
                        name="feeType"
                        placeholder="Enter Fee Type"
                        value={values.feeType}
                        onChange={handleChange}
                        className={`form-control ${
                          touched.feeType && errors.feeType ? "is-invalid" : ""
                        }`}
                      >
                        <option value="fixed">Fixed</option>
                        <option value="variable">Variable</option>
                        <option value="negotiable">Negotiable</option>
                      </select>
                      <ErrorMessage
                        component="div"
                        name="otherInfo"
                        className="invalid-feedback"
                      />
                    </div>
                    {values.feeType !== "variable" ? (
                      <div className="form-group">
                        <label>Budget *</label>
                        <input
                          type="number"
                          min={0}
                          name="budget"
                          placeholder="Enter budget"
                          value={values.budget}
                          onChange={handleChange}
                          className={`form-control ${
                            touched.budget && errors.budget ? "is-invalid" : ""
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="budget"
                          className="invalid-feedback"
                        />
                      </div>
                    ) : (
                      <>
                        <div className="form-group">
                          <label>Minimum Budget *</label>
                          <input
                            type="number"
                            name="minBudget"
                            min={0}
                            placeholder="Enter minimum budget"
                            value={values.minBudget}
                            onChange={handleChange}
                            className={`form-control ${
                              touched.minBudget && errors.minBudget
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="minBudget"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="form-group">
                          <label>Maximum Budget *</label>
                          <input
                            type="number"
                            name="maxBudget"
                            min={0}
                            placeholder="Enter maximum budget"
                            value={values.maxBudget}
                            onChange={handleChange}
                            className={`form-control ${
                              touched.maxBudget && errors.maxBudget
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="maxBudget"
                            className="invalid-feedback"
                          />
                        </div>
                      </>
                    )}
                    {/* <div className="form-group">
                    <label>Budget Other Info</label>
                    <textarea
                      name="budgetOtherInfo"
                      placeholder="Enter Budget Other Info"
                      value={values.budgetOtherInfo}
                      onChange={handleChange}
                      className={`form-control ${
                        touched.budgetOtherInfo && errors.budgetOtherInfo
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="budgetOtherInfo"
                      className="invalid-feedback"
                    />
                  </div> */}

                    <div className="form-group">
                      <label>Other Info</label>
                      <textarea
                        name="otherInfo"
                        placeholder="Enter otherInfo"
                        value={values.otherInfo}
                        onChange={handleChange}
                        className={`form-control ${
                          touched.otherInfo && errors.otherInfo
                            ? "is-invalid"
                            : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="otherInfo"
                        className="invalid-feedback"
                      />
                    </div>

                    {/* <div className="form-group">
                    <label>User Type *</label>
                    <select
                      name="userType"
                      placeholder="Enter otherInfo"
                      value={values.userType}
                      onChange={handleChange}
                      className={`form-control ${
                        touched.userType && errors.userType ? "is-invalid" : ""
                      }`}
                    >
                      <option value="individual">Individual</option>
                      <option value="company">Company</option>
                      <option value="member">Member</option>
                      <option value="non-member">Non Member</option>
                    </select>
                    <ErrorMessage
                      component="div"
                      name="otherInfo"
                      className="invalid-feedback"
                    />
                  </div> */}

                    <div
                      className="form-group"
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <div>
                        {/* <label>Budget *</label> */}
                        <input
                          type="checkbox"
                          name="useOwnDetails"
                          // placeholder="Enter budget"
                          value={values.useOwnDetails}
                          onChange={handleChange}
                          style={{ marginInlineEnd: 15 }}
                          // className={`form-control ${
                          //   touched.autoGeneratedEmail && errors.autoGeneratedEmail
                          //     ? "is-invalid"
                          //     : ""
                          // }`}
                        />
                        <label>
                          {" "}
                          Use my details as a contact person details
                        </label>
                      </div>
                    </div>

                    {!values.useOwnDetails && (
                      <>
                        <div className="form-group">
                          <label>
                            Name of the Service Seeker Contact Person *
                          </label>
                          <input
                            name="serviceSeekerContactPerson"
                            placeholder="Enter Service Seeker Contact Person"
                            onChange={handleChange}
                            value={values.serviceSeekerContactPerson}
                            className={`form-control ${
                              touched.serviceSeekerContactPerson &&
                              errors.serviceSeekerContactPerson
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="serviceSeekerContactPerson"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label>Service Seeker Designation *</label>
                          <input
                            name="serviceSeekerDesignation"
                            placeholder="Enter Service Seeker Designation"
                            onChange={handleChange}
                            value={values.serviceSeekerDesignation}
                            className={`form-control ${
                              touched.serviceSeekerDesignation &&
                              errors.serviceSeekerDesignation
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="serviceSeekerDesignation"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label>Service Seeker Organization *</label>
                          <input
                            name="serviceSeekerOrganization"
                            placeholder="Enter Service Seeker Organization"
                            onChange={handleChange}
                            value={values.serviceSeekerOrganization}
                            className={`form-control ${
                              touched.serviceSeekerOrganization &&
                              errors.serviceSeekerOrganization
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="serviceSeekerOrganization"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label>Service Seeker Email *</label>
                          <input
                            type="email"
                            name="serviceSeekerEmail"
                            placeholder="Enter Service Seeker Email"
                            onChange={handleChange}
                            value={values.serviceSeekerEmail}
                            className={`form-control ${
                              touched.serviceSeekerEmail &&
                              errors.serviceSeekerEmail
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="serviceSeekerEmail"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label>Service Seeker Contact Number *</label>
                          <input
                            name="serviceSeekerContactNumber"
                            placeholder="Enter Service Seeker Contact Number"
                            onChange={handleChange}
                            value={values.serviceSeekerContactNumber}
                            className={`form-control ${
                              touched.serviceSeekerContactNumber &&
                              errors.serviceSeekerContactNumber
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="serviceSeekerContactNumber"
                            className="invalid-feedback"
                          />
                        </div>
                      </>
                    )}
                    <div
                      className="form-group"
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      <div>
                        {/* <label>Budget *</label> */}
                        <input
                          type="checkbox"
                          name="sendEmail"
                          // placeholder="Enter budget"
                          value={values.sendEmail}
                          onChange={handleChange}
                          style={{ marginInlineEnd: 15 }}
                          // className={`form-control ${
                          //   touched.autoGeneratedEmail && errors.autoGeneratedEmail
                          //     ? "is-invalid"
                          //     : ""
                          // }`}
                        />
                        <label>
                          {" "}
                          Do you want to send auto emails to all the service
                          providers in the category
                        </label>
                      </div>
                    </div>

                    {/* <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field
                      type="password"
                      name="password"
                      placeholder="Enter password"
                      className={`form-control ${
                        touched.password && errors.password ? "is-invalid" : ""
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback"
                    />
                  </div> */}

                    <button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Please wait..." : "Submit"}
                    </button>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    );
  }
  return null;
};

const CreatePostWork = (props) => {
  const location = useLocation();
  let postType = location.pathname.split("post-work/create/");
  let selectedPostType = "";
  if (Array.isArray(postType) && postType.length > 1) {
    selectedPostType = postType[1];
    const adminToken = localStorage.getItem("user");
    if (adminToken) {
      return PostWork(CreatePostTypeDetail, {
        postType: "create/ " + selectedPostType,
        ...props,
      });
    } else {
      return <Redirect to="/post-work" />;
    }
  }
  return <PostTypeOptions />;
};

const mapStateToProps = (state) => {
  // console.log("state === ", state);
  return {
    serviceSeeker: state.serviceSeeker,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceSeekerAction: () => dispatch(getServiceSeeker()),
    // getIndividualAction: (item) => dispatch(getIndividual(item))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePostWork);
