import React,{Component, useEffect} from 'react';
import {Nav,Navbar,FormControl,Button,Form,Carousel} from 'react-bootstrap'

import { NavLink, withRouter, Link } from "react-router-dom";
import HeaderApp from '../Header/Header';
import Myriad1 from "../../images/Idea_1A.png"
import Affordable from "../../images/5490520A.png"
import m15 from "../../images/bg15.jpg"
import Distinctly from "../../images/Mar-Business_6a.png"
import ipad from "../../images/ipad.png"
import Game from "../../images/66711A.png"
import backimage from "../../images/backimage3.png"
import laptop from "../../images/laptop.png"
import backimagee from "../../images/backimage2.png"
import FooterApp from '../Footer/Footer';
import slider1 from '../../images/Win1.png';
import slider2 from '../../images/4836346A.png';
import newimage from '../../images/6685A.png';
import companylogo1 from '../../images/company_slider/MooreShekhaMufti.jpg';
import companylogo2 from '../../images/company_slider/CroweHussainChaudhuryCo.jpg';
import companylogo3 from '../../images/company_slider/MavenMinds.jpg';
import companylogo4 from '../../images/company_slider/MianCo.jpg';
import companylogo5 from '../../images/company_slider/GlobalMatrixConsultants_pvt_ltd.jpg';
import MetaTags from 'react-meta-tags';
import LogoCarousel from "react-elastic-carousel";


const Home = () => {
    useEffect(() => () => {
        try {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } catch (error) {
          window.scrollTo(0, 0);
        }
      }, []);

const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 1},
    { width: 650, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1440, itemsToShow: 5, itemsToScroll: 2 },
];

return(

    <div>
        <MetaTags>
            <title>Pakistan's Pioneer Portal for Accounting and Financial Outsourcing Solutions | APEXWAY</title>
            <meta name="description" content="APEXWAY marketplace provides access to the best accounting bookkeeping firms in Pakistan. APEXWAY is the brainchild of an experienced member of ICAP." />
            <meta name="robots" content="noodp,noydir" />
        </MetaTags>
                <div
                    ids="iq-home"
                    className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}
                >
                        <div className="container-fluid">
                            <div className="banner-text">
                            <Carousel>
                            <Carousel.Item>
                                <div className="row">
                                    <div className="headings-sldier col-lg-6">
                                        <h1 id="formedia-quries" className="htext text-uppercase iq-font-white iq-tw-3">
                                            WIN BIG WITH APEXWAY
                                        </h1>
                                        <p id="formedia-quries"  className="subtext iq-font-white iq-pt-15 iq-mb-40 pl-2">
                                            Winning new clients is crucial for business growth. We help you grow your business through APEX WAY and help you retain the customers for life!
                                            <br />
                                            <Link className="button" to="/how-it-works">Join Now</Link>
                                        </p>
                                    </div>  
                                    <div className="col-lg-6">
                                        <img loading="lazy" className="banner-img" src={slider1} alt="drive"/>
                                    </div>
                                </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                <div className="row">
                                    <div className="headings-sldier col-lg-6">
                                        <h1 id="formedia-quries"  className="htext text-uppercase iq-font-white iq-tw-3">
                                            YOUR GROWTH IS OUR GOAL
                                        </h1>
                                        <p id="formedia-quries"  className="subtext iq-font-white iq-pt-15 iq-mb-40 pl-2">
                                            Join Pakistan's Pioneer Portal of Commerce Professionals and Knock on the Door of New Opportunities
                                            <br />
                                            <Link className="button" to="/how-it-works">Join Now</Link>
                                        </p>
                                    </div>
                                    <div className="col-lg-6">
                                        <img loading="lazy" className="banner-img" src={slider2} alt="drive" />
                                    </div>
                                </div>
                                </Carousel.Item>
                         </Carousel>
                            </div>
                         </div>
                        
                            <div className="banner-objects">
                                <span className="banner-objects-02 iq-fadebounce">
                                    <span className="iq-round" />
                                </span>
                                <span className="banner-objects-03 iq-fadebounce">
                                    <span className="iq-round" />
                                </span>
                            </div>
                    </div>
                <section id="how-works-two" className="overview-block-ptb how-works">
                    <div className="container company-slider-custom">
                        <div className="heading-title">
                            <h3 className="title iq-tw-7">Trusted by</h3>
                            <p className="iq-font">Leading Professional Firms in Financial Services</p>
                        </div>
                        <LogoCarousel
                            // ref={ref => (carousel = ref)}
                            breakPoints={breakPoints}
                            itemPadding={[2, 10]}
                            enableAutoPlay={true}
                            pagination={false}
                            showEmptySlots
                            autoPlaySpeed={5000}
                        >
                            <div>
                                <a target="_blank" href="https://mooreshekhamufti.com/"><img src={companylogo1} alt=""/></a>
                            </div>
                            <div>
                                <a target="_blank" href="https://www.crowe.com/pk"><img src={companylogo2} alt=""/></a>
                            </div>
                            <div>
                                <a target="_blank" href="https://mavenminds.com.pk/"><img src={companylogo3} alt=""/></a>
                            </div>
                            <div>
                                <a target="_blank" href="https://miansco.com/"><img src={companylogo4} alt=""/></a>
                            </div>
                            <div>
                                <a target="_blank" href="https://gmcl.pk"><img src={companylogo5} alt=""/></a>
                            </div>
                        </LogoCarousel>
                    </div>
                </section>
                <section id="how-works-section1" className="how-works overview-block-ptb custom-section-bg">
                    <div className="container">
                        <div className="row flex-row-reverse align-items-center">
                            <div className="col-lg-7">
                                <img  loading="lazy"className="iq-works-img pull-right img-fluid"
                                     src={Game} alt="drive01" />
                            </div>
                            <div className="col-lg-5 align-self-center">
                                {/* <span className="badge iq-badge blue-bg iq-font-white">Primary new</span> */}
                                <h3 className="iq-tw-7 iq-mb-15">Game Changer for Service Providers</h3>
                                <p>
                                    The demand for outsourcing financial sector services is rapidly growing. However, professional firms, specially small accounting firms, often face hurdles due to the absence of a platform and a market place to gain access to new client opportunities.
                                </p>
                                <p>
                                    Professional firms often rely on business references to gain business in the market and therefore miss out huge opportunities due to lack of connections.
                                </p>
                                <p>
                                    Winning new customers without any business references would bring pivotal change for professional firms, providing auditing, corporate finance and accounting, taxation and other services.
                                </p>
                                <p>
                                    APEXWAY is first of its kind and specially designed digital portal, that surely is a game changer for practicing commerce firms.
                                </p>
                            </div>
                            <div className="iq-objectsnew">
                                <span className="iq-objects-02" data-bottom="transform:translatey(50px)"
                                    data-top="transform:translatey(-100px);">
                                    <img loading="lazy" src={backimage} className="img-fluid" alt="drive02"/>
                                </span>
                                <span className="iq-objects-03" data-bottom="transform:translatex(50px)"
                                      data-top="transform:translatex(-100px);">
                                    <img loading="lazy" src={backimagee} className="img-fluid" alt="drive02"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="how-works-two" className="overview-block-ptb how-works ">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6">
                                <img loading="lazy" className="homeimg iq-works-img pull-right img-fluid"
                                     src={Myriad1} alt="drive01" />
                            </div>
                            <div className="col-lg-6">
                                <h3 className="iq-tw-7 iq-mb-15">Myriad Benefits for Service Seekers</h3>
                                <p>
                                    As organizations seek more effective ways of working, they realize that  handing over finance functions to area specialists is the only choice in modern business world and a common practice. However,  more often they face huge challenges in finding the suitable professional firm.
                                </p>
                                <p>
                                    APEXWAY  portal offers immense flexibility and frees you from the worries of searching for the most affordable and suitable professional service providers in following sectors of Corporate Finance.
                                </p>
                                <ul class="home-how-it-works-list list-unstyled">
                                    <li>Accounting</li>
                                    <li>Audit</li>
                                    <li>Taxation</li>
                                    <li>Legal</li>
                                </ul>
                                <p>
                                    APEXWAY digital portal provides you with smart and easy search and post options of your required services, whether related to accounting or other business process outsourcing services
                                </p>
                            </div>

                            <div className='iq-objects-software'>
                                <span className="iq-objects-01" data-bottom="transform:translatey(50px)"
                                      data-top="transform:translatey(-100px);">
                                  <img loading="lazy" src={backimagee} alt="drive02"/>
                                </span>
                                <span className="iq-objects-02" data-bottom="transform:translatex(50px)"
                                      data-top="transform:translatex(-100px);">
                                    <img loading="lazy" src={backimage} alt="drive02"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="how-works-section" className="how-works overview-block-ptb custom-section-bg">
                    <div className="container">
                        <div className="row flex-row-reverse align-items-center">
                            <div className="col-lg-7">
                                <img loading="lazy" className="iq-works-img pull-right img-fluid"
                                     src={Affordable} alt="drive01" />
                            </div>
                            <div className="col-lg-5 align-self-center">
                                <h3 className="iq-tw-7 iq-mb-15">Affordable Growth Opportunity</h3>
                                <p>
                                    Whether you are a established professional firm providing commerce and finance related service or a small accounting firm, there is intense completion, entry barriers and related obstacles set by the leading market players
                                </p>
                                <p>
                                    At APEXWAY, we recognize and markedly value your diversified portfolio of professional services in Finance Sector.
                                </p>
                                <p>
                                    This recognition, in line with our aim of your business growth through diversification, is exquisitely reflected in our affordable step down pricing plans.
                                </p>
                            </div>
                            <div className="iq-objectsnew">
                                <span className="iq-objects-02" data-bottom="transform:translatey(50px)"
                                    data-top="transform:translatey(-100px);">
                                    <img loading="lazy" src={backimage} className="img-fluid" alt="drive02"/>
                                </span>
                                <span className="iq-objects-03" data-bottom="transform:translatex(50px)"
                                      data-top="transform:translatex(-100px);">
                                    <img loading="lazy" src={backimagee} className="img-fluid" alt="drive02"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="how-works-two-4" className="overview-block-ptb how-works ">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-7">
                                <img loading="lazy" className="iq-works-img pull-right img-fluid"
                                     src={Distinctly} alt="drive01" />
                            </div>
                            <div className="col-lg-5 align-self-center">
                                <h3 className="iq-tw-7 iq-mb-15">Distinctly Effective and Simple</h3>
                                <p>
                                    The simplicity of APEXWAY portal enhances its effectiveness manifold. The entire process, from the application for membership to registration and project posting, is extremely simple and easy to understand.
                                </p>
                            </div>
                            <div className='iq-objects-software'>
                                <span className="iq-objects-01" data-bottom="transform:translatey(50px)"
                                      data-top="transform:translatey(-100px);">
                                  <img loading="lazy" src={backimagee} alt="drive02"/>
                                </span>
                                <span className="iq-objects-02" data-bottom="transform:translatex(50px)"
                                      data-top="transform:translatex(-100px);">
                                    <img loading="lazy" src={backimage} alt="drive02"/>
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
                <section ids="counter" id="Aboutus" className="iq-ptb-100 iq-counter-box iq-bg-over iq-over-blue-90 iq-font-white iq-parallax jarallax">
                    <div className="container">
                        <div className="col-sm-12">
                            <div className="heading-title white">
                                <h3 className="title iq-tw-7">Reach your peak</h3>
                                <p className="iq-font-white">Our mission is to empower you to grow and achieve more</p>
                            </div>
                        </div>
                        <div className="counter">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <h3 className="iq-font-white">About APEXWAY</h3>
                                    <h5 className="iq-font-white">Empowering you to reach new peaks!</h5>
                                    <p className="iq-mt-30">APEXWAY portal is the brainchild of an experienced member of the Institute of Chartered Accountants of Pakistan. Our vision is to provide technology based solutions to empower professional service providers practicing in the field of accounting and finance. Our collaboration with an evolving IT company - Global Spectrum, has further strengthened way of expansion and our footprint in the market.</p>
                                </div>
                                <div className="col-lg-6">
                                    <div className="counter-info-img">
                                        <img loading="lazy" className="center-block" src={newimage} alt="ipad" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    <FooterApp/>
</div>



)



}

export default Home;