import {
  LOADING_STATE,
  SERVICE_PROVIDER_LOGIN,
  GET_REVIEWS_SERVICE_PROVIDERS,
} from "../../Constants/constants";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "../../utils";
import { authCheck } from "./../../components/auth";
import { Alert } from "react-bootstrap";

export const ServiceProviderLogin = (data, loading) => (dispatch) => {
  dispatch({
    type: LOADING_STATE,
    payload: loading,
  });

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/service-provider/login`,
      data
    )
    .then(function (response) {
      // console.log(response);
      toast.success("LOGGED IN SUCCESFULLY", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: SERVICE_PROVIDER_LOGIN,
        payload: response.data,
      });

      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });

      localStorage.setItem("user", JSON.stringify(response));

      if (authCheck()) {
        window.location.replace("/service-provider-profile");
      }
    })
    .catch(function (err) {
      // console.log("error");
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
    });
};

export const resetPassword = (data, loading) => (dispatch) => {

  dispatch({
    type: LOADING_STATE,
    payload: loading,
  });

  let payload = {
    email: data,
  };

  const dataResponse = axios
    .post(
      `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/reset/email`,
      payload
    )
    .then(function (response) {
      console.log(response,"reset pass response");
      if(response?.data?.success){
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          hideProgressBar:false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      else{
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          hideProgressBar:false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      
      // toast(AlertDismissibleExample(), {
      //   position: "top-center",
      //   autoClose: 80000,
      //   className: 'toast-container',
      //   hideProgressBar: true,
      //   closeOnClick: false,
      //   draggable: true,
      //   progress: undefined,
      // });
      // dispatch({
      //   type: SERVICE_PROVIDER_LOGIN,
      //   payload: response.data,
      // });

      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });

      // localStorage.setItem("user", JSON.stringify(response));

      // if (authCheck()) {
      //   window.location.replace("/service-provider-profile");
      // }
    })
    .catch(function (err) {
      // console.log("error");
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
    });
};

export const sendPassword = (data, loading) => (dispatch) => {
  dispatch({
    type: LOADING_STATE,
    payload: loading,
  });

  console.log("data", data);

  const dataResponse = axios
    .post(`${process.env.REACT_APP_HOST_URL}/api/v1/apexway/reset/change`, data)
    .then(function (response) {
      // console.log(response);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // dispatch({
      //   type: SERVICE_PROVIDER_LOGIN,
      //   payload: response.data,
      // });

      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });

      // localStorage.setItem("user", JSON.stringify(response));

      // if (authCheck()) {
      //   window.location.replace("/service-provider-profile");
      // }
    })
    .catch(function (err) {
      console.log("error");
      toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch({
        type: LOADING_STATE,
        payload: !loading,
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
    });
};

export const getReviewsServiceprovider = () => (dispatch) => {
  const adminToken = localStorage.getItem("user");
  const token = JSON.parse(adminToken)?.data?.token;
  const reference_id = JSON.parse(adminToken)?.data?.reference_id;

  let userType = JSON.parse(localStorage.getItem("user")).data.userType;

  const dataResponse = axios
    .get(`${process.env.REACT_APP_HOST_URL}/api/v1/apexway/review/providers`, {
      params: {
        serviceProvider: reference_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(function (response) {
      // console.log("response get", response);
      // toast.success(response?.data?.msg, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      dispatch({
        type: GET_REVIEWS_SERVICE_PROVIDERS,
        payload: response.data.data,
      });
    })
    .catch(function (err) {
      // toast.error(err?.response?.data?.msg || err?.response?.data?.message, {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
      dispatch({
        type: GET_REVIEWS_SERVICE_PROVIDERS,
        payload: [],
      });
      if (
        err?.response?.data?.error &&
        (err?.response?.data?.msg
          ?.toLowerCase()
          ?.includes("token is not valid") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("token is not valid") ||
          err?.response?.data?.msg
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action") ||
          err?.response?.data?.message
            ?.toLowerCase()
            ?.includes("you are not authorized to perform this action"))
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      console.log("error arrived", err);
    });
};
