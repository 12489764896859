import {
  GET_POST_WORK,
  LOADING_STATE,
  CREATE_REVIEW,
  DELETE_POST_WORK,
  DELETING_POST,
  COMPLETE_POST_WORK,
  DISCONTINUE_POST_WORK,
  SELECT_POST,
  UNSELECT_POST,
} from "../Constants/constants";

const initialState = {
  posts: [],
  reviews: [],
  allSelectedPosts: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_POST_WORK:
      return {
        ...state,
        posts: action.payload,
      };

    case SELECT_POST:
      return {
        ...state,
        allSelectedPosts: [...state.allSelectedPosts, action.payload],
      };

    case UNSELECT_POST:
      let afterUnselectPost = state.posts.filter((item) => {
        if (item.reference_id === action.payload) {
          return item.reference_id;
        }
      });
      return {
        ...state,
        allSelectedPosts: [...afterUnselectPost],
      };

    case DELETE_POST_WORK:
      let filteredPostsAfterDeletion = state.posts.filter(
        (item) => action.payload?._id && item._id !== action.payload?._id
      );
      return {
        ...state,
        posts: [...filteredPostsAfterDeletion],
      };

    case COMPLETE_POST_WORK:
      let filteredPostsAfterCompletion = state.posts.map((item) => {
        if (action.payload?._id && item._id === action.payload?._id) {
          return action.payload;
        } else {
          return item;
        }
      });
      return {
        ...state,
        posts: [...filteredPostsAfterCompletion],
      };

    case DISCONTINUE_POST_WORK:
      let filteredPostsAfterDisContinuation = state.posts.map((item) => {
        if (action.payload?._id && item._id === action.payload?._id) {
          return { ...action.payload };
        } else {
          return { ...item };
        }
      });
      return {
        ...state,
        posts: [...filteredPostsAfterDisContinuation],
      };

    case CREATE_REVIEW:
      return {
        ...state,
        reviews: action.payload,
      };

    case LOADING_STATE:
      return {
        ...state,
        loading: action.payload,
      };

    default:
      return state;
  }
}
