import React,{Component, useState} from 'react';
import {Nav,Navbar,FormControl,Button,Form, Spinner,Card,Alert} from 'react-bootstrap'
import {Modal, ModalHeader, ModalBody, ModalFooter,Badge} from 'reactstrap';

import { NavLink, withRouter } from "react-router-dom";
import FooterApp from '../Footer/Footer';
import HeaderApp from '../Header/Header';
import {resetPassword} from "../../Actions/serviceProvider/ServiceProviderLogin"
import { connect } from "react-redux";



const ForgotPassord = (props) => {

    const  [email,setEmail] = useState("");
    const [modal2, setModal2] = useState(false);
    const modalTogglee = () => setModal2(!modal2);


    const getEmail = (e)=>{
        setEmail(e.target.value)
    }


    const sendForgotpassword = async (e)=> {
      e.preventDefault()
      if(email != ""){
        await props.resetPasswordAction(email,true);
        await setEmail('');
        await modalTogglee();
      }
    }

return(

<div> 
      <div>
        <Modal size="lg" isOpen={modal2} toggle={modalTogglee} className="success-msg">
          <ModalHeader toggle={modalTogglee}></ModalHeader>
          <ModalBody toggle={modalTogglee} className="m-5 pb-3">
          If your account exists, a password reset link was sent to your registered email address.<br></br><br></br>
          If you do not receive an email, it may be because you are suspended, your account is not activated, you have requested multiple password resets within a short time frame, or you are not allowed to change your password.<br></br><br></br>
          Contact site administrator if any of those reasons apply. Also, please check your spam filter.<br></br><br></br>
          <h3>The APEXWAY Team</h3><br></br>
          <div className="forgotpagebadge">
          {props.serviceProvider.loading ?<Spinner animation="border" variant="dark" /> : "" }
          </div>
          </ModalBody>
        </Modal>
      </div>
    <div>
          <div ids="iq-home" className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}>
              <div className="container-fluid">
                  <div className="col-lg-12">
                      <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                      Forgot your Password <br></br>
                      </h1>
                  </div>
              </div>
          </div>
      </div>
   
   <div style={{padding: "50px"}}>
       <Card style={{padding: "50px"}}>
       <p>Enter Your email address for password recovery</p>

   <Form onSubmit={sendForgotpassword}>
    <Form.Group controlId="formBasicEmail">

        <Form.Label>Email address</Form.Label>
        <Form.Control onChange={getEmail} type="email" placeholder="Enter email" value={email} required/>
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
    </Form.Group>


    <Button variant="primary" className="button bt-blue" type="submit">
      {props.serviceProvider.loading ?   <Spinner animation="border" variant="light" /> : "Submit" }
    </Button>
</Form>
</Card>
</div>

<FooterApp/>

</div>
)



}







const mapStateToProps = (state) => {
    return { 
      serviceProvider: state.serviceProvider 
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return { 
      resetPasswordAction: (values,loadingState) => dispatch(resetPassword(values,loadingState))
     }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ForgotPassord)