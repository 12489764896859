
import React,{Component,useEffect} from 'react';
import {Nav,Navbar,FormControl,Button,Form} from 'react-bootstrap'

import { NavLink, withRouter } from "react-router-dom";
import FooterApp from '../Footer/Footer';
import HeaderApp from '../Header/Header';
import img4 from '../../images/4.jpg';
import img5 from '../../images/5.jpg';
import img6 from '../../images/6.jpg';


const AboutPortal = () => {
    useEffect(() => () => { // <-- Now we return the useEffect teardown effect, which will be fired only after the path/search change for the first time
        try {
          // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } catch (error) {
          // just a fallback for older browsers
          window.scrollTo(0, 0);
        }
      }, []);

return(
<div> 

<div >
<HeaderApp/>
</div>


{/* <section class="inner-page-banner">
<div class="page-heading text-center">
    <h2>What is Apexway?</h2>
    <span class="section_1-breadcrumbs"><a href="Home.aspx"><i class="fa fa-home home_1"></i> Home</a></span>
</div>
</section> */}
<div>
                <div
                    ids="iq-home"
                    className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}
                >
                <div className="container-fluid">
                <div className="col-lg-12">
                                    <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                                    What is Apexway?<br></br>
                                    <span class="section_1-breadcrumbs"><a href="Home.aspx"><i class="fa fa-home home_1"></i> Home</a></span>
                                    </h1>
                                    
                                </div>
                    </div>
                </div>
                </div>




<section class="features py-5">

<div class="container py-sm-3">
<div class="testimonals">
    <div class="container">
        <div class="section_1its-feedback-grids">
            <div id="owl-demo" class="owl-carousel owl-theme">
                <div class="item">
                    <div class="testimonal grid-1">
                        <div class="first-grid">
                            <img src={img5} class="image-size" alt=""/>
                            <h3 >We bring customers and service providers together!</h3>
                            <p>Apexway is connecting linking between service seekers and professional practicing firms. Our carefully designed screening and registration criteria for service providers ensure that the service seekers get premium quality services from the selected field experts</p>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="testimonal grid-1">
                        <div class="first-grid">
                            <img src={img4} class="image-size" alt=""/>
                            <h3 >Key features for service providers</h3>
                            <p>The portal provides superb opportunity to the service providers to win new customers without any business references. So winning new customers would be much easier than never before. Not only this, our affordable subscription packages allow quick organic growth and expansion to diversified business areas.</p>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="testimonal grid-1">
                        <div class="first-grid">
                            <img src={img6} class="image-size" alt=""/>
                            <h3 >Key features for service seekers</h3>
                            <p>This portal offers immense flexibility and frees service providers from the worries of searching for the most affordable and suitable professional service providers. Smart and easy search and post options also facilitate efficient and hassle free engagement processing experience</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

{/* <script>
$(document).ready(function () {
    $("#li-how-it-works").removeClass('active');
    $("#li-home").removeClass('active');
    $("#li-packages").removeClass('active');
    $("#li-post-work").removeClass('active');
    $("#li-about").addClass('active');
    $("#li-contact").removeClass('active');

    $("#owl-demo").owlCarousel({

        autoPlay: 3000, //Set AutoPlay to 3 seconds
        autoPlay: true,
        items: 2,
        itemsDesktop: [640, 5],
        itemsDesktopSmall: [414, 4]
    });

});
</script> */}


</section>


<FooterApp/>

</div>
)



}

export default AboutPortal;

