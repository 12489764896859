import React,{Component} from 'react';
import { NavLink, withRouter } from "react-router-dom";
import {Container} from 'react-bootstrap'
import logo1 from "./../../images/apexway.png"
import icon from '../../images/whatsapp.svg';


const FooterApp = () => {

return(
    <footer className="iq-footerr footer-custom iq-pt-20">
        <Container>
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                    <a className="navbar-brand" href="/home">
                        <img id="footer-img" className="img-fluid"  src={ logo1 } alt="Modal Image" /><br></br>
                        <p className="frailway sloganb mb-2">We Help Your Business <br></br>Grow In a Changing World!</p>
                    </a>
                    <div class="footerv2-inner custom-social-color mt-0">
                        <ul class="social-iconsv2 footer-social ">
                            <li>
                                <a target="_blank" href="https://www.facebook.com/Apexwaysolutions">
                                    <i class="fab fa-facebook-f"></i>
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.linkedin.com/company/apex-way-solutions">
                                    <i class="fab fa-linkedin-in"></i>
                                </a>
                            </li>
                            {/* <li>
                                <div>
                                    <p className="coming-soon">Coming Soon</p>
                                </div>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fab fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#">
                                    <i class="fab fa-google-plus-g"></i>
                                </a>
                            </li> */}
                        </ul>
                    </div>
                </div>
                {/* <div className="col-lg-1 d-none d-lg-block"></div> */}
                <div className="col-lg-4 col-md-6 col-sm-12 iq-mtb-20">
                    <h4 className="iq-tw-7 iq-pb-10 ml-3">Contact</h4>
                    <div class="fv3-contact">
                        <span class="far fa-envelope"> </span>
                        <p className='frailway margintest'>
                            <a target="_blank" href="mailto:example@email.com">info@apex-way.com</a>
                        </p>
                    </div>
                    <div class="fv3-contact">
                        <span className="fab fa-whatsapp"> </span>
                        <p className='frailway margintest'>
                            <a target="_blank" href="https://wa.me/923458228575">923458228575</a>
                        </p>
                    </div>
                    <div class="fv3-contact">
                        <span class="fas fa-map-marker-alt"> </span>  
                        <p className='frailway margintest'>
                            <a target="_blank" href="#">A-101, Block H, Hyderi, Karachi,<br/>Pakistan</a>
                        </p>
                    </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 iq-mtb-20">
                    <h4 className="iq-tw-7 iq-pb-10">Menu</h4>
                    <ul id="forhover" className="frailway list-navigation" style={{textAlign: "left"}}>
                        <li>
                            <NavLink   to="/home">Home</NavLink>
                        </li>
                        <li class="">
                            <NavLink  to="/About-us"> About us </NavLink>
                        </li>
                        <li>
                            <NavLink   to="/Contact"> Contact </NavLink>
                        </li>
                        <li class="">
                            <NavLink   to="/post-work">Post Work</NavLink>
                        </li>
                        <li class="">
                            <NavLink   to="/how-it-works">How It Works</NavLink>
                        </li>
                    </ul>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-12 iq-mtb-20">
                    <ul id="forhover" className="list-navigation" style={{textAlign: "left"}}>
                        <h4 className="iq-tw-7 iq-pb-10">Packages</h4>
                        <li class="frailway my-2">
                            <NavLink  to="/service-seeker-packages"> Service Seeker </NavLink>
                        </li>
                        <li class="frailway my-2">
                                <NavLink  to="/service-provider-packages"> Service Provider </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </Container>
        <div class="cpy-right">
            <div>
                <p>© 2021 IMI APEXWAY SOLUTIONS (SMC-PRIVATE) LIMITED.  All rights reserved. Developed by <a href='https://www.chaynztech.com/'>ChaynzTech</a></p>
            </div>
            <div class="footerv2-inner">
                <ul class="social-iconsv2 footer-social ">
                    <li>
                        <a target="_blank" href="https://www.facebook.com/Apexwaysolutions">
                            <i class="fab fa-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.linkedin.com/company/apex-way-solutions">
                            <i class="fab fa-linkedin-in"></i>
                        </a>
                    </li>
                    {/* <li>
                        <a href="#">
                            <i class="fab fa-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i class="fab fa-google-plus-g"></i>
                        </a>
                    </li>
                    <li>
                        <div>
                            <p className="coming-soon">Coming Soon</p>
                        </div>
                    </li> */}
                </ul>
            </div>
        </div>
    </footer>
)



}

export default FooterApp;
