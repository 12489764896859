import React, { Component, useState, useEffect } from "react";
import {
  Nav,
  Navbar,
  FormControl,
  Alert,
  Button,
  Form,
  NavDropdown,
  Modal,
  Container,
  ProgressBar,
  Row,
  Col,
  Card,
  Table,
  ListGroup,
  Tabs,
  Tab,
  Badge,
  ListGroupItem,
} from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";
import profile from "../../images/profile.png";
import moment from "moment";
import { connect } from "react-redux";
import { authCheck } from "./../auth";
import { logout } from "../../Actions/global/global";
import {
  getServiceSeeker,
  updateServiceSeeker,
  getServiceProviders,
  getReviews,
} from "../../Actions/serviceSeeker/individualLogin";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getPostWork } from "../../Actions/post/PostWork";
import './custom.css';
import star from '../../images/star.svg';
import emptystar from '../../images/estar.svg';
import { startsWith } from "lodash";
import MetaTags from 'react-meta-tags';

const ServiceSeekerProfile = (props) => {
  const [editable, setEditable] = useState(false);
  const [user, setUser] = useState({});
  

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [serviceProvider, setServiceProvider] = useState({});
  const [serviceProviderModal, setserviceProviderModal] = useState(false);
  const handleSpModal = () => setserviceProviderModal(!serviceProviderModal);
  const [reviewsList, setReviews] = useState([]);
  const [numbers, setnumbers] = useState([]);
  const [search, setsearch] = useState("");
  let userType = JSON.parse(localStorage.getItem("user")).data.userType;

  useEffect(async () => {
    await props.getServiceProvidersAction();
    if (await authCheck()) {
      props.history.push("/service-seeker-profile");
    }

    await props.getServiceSeekerAction();
    await setUser(props.serviceSeeker);
  }, []);

  const getProviders = async () => {
    // console.log("asdasd");
    await props.getServiceProvidersAction();
  };

  const getReviews = async (id) => {
    await props.getReviewsAction(id);
    // console.log("props review", props.reviews);
    setReviews(props.reviews);
  };

  const getServiceProviderDetail = async (item) => {
    setServiceProvider(item);
    getReviews(item.reference_id);
    handleSpModal();
  };
  

  // console.log("popz", props);
  const formik = useFormik({
    initialValues: {
      contact_address_comm: props?.serviceSeeker?.contact_address_comm,
      contact_address_permanent:props?.serviceSeeker?.contact_address_permanent,
      contact_number1: props?.serviceSeeker?.contact_number1,
      currently_working_as: props?.serviceSeeker?.currently_working_as,
      contact_number2: props?.serviceSeeker?.contact_number2,
      designation: props?.serviceSeeker?.designation,
      email: props?.serviceSeeker?.email,
      name_of_member: props?.serviceSeeker?.name_of_member,
      name: props?.serviceSeeker?.name,
      organisation: props?.serviceSeeker?.organisation,
      website: props?.serviceSeeker?.website,
      contact_number1:props?.serviceSeeker?.contact_number1,
      contact_number2:props?.serviceSeeker?.contact_number2,
      address1:props?.serviceSeeker?.address1,
      address2:props?.serviceSeeker?.address2,
      member_type:props?.serviceSeeker?.member_type,
      member_number:props?.serviceSeeker?.member_number,
      member_id:props?.serviceSeeker?.member_id,
      official_email:props?.serviceSeeker?.official_email,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      // email: Yup.string().email('Invalid email address').required('Required'),
      // password: Yup.string()
      // .max(105, 'Must be 105 characters or less')
      // .required('Required'),
    }),
    onSubmit: async (values) => {
      const {
        contact_address_comm,
        official_email,
        contact_address_permanent,
        name,
        address1,
        email,
        organisation,
        name_of_member,
        membership_number,
        website,
        designation,
        currently_working_as,
        member_type,
        member_number,
      } = values;
      delete values["name"];
      // console.log("values", values);
      const { updateServiceSeekerAction } = props;
      await updateServiceSeekerAction(values, true);
    },
  });

var filteredArray = props?.serviceProviders?.filter(element => element.area_of_specialization?.some(subElement => subElement === search)
)
.map(element => {
  let n = Object.assign({}, element, {'subElements': element?.subElements?.filter(
    subElement => subElement === search
  )})
  return n;
})

// console.log(filteredArray)



  const logoutUser = async () => {
    // await props.logoutUserAction();
    localStorage.clear();
    window.location.reload();
  };

  function renderStar(len) {
    const temp = [];
   for (let i=0; i < len; i++) {
    temp.push(i);
  } 
  return  temp.map((val) => <img src={star} alt="as" className="star"/>); 
 };

 function emptyStar(len) {
  const temp = [];
 for (let i=0; i < 10-len; i++) {
  temp.push(i);
} 
return  temp.map((val) => <img src={emptystar} alt="as" className="star"/>); 
};
  // console.log("sp", serviceProvider);
  // console.log("revies", props.reviews);

  // const {cnic,active,approve,contact_address_comm,contact_address_permanent,contact_number1,contact_number2,created_at,member_dropdown, designation,member_since,website,suspended} = props.serviceSeeker;

  return (
    <div class="bgcoolors" style={{ marginTop: "50px", }}>
    <MetaTags>
      <title>Service Seeker Profile | Apexway</title>
    </MetaTags>
      <Container>
      <div className="margintop">
      <span class="text-uppercase page-subtitle">OverView</span>
        <h3  class="page-title">Service Seeker Profile</h3>
      </div>
           <Row>
          <Col lg={4} style={{ marginTop: "0.8em" }}>
            <Card className="shadow">
              <Card.Body>
              


                <ListGroup variant="flush">
                  <ListGroup.Item>
                  <span className="cardname">
                  {" "}
                    {(props?.serviceSeeker?.name !=="") ? props?.serviceSeeker?.name : "-" }{" "}</span><br></br>
                    <Badge variant="info">
                    
                  </Badge>
                  
                  {/* {" "}
                    {props?.serviceSeeker?.member_dropdown}{" "} */}

                    { userType === "individual"
                      ? [
                          (props?.serviceSeeker?.member_dropdown === "Individual(M)"
                            ? <Button variant="primary" className="membertypediv" size="sm" disabled>Individual(Member)
                                </Button>
                            : <Button variant="primary" className="membertypediv" size="sm" disabled>Individual(Non-Member)
                                </Button>
                          )
                        ]
                      : [
                          (props?.serviceSeeker?.is_member === true
                            ? <Button variant="primary" className="membertypediv" size="sm" disabled>Company(Member)
                                </Button>
                            : <Button variant="primary" className="membertypediv" size="sm" disabled>Company/Org(Without-Member)
                                </Button>
                          )
                        ]
                    }
                  

                  </ListGroup.Item>
                 
                  <ListGroup.Item>
                  {" "}
                    {props.serviceSeeker?.email}{" "}
                  </ListGroup.Item>
                  <ListGroup.Item>
                  {" "}
                    {props?.serviceProvider?.memberships}
                  </ListGroup.Item>
                </ListGroup>
                {/* <Button className="btn-update" onClick={handleShow}></Button> */}
                <button id="btnbtn" onClick={handleShow} className="button bt-blue">Update
                 </button>
                 
                <Modal size="lg" show={serviceProviderModal} onHide={handleSpModal}>
                <Modal.Header style={{ padding: "20px" }} closeButton>
                <h6>
                  Service Provider Details/Reviews
                </h6>
                </Modal.Header>
                <Card className="shadow" style={{ padding: "20px" }}>
                <Tabs defaultActiveKey="details" id="uncontrolled-tab-example" className="mb-3" closeButton>
                <Tab eventKey="details" title="Details">
                <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold"> Name <br></br>
                              &nbsp;	&nbsp; </span> {" "}
                              {serviceProvider?.name}{" "}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Contact<br></br>
                              &nbsp;	&nbsp;</span> {" "}
                              {serviceProvider?.contact_number1}{" "}
                              
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Email<br></br>
                              &nbsp;	&nbsp; </span> {" "}
                              {serviceProvider?.email}{" "}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Address<br></br>
                              &nbsp;	&nbsp;</span> {" "}
                              {serviceProvider?.address1}{" "}
                              
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                        <p>
                              <span className="postwork-bold">website<br></br>
                              &nbsp;	&nbsp; </span> {" "}
                              {serviceProvider?.website}{" "}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                        
                            <p>
                              <span className="postwork-bold">Area Of Specialization
                              &nbsp;	&nbsp; </span> 
                              <div class="continer" style={{ marginLeft: "20px"}} >
                                <div class="row">
                              {serviceProvider
                                  ?.area_of_specialization && serviceProvider
                                    ?.area_of_specialization && serviceProvider?.area_of_specialization.map(
                                    (item) => {
                                      return (
                                        <span
                                          class="badge badge-primary"
                                          style={{
                                            marginLeft: "5px",
                                            padding: "8px",
                                            marginTop: "6px",
                                            textAlign: "left",
                                          }}
                                        >
                                          {item!="others"?item:item+" : "+serviceProvider.other_specialization[0]}{" "}
                                        </span>
                                       
                                      );
                                    }
                                  )}
                                  </div>
                                  </div>
                            </p>
                        </div>
                        </div>
                        </div>
                        </div>
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold" >Location Of Offices
                              &nbsp;	&nbsp;</span>
                              <div class="continer" style={{ marginLeft: "20px"}} >
                                        <div class="row">
                              {serviceProvider
                                  ?.loc_of_offices_pk && serviceProvider
                                    ?.loc_of_offices_pk && serviceProvider?.loc_of_offices_pk.map(
                                    (item) => {
                                      return (
                                       
                                        <div class="col-lg-5">
                                        {item}{" "}
                                        </div>
                                        
                                        
                                      );
                                    }
                                  )}
                                  </div>
                                         
                                        </div>
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold"><br></br>
                              &nbsp;	&nbsp;</span>
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>
                  </Tab>
                  <Tab eventKey="reviews" title="Reviews">
                    <Row>
                      <Col>
                        <div style={{ margin: "10px", textAlign: "center" }}>
                          {props?.reviews && props?.reviews?.length > 0 ? (
                            <h3></h3>
                          ) : (
                            <h3> No Reviews Found</h3>
                          )}
                        </div>
                        <div>
                          {props?.reviews &&
                            props?.reviews.map((item, index) => {
                              return (
                                <div>
                                <hr className="review-card" />
                                
                                <div>

                                 <p style={{ textAlign: "left", fontWeight: "bold", marginTop:"10px"}}>
                                  Name - {" "} {moment(item.created_at).format( " kk:mm (DD-MM-YYYY) ")}{" "}
                                </p>
                                <div class="col-lg-12">
                                  <div class="container">
                                  <div class="row">
                                  <div class="col-lg-7">
                                  <div style={{ textAlign: "left" }}>
                                     <p>{" "}
                                    <span style={{ fontWeight: "bold" }}>{item.heading}</span> - {item.content}{" "} </p>
                                   </div>
                                  </div>
                                  <div class="col-lg-5">
                                  <div style={{ textAlign: "left" }}>
                                     <p>{" "}
                                    <b>Area Of Specialization : </b> <br></br>
                                    <span style={{ marginLeft:"10px" }}>{item.area_of_specialization}</span> <br></br> <b>Task Completion :</b> <br></br><p style={{ marginLeft:"10px" }}> {item.task_completion}{" "} </p></p>
                                   </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                                  <div class="col-lg-12">
                                  <div class="container">
                                  <div class="row">
                                  <div class="col-lg-6">
                                  <div>
                                      <p style={{ fontWeight: "bold" }}>{" "}
                                      Rating: (<span>{item.rating}/10</span>)<br></br>  
                                     
                                      {renderStar(Number(item.rating))}{emptyStar(Number(item.rating))}
                                          </p>
                                        </div>
                                  </div>
                                  <div class="col-lg-6">
                                  <div>
                                      <p style={{ fontWeight: "bold"}}>{" "}
                                      Recommendation: (<span>{item.recommendation}/10</span>)<br></br>  
                                      {renderStar(Number(item.recommendation))}{emptyStar(Number(item.recommendation))}
                                          </p>
                                        </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                                  <div class="col-lg-12">
                                  <div class="container">
                                  <div class="row">
                                  <div class="col-lg-6">
                                  <div>
                                      <p style={{ fontWeight: "bold"}}>{" "}
                                      Support: (<span>{item.support}/10</span>)<br></br>  
                                      {renderStar(Number(item.support))}{emptyStar(Number(item.support))}
                                          </p>
                                        </div>
                                  </div>  
                                  <div class="col-lg-6">
                                  <div>
                                      <p style={{ fontWeight: "bold" }}>{" "}
                                      Communication: (<span>{item.communication}/10</span>)<br></br>  
                                      {renderStar(Number(item.communication))}{emptyStar(Number(item.communication))}
                                          </p>
                                        </div>
                                  </div>
                                  </div>
                                  </div>
                                  </div>
                                </div>
                                </div>
                              );
                            })}
                        
                        </div>
                      </Col>
                    </Row>
                  </Tab>
                
                </Tabs>
                  {/* <Modal.Header>
                    <h3 style={{ fontSize: "revert",paddingTop:'10px' }}>
                      Service Provider Details
                    </h3>
                  </Modal.Header> */}
                  
                    {/* <Row>
                      <Col>
                        <Col>
                          <div style={{ textAlign: "left" }}>
                            <p>Name </p>
                          </div>
                        </Col>
                      </Col>

                      <Col>
                        <div style={{ textAlign: "left" }}>
                          <p>
                              {" "}
                              {serviceProvider?.name}{" "}
                          </p>
                          <p>
                          </p>
                        </div>
                      </Col>
                    </Row> */}

                    {/* <Row>
                      <Col>
                        <div style={{ margin: "10px", textAlign: "center" }}>
                          {props?.reviews && props?.reviews?.length > 0 ? (
                            <h3> Reviews </h3>
                          ) : (
                            <h3> No Reviews Found</h3>
                          )}
                        </div>

                        <div>
                          {props?.reviews &&
                            props?.reviews.map((item, index) => {
                              return (
                                <Card
                                  style={{ padding: "10px", margin: "10px" }}
                                >
                                  <p>
                                  {" "}
                                    {item.heading} - {item.content}{" "}
                                    <br></br>
                                  <ProgressBar now={index+"0"} />
                                  </p>
                                </Card>
                              );
                            })}
                        </div>
                      </Col>
                    </Row> */}
                    {/* <button id="btnbtn"  className="button bt-blue" onClick={handleSpModal}>
                    {" "}
                    Cancel{" "}
                  </button>
                  </Card> */}
                  {/* <button id="btnbtn"  className="button bt-blue" onClick={handleSpModal}>
                    {" "}
                    Cancel{" "}
                  </button> */}
                  </Card>
                </Modal>

                <Modal  className="modell" show={show} onHide={handleClose}>
                  <form
                    onSubmit={formik.handleSubmit}
                    style={{
                      border: "2px solid silver",
                      padding: "50px",
                      textAlign: "left",
                      border:"3%",
                    }}
                  >
                    <h2> Update Profile </h2>
                    { userType === "individual"
                      ? [
                          (props?.serviceSeeker?.member_dropdown === "Individual(M)"
                            ? 
                    <div>
                    <Row>
                      <Col>
                        <label htmlFor="contact_address_comm" style={{ marginTop: "10px",marginLeft:"10px"}}>
                          {" "}
                          Contact Address Comm:{" "}
                        </label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="contact_address_comm"
                          name="contact_address_comm"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_address_comm}
                        />
                        {formik.touched.contact_address_comm &&
                        formik.errors.contact_address_comm ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.contact_address_comm}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="contact_number1" style={{ marginTop: "10px",marginLeft:"10px"}}>
                          {" "}
                          Contact_number1:
                        </label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="contact_number1"
                          name="contact_number1"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_number1}
                        />
                        {formik.touched.contact_number1 &&
                        formik.errors.contact_number1 ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.contact_number1}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="organisation" style={{ marginTop: "10px",marginLeft:"10px"}}>Organisation:</label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="organisation"
                          name="organisation"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.organisation}
                        />
                        {formik.touched.organisation &&
                        formik.errors.organisation ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.organisation}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="designation" style={{ marginTop: "10px",marginLeft:"10px"}}>Currently working as:</label>
                        <select
                        id="currently_working_as"
                        name="currently_working_as"
                        class="form-control"
                        value={formik.values.currently_working_as}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{
                            margin: "10px",
                            marginLeft: "0px",
                          }}
                      >
                      <option  hidden value="Yes">{formik.values.currently_working_as}</option>
                        <option value="Partner">Partner</option>
                        <option value="Director">Director</option>
                        <option value="Self-Employed">Self-Employed</option>
                      </select>
                        {/* <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="currently_working_as"
                          name="currently_working_as"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.currently_working_as}
                        />
                        {formik.touched.currently_working_as &&
                        formik.errors.currently_working_as ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.currently_working_as}
                          </div>
                        ) : null} */}
                      </Col>
                    </Row>
                  </div>
                            : 
                  <div>
                    <Row>
                      <Col>
                        <label htmlFor="contact_address_comm" style={{ marginTop: "10px",marginLeft:"10px"}}>
                          {" "}
                          Contact Address Comm:{" "}
                        </label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="contact_address_comm"
                          name="contact_address_comm"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_address_comm}
                        />
                        {formik.touched.contact_address_comm &&
                        formik.errors.contact_address_comm ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.contact_address_comm}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="contact_number1" style={{ marginTop: "10px",marginLeft:"10px"}}>
                          {" "}
                          Contact_number1:
                        </label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="contact_number1"
                          name="contact_number1"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_number1}
                        />
                        {formik.touched.contact_number1 &&
                        formik.errors.contact_number1 ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.contact_number1}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="organisation" style={{ marginTop: "10px",marginLeft:"10px"}}>Organisation:</label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="organisation"
                          name="organisation"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.organisation}
                        />
                        {formik.touched.organisation &&
                        formik.errors.organisation ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.organisation}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="designation" style={{ marginTop: "10px",marginLeft:"10px"}}>Currently working as:</label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="currently_working_as"
                          name="currently_working_as"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.currently_working_as}
                        />
                        {formik.touched.currently_working_as &&
                        formik.errors.currently_working_as ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.currently_working_as}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                            </div>
                          )
                        ]




                      : 
                      
                      
                      
                      
                [
                  (props?.serviceSeeker?.is_member === true
                            ? 
                  <div>
                    <Row>
                      <Col>
                        <label htmlFor="contact_address_comm" style={{ marginTop: "10px",marginLeft:"10px"}}>
                          {" "}
                          Contact Address:{" "}
                        </label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="address1"
                          name="address1"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address1}
                        />
                        {formik.touched.address1 &&
                        formik.errors.address1 ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.address1}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="contact_number1" style={{ marginTop: "10px",marginLeft:"10px"}}>
                          {" "}
                          Contact_number1:
                        </label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="contact_number1"
                          name="contact_number1"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_number1}
                        />
                        {formik.touched.contact_number1 &&
                        formik.errors.contact_number1 ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.contact_number1}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="name_of_member" style={{ marginTop: "10px",marginLeft:"10px"}}>Name of member:</label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="name_of_member"
                          name="name_of_member"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name_of_member}
                        />
                        {formik.touched.name_of_member &&
                        formik.errors.name_of_member ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.name_of_member}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="designation" style={{ marginTop: "10px",marginLeft:"10px"}}>Designation:</label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="designation"
                          name="designation"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.designation}
                        />
                        {formik.touched.designation &&
                        formik.errors.designation ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.designation}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="member_type" style={{ marginTop: "10px",marginLeft:"10px"}}>Member type:</label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="member_type"
                          name="member_type"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.member_type}
                        />
                        {formik.touched.member_type &&
                        formik.errors.member_type ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.member_type}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label htmlFor="member_number" style={{ marginTop: "10px",marginLeft:"10px"}}>Membership number:</label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="member_number"
                          name="member_number"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.member_number}
                        />
                        {formik.touched.member_number &&
                        formik.errors.member_number ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.member_number}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label htmlFor="official_email" style={{ marginTop: "10px",marginLeft:"10px"}}>Official email:</label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="official_email"
                          name="official_email"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.official_email}
                        />
                        {formik.touched.official_email &&
                        formik.errors.official_email ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.official_email}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                  



                            : 
                            
                            
                            
                            <div>
                            <Row>
                      <Col>
                        <label htmlFor="contact_address_comm" style={{ marginTop: "10px",marginLeft:"10px"}}>
                          {" "}
                          Contact Address:{" "}
                        </label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="address1"
                          name="address1"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address1}
                        />
                        {formik.touched.address1 &&
                        formik.errors.address1 ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.address1}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="contact_number1" style={{ marginTop: "10px",marginLeft:"10px"}}>
                          {" "}
                          Contact_number1:
                        </label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="contact_number1"
                          name="contact_number1"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.contact_number1}
                        />
                        {formik.touched.contact_number1 &&
                        formik.errors.contact_number1 ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.contact_number1}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="name_of_member" style={{ marginTop: "10px",marginLeft:"10px"}}>Name of contact person:</label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="name_of_member"
                          name="name_of_member"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name_of_member}
                        />
                        {formik.touched.name_of_member &&
                        formik.errors.name_of_member ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.name_of_member}
                          </div>
                        ) : null}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label htmlFor="designation" style={{ marginTop: "10px",marginLeft:"10px"}}>Designation:</label>

                        <input
                          className="form-control"
                          style={{
                            margin: "10px",
                          }}
                          id="designation"
                          name="designation"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.designation}
                        />
                        {formik.touched.designation &&
                        formik.errors.designation ? (
                          <div style={{ color: "white" }}>
                            {formik.errors.designation}
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                            </div>




                          )
                        ]
                    }

                    
                    <Button style={{ marginTop: "10px",marginLeft:"10px"}} onClick={handleClose}>Close</Button>
                    <button type="submit" className="btn btn-primary"
                    style={{ marginTop: "10px",marginLeft:'20px' }}>
                      {" "}
                      Submit{" "}
                    </button>
                  </form>
                </Modal>


                
              </Card.Body>
            </Card>
            {/* <Card className="shadow bottomcard">
              <Card.Header className="sppheader">Service Seeker Member Type</Card.Header>
              <Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                  <h5>
                  
                  </h5>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card> */}
          </Col>

          <Col lg={8}>
            {/* <Card className="shadow-sm" style={{ padding: "10px", marginTop: "10px" }}>
              <Card.Header>Featured</Card.Header>

              <Row>
                <Col>Service Seeker Member Type</Col>
                <Col>
                  <Badge variant="info">
                    {" "}
                    {props?.serviceSeeker?.member_dropdown}{" "}
                  </Badge>
                </Col>
              </Row>
            </Card> */}

            <Row style={{ marginTop: "13px",marginBottom:"10px"}}>
              <Col lg={12}>
                <Card className="shadow">
                  <Card.Body>
                    {/* <Button onClick={logoutUser}> Logout </Button> */}

                    <Tabs
                      defaultActiveKey="profile"
                      id="uncontrolled-tab-example"
                    >
                      <Tab eventKey="profile" title="Profile">
                        <br />
                        <Container>


                        {userType === "individual" ? 
                        [
                            (props?.serviceSeeker?.member_dropdown === "Individual(M)"
                              ? <div>
                         <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            
                            <p>
                              <span className="postwork-bold">Member Type<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}{(props?.serviceSeeker?.member_type !=="") ?  props?.serviceSeeker?.member_type  : "-" }{" "} 
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                        
                            <p>
                              <span className="postwork-bold">Membership Number<br></br>
                              &nbsp;	&nbsp; </span> {props?.serviceSeeker?.membership_number}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>  

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Name<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.name}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Contact Number(1)<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.contact_number1}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold"> Contact Address Communication<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.contact_address_comm}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">email<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                  {props?.serviceSeeker?.email}
                            </p>
                            
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                        <hr className="review-card"></hr>
                        <div style={{ marginBottom:"20px"}}>
                        <h4 style={{ marginBottom:"20px",marginLeft:'25px',textAlign: "left"}} >Additional Information</h4>
                        </div> 
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}} class="col-lg-8">
                              <p>
                                    <span className="postwork-bold">Member Since<br></br>
                                    &nbsp;	&nbsp; </span>{" "}
                                    {" "}
                                        {moment(
                                          props?.serviceSeeker?.member_since
                                        ).format("YYYY-MM-DD HH:MM")}
                                  </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            
                            <p>
                              <span className="postwork-bold">CNIC<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.cnic}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Contact Number (2)<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                  {props?.serviceSeeker?.contact_number2}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>  
                        
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Contact Address Permanent<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                              {" "}
                                  {props?.serviceSeeker?.contact_address_permanent}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                        <p>
                              <span className="postwork-bold">Email Official<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                  {props?.serviceSeeker?.official_email}
                            </p>  
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Name of Organisation<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                                  {props?.serviceSeeker?.organisation}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Website<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.website}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Currently working as<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                                  {props?.serviceSeeker?.currently_working_as}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                         </div>
                         


                       /* else condition start  here */
                              : 
                      /* else condition start  here */

                          <div>
                         <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            
                            <p>
                              <span className="postwork-bold">Name<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}{(props?.serviceSeeker?.name !=="") ?  props?.serviceSeeker?.name  : "-" }{" "} 
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                        <p>
                              <span className="postwork-bold"> Contact Number (1) <br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.contact_number1}
                            </p>
                        </div>
                        </div>
                        </div>
                        </div>  

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Contact Address Communication<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.contact_address_comm}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Email<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.email}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div> 

                        <div class="col-lg-12">
                        <hr className="review-card"></hr>
                        <div style={{ marginBottom:"20px"}}>
                        <h4 style={{ marginBottom:"20px",marginLeft:'25px',textAlign: "left"}} >Additional Information</h4>
                        </div> 
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}} class="col-lg-8">
                              <p>
                                    <span className="postwork-bold">Member Since<br></br>
                                    &nbsp;	&nbsp; </span>{" "}
                                    {" "}
                                        {moment(
                                          props?.serviceSeeker?.member_since
                                        ).format("YYYY-MM-DD HH:MM")}
                                  </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            
                            <p>
                              <span className="postwork-bold">CNIC<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.cnic}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                        <p>
                              <span className="postwork-bold">Gender<br></br>
                              &nbsp;	&nbsp; </span> {" "}
                                                {props?.serviceSeeker?.gender}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Contact Number (2)<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                  {props?.serviceSeeker?.contact_number2}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>  
                        
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Contact Address Permanent<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                              {" "}
                                  {props?.serviceSeeker?.contact_address_permanent}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                        <p>
                              <span className="postwork-bold">Email Official<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                  {props?.serviceSeeker?.official_email}
                            </p>  
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Name of Organisation<br></br>
                              &nbsp;	&nbsp; </span>{" "}
                                  {props?.serviceSeeker?.organisation}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Website<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.website}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                       
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            
                            
                        </div>
                        </div>
                        </div>
                        </div>
                         </div>


                         
                            )
                          ]
                         
                         : 

                          [
                            (props?.serviceSeeker?.is_member === true
                              ? <div>
                         <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            
                            <p>
                              <span className="postwork-bold">Name<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}{(props?.serviceSeeker?.name !=="") ?  props?.serviceSeeker?.name  : "-" }{" "} 
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                        
                            <p>
                              <span className="postwork-bold">National Tax Number<br></br>
                              &nbsp;	&nbsp; </span> {props?.serviceSeeker?.ntn_strn}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>  

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold"> Contact Number (1) <br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.contact_number1}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Contact Address<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.address1}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Signup Email<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.email}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Name of member<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                  {props?.serviceSeeker?.name_of_member}
                            </p>
                            
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Designation<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.designation}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Member type<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                  {props?.serviceSeeker?.member_type}
                            </p>
                            
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Membership Number<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.member_number}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Email Official<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                  {props?.serviceSeeker?.official_email}
                            </p>
                            
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                        <hr className="review-card"></hr>
                        <div style={{ marginBottom:"20px"}}>
                        <h4 style={{ marginBottom:"20px",marginLeft:'25px',textAlign: "left"}} >Additional Information</h4>
                        </div> 
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}} class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Contact Number (2)<br></br>
                              &nbsp;	&nbsp; </span>{" "}{(props?.serviceSeeker?.contact_number2 !=="") ?  props?.serviceSeeker?.contact_number2  : "-" }{" "}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            
                            <p>
                              <span className="postwork-bold">Contact Address Permanent<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.address2}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                        <p>
                              <span className="postwork-bold">Personal Email<br></br>
                              &nbsp;	&nbsp; </span> {" "}
                                                {props?.serviceSeeker?.personal_email}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">website<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                  {props?.serviceSeeker?.website}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>  
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                        <p>
                              <span className="postwork-bold">Incorporation Number<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.incorporation_number}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                         </div>



                              : 
                              
                              
                              
                              <div>
                              <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            
                            <p>
                              <span className="postwork-bold">Name<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}{(props?.serviceSeeker?.name !=="") ?  props?.serviceSeeker?.name  : "-" }{" "} 
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                        
                            <p>
                              <span className="postwork-bold">National Tax Number<br></br>
                              &nbsp;	&nbsp; </span> {props?.serviceSeeker?.ntn_strn}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>  

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold"> Contact Number (1) <br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.contact_number1}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Contact Address<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.address1}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Signup Email<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.email}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">Name of member<br></br>
                              &nbsp;	&nbsp;
                              </span>{" "}
                                  {props?.serviceSeeker?.name_of_member}
                            </p>
                            
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Designation<br></br>
                              &nbsp;	&nbsp; </span>{props?.serviceSeeker?.designation}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                        </div>
                        </div>
                        </div>
                        </div> 
                         
                        <div class="col-lg-12">
                        <hr className="review-card"></hr>
                        <div style={{ marginBottom:"20px"}}>
                        <h4 style={{ marginBottom:"20px",marginLeft:'25px',textAlign: "left"}} >Additional Information</h4>
                        </div> 
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}} class="col-lg-8">
                            <p>
                              <span className="postwork-bold">Contact Number (2)<br></br>
                              &nbsp;	&nbsp; </span>{" "}{(props?.serviceSeeker?.contact_number2 !=="") ?  props?.serviceSeeker?.contact_number2  : "-" }{" "}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            
                            <p>
                              <span className="postwork-bold">Contact Address Permanent<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.address2}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>

                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                        <p>
                              <span className="postwork-bold">Email<br></br>
                              &nbsp;	&nbsp; </span> {" "}
                                                {props?.serviceSeeker?.personal_email}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            <p>
                              <span className="postwork-bold">website<br></br>
                              &nbsp;
                              </span>
                                  {props?.serviceSeeker?.website}
                            </p>
                            
                        </div>
                        </div>
                        </div>
                        </div>  
                        <div class="col-lg-12">
                       <div class="container">
                        <div class="row">
                        <div style={{ textAlign: "left"}}  class="col-lg-8">
                        <p>
                              <span className="postwork-bold">Incorporation Number<br></br>
                              &nbsp;	&nbsp;
                              </span>{props?.serviceSeeker?.incorporation_number}
                            </p>
                        </div>
                        <div style={{ textAlign: "left"}}  class="col-lg-4">
                            
                            
                        </div>
                        </div>
                        </div>
                        </div> 
                         </div>
                            )
                          ]
                         
                         }
                        </Container>
                      </Tab>

                      <Tab
                        eventKey="Service providers"
                        title="Service Providers"
                      >
                      <div className="testingdivs">
                        <div class="searchdivsp col-sm-6">
                            <label>Select by AOS</label>
                            <select
                              id="dd-serv-seek-type"
                              class="form-control col-sm-12"
                              onChange={e => setsearch(e.target.value)}
                            >
                              <option value="">All</option>
                              <option value="sales-service-tax">Sales/Service Tax Services</option>
                              <option value="income-tax">Income Tax Services</option>
                              <option value="accounting">Accounting Services</option>
                              <option value="audit-assurance">Audit & Assurance Services</option>
                              <option value="fixed-assets-assignments">Fixed Assets Assignments & Related Services</option>
                              <option value="business-process-outsourcing">Business Process Outsourcing</option>
                              <option value="Public-sector-NPO-advisory">Public Sector/NPO/Donor Audits & Advisory Services</option>
                              <option value="actuarial">Actuarial Services</option>
                              <option value="legal-advisory">Legal Advisory Services</option>
                              <option value="transaction-advisory">Transaction Advisory Services</option>
                              <option value="IT-IS-audits">Information System/IT Audits</option>
                              <option value="cost-Accounting-and-audit">Cost Accounting, Audit & Related Services</option>
                              <option value="accounting-advisory">Accounting Advisory</option>
                              <option value="internal-audit">Internal Audit Services</option>
                              <option value="corporate-secretarial">Corporate Secretarial Services</option>
                              <option value="others">Other</option>
                            </select>
                        </div>
                      </div>
                      
                        <br />
                        <div style={{ textAlign: "left"}} class="container">
                              <div class="row">
                          
                        {search != "" ? 
                        <>
                          {filteredArray?.map((item) => {
                              return (
                                <div  class="col-sm-6">
                                <Card className="cardpointer" onClick={() => getServiceProviderDetail(item)} style={{marginBottom:"10px"}}>
                                    <Card.Body>
                                      <Card.Title>{item.name}</Card.Title>
                                      <Card.Subtitle>{item.contact_number1}</Card.Subtitle>
                                      <Card.Text>{item.email}
                                      </Card.Text>
                                    </Card.Body>
                                  </Card>
                                  </div>
                              );
                            })}
                        </>
                        :
                        <>
                        {props &&
                            props?.serviceProviders?.map((item) => {
                              return (
                                <div  class="col-sm-6">
                                <Card className="cardpointer" onClick={() => getServiceProviderDetail(item)} style={{marginBottom:"10px"}}>
                                    <Card.Body>
                                      <Card.Title>{item.name}</Card.Title>
                                      <Card.Subtitle>{item.contact_number1}</Card.Subtitle>
                                      <Card.Text>{item.email}
                                      </Card.Text>
                                    </Card.Body>
                                  </Card>
                                  </div>
                              );
                            })}   
                        </>
                        }
                            </div>
                        </div>
                        {editable && (
                          <Form>
                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                  type="email"
                                  placeholder="Enter email"
                                />
                              </Form.Group>

                              <Form.Group as={Col} controlId="formGridPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  placeholder="Password"
                                />
                              </Form.Group>
                            </Form.Row>

                            <Form.Row>
                              <Form.Group as={Col} controlId="formGridCity">
                                <Form.Label>City</Form.Label>
                                <Form.Control />
                              </Form.Group>

                              <Form.Group as={Col} controlId="formGridState">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                  as="select"
                                  defaultValue="Choose..."
                                >
                                  <option>Choose...</option>
                                  <option>...</option>
                                </Form.Control>
                              </Form.Group>

                              <Form.Group as={Col} controlId="formGridZip">
                                <Form.Label>Zip</Form.Label>
                                <Form.Control />
                              </Form.Group>
                            </Form.Row>

                            <Form.Group id="formGridCheckbox">
                              <Form.Check
                                type="checkbox"
                                label="Check me out"
                              />
                            </Form.Group>

                            <Button variant="primary" type="submit">
                              Submit
                            </Button>
                          </Form>
                        )}
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    individualUser: state.serviceSeeker.individualUser,
    serviceSeeker: state.serviceSeeker.user,
    postWorks: state.post,
    serviceProviders: state.serviceSeeker.serviceProviders,
    reviews: state.serviceSeeker.reviews,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReviewsAction: (id) => dispatch(getReviews(id)),
    getPostWorkAction: () => dispatch(getPostWork()),
    logoutUserAction: () => dispatch(logout),
    getServiceSeekerAction: (id) => dispatch(getServiceSeeker(id)),
    updateServiceSeekerAction: (values) =>
      dispatch(updateServiceSeeker(values)),
    getServiceProvidersAction: () => dispatch(getServiceProviders()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceSeekerProfile);
