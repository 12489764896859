import logo from "./logo.svg";
import "./App.css";
import List from "./components/list";
import Sidebar from "./components/Sidebar/sidebar";
import CustomerChat from "./components/CustomerChat";
import { Routers } from "./components/Routers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useEffect } from "react";
import jwt from "jsonwebtoken";
import axios from "axios";
import LinkedInTag from 'react-linkedin-insight';
import ReactGA from 'react-ga';


if (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'development') {
  console.log("no tags script")
  console.log(process.env.REACT_APP_GA_TRACKING_ID)
  console.log(process.env.REACT_APP_LN_PARTNER_ID)
  console.log(process.env.REACT_APP_FB_CHAT_PAGE_ID)
  
}
else if(process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENV === 'production' ){  
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

  LinkedInTag.init(process.env.REACT_APP_LN_PARTNER_ID, 'dc');
  if(process.env.REACT_APP_LN_CONVERSION_ID !== ""){
    LinkedInTag.track(process.env.REACT_APP_LN_CONVERSION_ID);
  }
}

function App() {
  useEffect(() => {
    async function verifyToken() {
      let userDetails = localStorage.getItem("user");
      if (userDetails) {
        const adminToken = localStorage.getItem("user");
        const token = JSON.parse(adminToken)?.data?.token;
        const id = JSON.parse(adminToken)?.data?.id;
        const dataResponse = await axios
          .get(
            `${process.env.REACT_APP_HOST_URL}/api/v1/apexway/post/by-user`,
            {
              params: {
                userId: id,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(function (response) {})
          .catch(function (err) {
            if (
              err?.response?.data?.error &&
              (err?.response?.data?.msg
                ?.toLowerCase()
                ?.includes("token is not valid") ||
                err?.response?.data?.message
                  ?.toLowerCase()
                  ?.includes("token is not valid") ||
                err?.response?.data?.msg
                  ?.toLowerCase()
                  ?.includes("you are not authorized to perform this action") ||
                err?.response?.data?.message
                  ?.toLowerCase()
                  ?.includes("you are not authorized to perform this action"))
            ) {
              localStorage.clear();
              window.location.href = "/";
            }
          });
      }
    }
    verifyToken();
  }, []);

  return (
    <div className="App">
      <Routers />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <CustomerChat />
    </div>
  );
}

export default App;
