import React,{Component} from 'react';
import {Nav,Navbar,FormControl,Button,Form} from 'react-bootstrap'

import { NavLink, withRouter } from "react-router-dom";
import HeaderApp from '../Header/Header';
import FooterApp from '../Footer/Footer';
import MetaTags from 'react-meta-tags';

import img3 from '../../images/Myriad.jpg';


const AboutUs = () => {

return(

  <div> 
    <MetaTags>
        <title>Technology Based Solutions to Empower Professional Financial Services Firms | APEXWAY</title>
        <meta name="description" content="APEXWAY marketplace is an innovative platform for service seekers and professional firms providing accounting, auditing, taxation, legal and other commerce and finance related services." />
        <meta name="robots" content="noodp,noydir" />
    </MetaTags>

<div>
<HeaderApp/>
</div>

    <div>
        <div ids="iq-home" className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                    About Us<br></br>
                    </h1>
                </div>
            </div>
        </div>
    </div>




<section class="features py-5">

<section class="contact py-2">
<div class="container py-sm-3">
    <div class="heading pb-2">
        <h2 class="heading mb-2 text-center"><span>Reach your peak</span></h2>
        <p class="para mb-5 text-center">Our mission is to empower you to grow and achieve more</p>
    </div>

    <div class="container py-sm-3">
        <div class="row">
            <div class="col-lg-6 about-img">
                <img src={img3} alt="" class="img-fluid" loading="lazy"/>
            </div>
            <div class="col-lg-6 about-right mt-lg-0">
                <h4>About APEXWAY</h4>
                <h5>Empowering you to reach new peaks!</h5>
                <p class="my-4">APEXWAY portal is the brainchild of an experienced member of the Institute of Chartered Accountants of Pakistan. Our vision is to provide technology based solutions to empower professional service providers practicing in the field of accounting and finance. Our collaboration with an evolving IT company - Global Spectrum, has further strengthened way of expansion and our footprint in the market.</p>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-sm-6 about-in text-left">
                <div class="card">
                    <div class="card-body card-boxshadow">
                        <i class="fas fa-anchor"></i>
                        <h5 class="card-title">Head office</h5>
                        <p class="card-text">
                            A-101, Ground Floor, Block H, Hyderi, Karachi, Pakistan.
                        </p>
                    </div>
                </div>

            </div>
            <div class="col-sm-6 about-in text-left">
                <div class="card">
                    <div class="card-body card-boxshadow">
                        <i class="far fa-map"></i>
                        <h5 class="card-title">Support office (NOO)</h5>
                        <p class="card-text">
                            Fishponds, Bristol, United Kingdom.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
</section>

{/* <script>
$(document).ready(function () {
    $("#li-how-it-works").removeClass('active');
    $("#li-home").removeClass('active');
    $("#li-packages").removeClass('active');
    $("#li-post-work").removeClass('active');
    $("#li-about").addClass('active');
    $("#li-contact").removeClass('active');
});
</script> */}

</section>



<FooterApp/>

</div>

)



}

export default AboutUs
