

import {LOGOUT_USER, UNIQUE_EMAIL, UNIQUE_NAME,UNIQUE_CNIC} from '../Constants/constants';

const initialState = {
    logout : false,
    uniqueName: {},
    uniqueEmail: {},
    uniqueCnic:{}
};


export default function (state = initialState, action) {
    switch (action.type) {
        case LOGOUT_USER:

            localStorage.clear();
            return {
                ...state,
                logout: true
            }

            case UNIQUE_EMAIL:
                return {
                ...state,
                uniqueEmail: action.payload
                }

                case UNIQUE_NAME:

                return {
                    ...state,
                    uniqueName: action.payload
                }
                
                case UNIQUE_CNIC:

                return {
                    ...state,
                    uniqueCnic: action.payload
                }
        
    
        
    

            default:
            return state;
    }
}
