import { combineReducers } from "redux";
import globalReducer from "./globalReducers";
import ServiceProviderReducer from "./ServiceProviderReducer";
import serviceSeekerReducer from "./serviceSeekerReducer";
import postReducer from "./postReducer";

export default combineReducers({
  globalReducer: globalReducer,
  serviceProvider: ServiceProviderReducer,
  serviceSeeker: serviceSeekerReducer,
  post: postReducer,
});
