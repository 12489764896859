import React,{Component, useEffect,useState} from 'react';
import {Nav,Navbar,FormControl,Button,Form} from 'react-bootstrap'
import '../Sidebar/style.css';
import { NavLink, withRouter } from "react-router-dom";
import HeaderApp from '../Header/Header';
import FooterApp from '../Footer/Footer';
import check from '../../images/check2.svg'
import ipad from '../../images/Hiw1.jpg'
import ipad2 from '../../images/Hiw2.jpg'
import MetaTags from 'react-meta-tags';

const HowItWorks = () => {
return(
<>
    <MetaTags>
        <title>Pakistan's Pioneer Portal for Accounting and Financial Outsourcing Solutions | APEXWAY</title>
        <meta name="Find credible financial and accounting service providers. Get the best quote for your assignment quickly with Pakistan's pioneer online marketplace." />
        <meta name="robots" content="noodp,noydir" />
    </MetaTags>
    <div>
        <HeaderApp/>
    </div>
    <div>
        <div ids="iq-home" className={"iq-banner overview-block-pt iq-bg-over iq-over-blue-90 iq-parallax jarallax"}>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <h1 id="bannertext" className="text-uppercase iq-font-white iq-tw-3">
                        How it works<br></br>
                    </h1>
                </div>
            </div>
        </div>
    </div>
    <section className="registerdiv">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-sm-12">
                    <img loading="lazy" className="img-fluid" src={ipad}  alt="drive05" />
                </div>
                <div class="col-lg-6 col-sm-12">
                    <div className="heading-title left text-left">
                        <h3 className="iq-tw-7 iq-mb-25 title">The Registration Process</h3>
                    </div>
                    <ul className="iq-mt-40 iq-list">
                        <li className="iq-tw-6 iq-mb-15">
                            <i><img loading="lazy" className="check-icon" src={check} alt='asdasd' /></i>
                            <span className="iq-font-black">Submit the registration form on the website.</span>
                        </li>
                        <li className="iq-tw-6 iq-mb-15">
                            <i><img loading="lazy" className="check-icon" src={check} alt='asdasd' /></i>
                            <span className="iq-font-black">After this you will receive a confirmation email from us. The email will guide you on the available packages, list of required documents and bank payment process.</span>
                        </li>
                        <li className="iq-tw-6 iq-mb-15">
                            <i><img loading="lazy" className="check-icon" src={check} alt='asdasd' /></i>
                            <span className="iq-font-black">Applicant should then share evidence of the payment and required documents with us.</span>
                        </li>
                        <li className="iq-tw-6 iq-mb-15">
                            <i><img loading="lazy" className="check-icon" src={check} alt='asdasd' /></i>
                            <span className="iq-font-black">That's it. Please also see below for further details on account opening process.</span>
                        </li>
                            <h6>Note: Process duration 4-5 working days after documents submission and payment.</h6>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <div className="iq-compare-services overview-block-ptb iq-over iq-over-blue-90 iq-parallax jarallax">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="heading-title white">
                        <h3 className="title iq-tw-7">APEXWAY Made it Easier for You</h3>
                        <p className="iq-font-white">Just follow the easy steps and open your ways for exciting journey of new opportunities</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-5">
                    <div className="iq-services-box text-left iq-font-white">
                        <div className="iq-icon">
                            <i aria-hidden="true" className="ion-ios-monitor-outline" />
                        </div>
                        <div className="services-content">
                            <h4 className="iq-tw-6 iq-pb-10 iq-font-white" style={{marginBottom:'10px'}}>Register as a Service Provider?</h4>
                            <ul>
                                <li>
                                    <div className="row iq-mt-20">
                                        <div className="col-12">
                                            If you are a registered practicing firm engaged in providing professional services in Corporate Finance, you are eligible for registration as a service provider.
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="row iq-mt-20">
                                        <div className="col-12">
                                            As a Service provider you can select multiple areas of specialization from the list of specified services.
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="row iq-mt-20">
                                        <div className="col-12">
                                            Our specified services are :
                                            <ul className="how-it-works-services-list pb-0">
                                                <li>Financial Audits and Assurance</li>
                                                <li>Cost/Internal Audits</li>
                                                <li>Accounting Services</li>
                                                <li>Taxation</li>
                                                <li>It/System Audits</li>
                                                <li>Corporate Secretarial</li>
                                                <li>Business and Transaction Advisory</li>
                                                <li>Legal Advisory</li>
                                                <li>Actuarial</li>
                                                <li>Business Process Outsourcing</li>
                                                <li>Npo Audits and Advisory</li>
                                                <li>Fixed Assets Assignments</li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="row iq-mt-20">
                                        <div className="col-12">
                                            To register for any other services not specified above, choose “Other” category and specify the type of service you offer.
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-sm-2 align-self-center text-center">
                </div>
                <div className="col-sm-5">
                    <div className="iq-services-box text-left iq-font-white">
                        <div className="iq-icon">
                            <i aria-hidden="true" className="ion-ios-monitor-outline" />
                        </div>
                        <div className="services-content">
                            <h4 className="iq-tw-6 iq-pb-10 iq-font-white" style={{marginBottom:'10px'}}>Register as a Service Seeker?</h4>
                            <ul>
                                <li>
                                    <div className="row iq-mt-20">
                                        <div className="col-12">
                                            Registrations are open for individuals, companies or any other organization seeking to outsource corporate finance or other related services.
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="row iq-mt-20">
                                        <div className="col-12">
                                            APEXWAY offers free registration for individuals who are members of the prescribed professional bodies (ICAP, ICMAP, ACCA, ICPAP, ICSP, PIPFA, CIA, PBC and all international bodies) and a nominal fee for others.

                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="row iq-mt-20">
                                        <div className="col-12">
                                            Special package for companies applying for registration via a member of the prescribed professional bodies mentioned above.

                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="row iq-mt-20">
                                        <div className="col-12">
                                            The option for a limited time membership also exists at a very nominal fee.
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="row iq-mt-20">
                <div className="col-sm-5">
                    <div className="iq-services-box text-left iq-font-white">
                        <div className="iq-icon">
                            <i aria-hidden="true" className="ion-ios-color-wand-outline" />
                        </div>
                        <div className="services-content">
                        <p>Service providers can select multiple areas of specialization from the list of specified services only.</p>
                            
                        </div>
                    </div>
                </div>
                <div className="col-sm-2 align-self-center text-center">
                </div>
                <div className="col-sm-5">
                    <div className="iq-services-box text-left iq-font-white">
                        <div className="iq-icon">
                            <i aria-hidden="true" className="ion-ios-color-wand-outline" />
                        </div>
                        <div className="services-content">
                        <p>Free registration for individuals who are members of the prescribed professional bodies (ICAP, ICMAP, ACCA, ICPAP, ICSP, PIPFA, CIA, PBC and all international bodies) and a nominal fee for others.</p>
                            
                        </div>
                    </div>
                </div>
            </div>*/}
        </div>
    </div>
    <section id="software-features" className="overview-block-ptb grey-bg main-service-box">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <div className="heading-title">
                        <h3 className="title iq-tw-7">Posting Assignments</h3>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-7 col-md-12">
                    <img loading="lazy" src={ipad2} className="img-fluid" alt="#" />
                </div>
                <div className="col-lg-5 col-md-12 left-img">
                    <div className="media service-box">
                        <i aria-hidden="true" className="ion-ios-pie-outline" />
                        <div className="media-body">
                            Service seekers who are registered on the portal can either directly search and contact our registered and verified service providers or post a project on the portal.
                        </div>
                    </div>
                    <div className="media service-box">
                        <i aria-hidden="true" className="ion-ios-pie-outline" />
                        <div className="media-body">
                            As a registered service seekers you will be able to post review of the services obtained.
                        </div>
                    </div>
                    <div className="media service-box">
                        <i aria-hidden="true" className="ion-ios-pie-outline" />
                        <div className="media-body">
                            APEXWAY has unique review process which allows only relevant feedback against a particular assignment.
                        </div>
                    </div>
                    <div className="media service-box">
                        <i aria-hidden="true" className="ion-ios-pie-outline" />
                        <div className="media-body">
                            Search tools also allow quick identification of the relevant field experts (by area of specialization, (for  example accounting, audit, taxation, corporate advisory, International Financial Reporting Standards (IFRS or GAAP), internal audits etc.).
                        </div>
                    </div>
                    <div className="media service-box">
                        <i aria-hidden="true" className="ion-ios-monitor-outline" />
                        <div className="media-body">
                            As a registered Service provider of APEXWAY you will receive automatic emails of new work opportunities.
                        </div>
                    </div>
                </div>
            </div>
            <b>Note: To ensure compliance with strict quality control standards of the Portal, all registration applications of Service Providers, assignment posts and reviews by the Service Seekers are subject to strict review by the site Administrators. The administrators reserve all the rights to decline an application/post/review with or without assigning any reason.</b>
        </div>
    </section>
    <FooterApp/>
</>
)



}

export default HowItWorks;